import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import HealthCard from "../components/Card/HealthCard";
import ServiceCard from "../components/ServiceCard/ServiceCard";
import CardModel from "../components/Model/CardDetail";

import Heart from "../assets/svg/Heart";
import HomeCar from "../assets/svg/HomeCar";
import Term from "../components/Terms/";
import { useLocation } from "react-router-dom";

import { fetchMe } from "../store/app/actions";

import { fetchHealths } from "../store/health/actions";
import { fetchLabs } from "../store/lab/actions";

import MetaTag from "../meta-seo/MetaTag";

const dummyService = [
  { id: 1, name: "Home Collection", icon: <HomeCar></HomeCar> },
  { id: 2, name: "Health Packages", icon: <Heart></Heart> },
  // { id: 3, name: "New Test", icon: <Heart></Heart> },
  // { id: 4, name: "T Collection", icon: <Heart></Heart> },
];

const HomeCollection = () => {
  return (
    <div className="health__container">
      <Term></Term>
    </div>
  );
};

const HealthPackage = ({ healths = [] }) => {
  return (
    <div className="health__container">
      <h3 className="heading__primary line">Health Packages</h3>
      <ul className="cardTypeList constant__padding-top">
        {healths
          .filter((item) => item.is_active)
          .map((card, i) => (
            <HealthCard {...card} key={i}></HealthCard>
          ))}
      </ul>
    </div>
  );
};

const NewTest = () => {
  return (
    <div className="health__container">
      <h3 className="heading__primary line">New Test</h3>
      <div className="test__data">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo cum enim,
        aliquam repellat assumenda omnis modi ut error voluptatum fugiat
        quibusdam! Sapiente, ratione praesentium at deserunt eveniet,
        exercitationem doloribus illo delectus quos suscipit necessitatibus
        atque quae temporibus! Quaerat reprehenderit mollitia aperiam! Error,
        labore excepturi! Expedita nemo, in eligendi, impedit nisi alias beatae,
        aliquam eveniet aliquid dicta voluptatem cumque dolor excepturi itaque
        voluptates saepe non aspernatur ipsa dolorum nobis? Illum incidunt
        commodi esse cum minus quas officia quaerat vel in vitae?
      </div>
    </div>
  );
};

const TestPackage = () => {
  return (
    <div className="health__container">
      <h3 className="heading__primary line">Test Packages</h3>
    </div>
  );
};

const CardDetails = ({ cardId, healths = [] }) => {
  //
  const individualCard = healths.find((card) => card.id === cardId);
  return (
    <>
      <div className="individual__card health__container">
        {healths && <CardModel {...individualCard}></CardModel>}
      </div>
    </>
  );
};

const DetailPage = (props) => {
  const { fetchHealths, healths = [] } = props;

  const [active, setActive] = useState(0);

  // if(props.match.params)
  // getting the id of health card
  const getCardId =
    props.match.params.id && props.match.params.id.split("?")[0] * 1;

  const location = useLocation();
  //

  useEffect(() => {
    const active = new URLSearchParams(location.search).get("active");

    if (!active) setActive(0);
    active && setActive(active);
  }, [location]);

  useEffect(() => {
    fetchHealths({});
    // props.healths = [];
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const componentDirectory = {
    0: <CardDetails cardId={getCardId} healths={healths} />,
    1: <HomeCollection />,
    2: <HealthPackage healths={healths} />,
    // 3: <NewTest />,
    // 4: <TestPackage />,
  };
  return (
    <>
      <MetaTag
        title={active == 0 ? "Home Collection" : "Health Package"}
        content={
          active === 0
            ? "More information about Riddi Siddhi diagnostic center"
            : "All the health packages present in Riddhi Siddhi diagnostic center"
        }
      ></MetaTag>
      <div className="healthpage constant__margin-top">
        <div className="health__package-info constant__padding">
          {componentDirectory[active]}
          <ServiceCard
            dummyService={dummyService}
            onClick={(i) => setActive(i + 1)}
          ></ServiceCard>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  appState: { me },
  healthState: { healths },
  labState: { labs },
}) => ({
  me,
  healths,
  labs,
});

export default connect(mapStateToProps, {
  fetchMe,
  fetchHealths,
  fetchLabs,
})(DetailPage);
