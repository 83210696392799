import {
  SET_WEBSITE_INFORMATIONS_DATA,
  SET_WEBSITE_INFORMATION_DATA,
  SET_WEBSITE_INFORMATIONS_METADATA,
  SET_SEARCHED_WEBSITE_INFORMATIONS_DATA,
  RESET_SEARCHED_WEBSITE_INFORMATIONS_DATA,
} from "./action-types";

export const initialState = {
  websiteInformation: undefined,
  websiteInformations: undefined,
  searchedWebsiteInformations: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WEBSITE_INFORMATION_DATA:
      return { ...state, websiteInformation: action.payload };
    case SET_WEBSITE_INFORMATIONS_DATA:
      return { ...state, websiteInformations: action.payload };
    case RESET_SEARCHED_WEBSITE_INFORMATIONS_DATA:
      return { ...state, searchedWebsiteInformations: undefined };
    case SET_SEARCHED_WEBSITE_INFORMATIONS_DATA:
      return { ...state, searchedWebsiteInformations: action.payload };
    case SET_WEBSITE_INFORMATIONS_METADATA:
      return { ...state, metadata: action.payload };
    default:
      return state;
  }
};
