import React from "react";

const index = () => {
  return (
    <div className="terms constant__padding-top">
      <h3 className="terms__heading line heading__secondary">
        Terms and Conditions
      </h3>
      <ol className="terms__list">
        <li>
          Appointments should be taken two days prior to date of sample
          collection.
        </li>
        <li>
          Payment of the test and collection charges will be done on collection
          of sample.
        </li>
        <li>
          Any feedback regarding our home collection service will be valuable to
          us.
        </li>
      </ol>
      {/* <p className="terms__contact">Call Us @ 9851234567, 9801234567</p> */}
    </div>
  );
};

export default index;
