import React, { useState, useEffect } from "react";

import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";

import { convertToHTML } from "draft-convert";

import { connect } from "react-redux";

import { fetchTeams } from "../store/team/actions";

import MetaTag from "../meta-seo/MetaTag";

const DataCard = ({ index, team }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    if (team && team.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(team.description))
        )
      );
    }
  }, [team]);

  useEffect(() => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className={`team__card team__card-${index}`}>
      <div
        className="team__card-description"
        style={{ order: index % 2 ? "1" : "2" }}
      >
        <ul
          className="team__card-list"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></ul>
      </div>
      <div
        className="team__card-image"
        style={{ order: index % 2 ? "2" : "1" }}
      >
        {/* svg */}
        <div className="team__image-content">
          <div className="team__card-profile">
            <div className="team__card-img">
              {team.bannerurl && <img src={team.bannerurl} alt="" />}
              <div
                className="name"
                style={{
                  position: "absolute",
                  bottom: "-26px",
                  whiteSpace: "nowrap",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {team.title && team.title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Team = ({ fetchTeams, teams = [] }) => {
  useEffect(() => {
    fetchTeams({});
  }, []);

  return (
    <>
      <MetaTag
        title="Team"
        content="A team that works together, stays together and finds greatness together."
        url="https://rsdiagnosticenter.com/team"
      ></MetaTag>
      <div className="teampage">
        <div className="team__container constant__padding">
          <h3 className="heading__primary line">Our Team Members</h3>
          <p className="team__text-summary">
            A team that works together, stays together and finds greatness
            together.
          </p>
          <div className="team__cards">
            {teams
              .filter((item) => item.is_active)
              .sort((a, b) => {
                return a.position - b.position;
              })
              .map((team, i) => {
                i = i + 1;
                return <DataCard key={i} index={i} team={team}></DataCard>;
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ teamState: { teams } }) => ({
  teams,
});

export default connect(mapStateToProps, {
  fetchTeams,
})(Team);
