export const SET_LAB_DATA = 'homeImage/SET_LAB_DATA';

export const SET_LABS_DATA = 'homeImage/SET_LABS_DATA';

export const SET_LABS_METADATA = 'homeImage/SET_LABS_METADATA';

export const SET_SEARCHED_LABS_DATA = 'homeImage/SET_SEARCHED_LABS_DATA';
export const RESET_SEARCHED_LABS_DATA = 'homeImage/RESET_SEARCHED_LABS_DATA';

export const CREATE_LAB_DATA = 'homeImage/CREATE_LAB_DATA';

export const UPDATE_LAB_DATA = 'homeImage/UPDATE_LAB_DATA';

export const REMOVE_LAB_DATA = 'homeImage/REMOVE_LAB_DATA';
