import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/Navbar/Index";

import { connect } from "react-redux";

import Search from "../components/Search/Search";

import { logoutUser, resetNotification } from "../store/app/actions";
import { logout } from "../store/app/actions";
import { fetchNavigations } from "../store/navigation/actions";

const Header = (props) => {
  // const [windowWidth, setWindowWidth] = useState();

  // function sizeGetter() {
  //   setWindowWidth((prev) => window.innerWidth);
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", sizeGetter);

  //   return () => {
  //     // remove resize listener
  //     window.removeEventListener("resize", sizeGetter);
  //   };
  // }, [windowWidth]);

  // const [dots, setDots] = useState(false);

  useEffect(() => {
    props.fetchNavigations({});
  }, []);

  const mail =
    props.websiteInformations &&
    props.websiteInformations
      .filter((item) => item.is_active)
      .find((item) => item.title.toLowerCase() === "mail");
  const telephone =
    props.websiteInformations &&
    props.websiteInformations
      .filter((item) => item.is_active)
      .find((item) => item.title.toLowerCase() === "telephone");
  const logo =
    props.navigations &&
    props.navigations.find((item) => item.title.toLowerCase() === "logo");

  const logout = async () => {
    await props.logoutUser({});
  };

  const ReportLink =
    process.env.REACT_APP_REPORT_URL && process.env.REACT_APP_REPORT_URL;

  return (
    <header className="header">
      {/* logo */}
      <Link to="/" className="navbar-logo">
        <img
          src={require("../assets/images/logo.png").default}
          alt="Riddhi Siddhi"
        />
      </Link>
      <div className="navgation__container constant__padding">
        {/* top navigation */}
        <div className="top__navigation">
          <div className="contact-info">
            <div className="contact__group">
              <div className="contact__icon">
                <svg
                  width="17"
                  height="25"
                  viewBox="0 0 17 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.84578 0.935845L4.12584 0.526608C5.32738 0.143543 6.60863 0.763347 7.12374 1.9744L8.14565 4.387C8.59057 5.43746 8.34432 6.6735 7.53417 7.44201L5.28098 9.58099C5.41898 10.861 5.84963 12.1209 6.57056 13.3605C7.25554 14.5599 8.17423 15.6097 9.27224 16.4476L11.9787 15.5435C13.0053 15.2021 14.1224 15.5958 14.7505 16.5202L16.2174 18.6734C16.949 19.7489 16.8181 21.2312 15.9104 22.1424L14.9373 23.1191C13.969 24.0911 12.5854 24.4444 11.3065 24.0447C8.28602 23.1025 5.5094 20.3056 2.97546 15.6541C0.437946 10.9955 -0.457856 7.0411 0.28924 3.79575C0.603306 2.43004 1.57524 1.34151 2.84578 0.935845Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="contact__group-text ">
                {telephone && telephone.description}
              </span>
            </div>
            <div className="contact__group">
              <div className="contact__icon">
                <svg
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.4 0C2.49826 0 1.63346 0.358213 0.995836 0.995836C0.358213 1.63346 0 2.49826 0 3.4V3.7417L10.2 9.23439L20.4 3.7434V3.4C20.4 2.49826 20.0418 1.63346 19.4041 0.995836C18.7665 0.358213 17.9017 0 17 0H3.4Z"
                    fill="white"
                  />
                  <path
                    d="M20.4 5.67285L10.6029 10.9479C10.4791 11.0146 10.3406 11.0495 10.2 11.0495C10.0594 11.0495 9.92092 11.0146 9.79709 10.9479L0 5.67285V13.5999C0 14.5017 0.358213 15.3665 0.995836 16.0041C1.63346 16.6417 2.49826 16.9999 3.4 16.9999H17C17.9017 16.9999 18.7665 16.6417 19.4041 16.0041C20.0418 15.3665 20.4 14.5017 20.4 13.5999V5.67285Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="contact__group-text ">
                {mail && mail.description}
              </span>
            </div>
          </div>
          <div className="actions">
            <Link
              className="btn btn-patient"
              // to={props.me ? "/report" : "login"}
              to={{
                pathname: ReportLink,
              }}
              target="_blank"
            >
              Patient Report
            </Link>
          </div>
        </div>
        <Navbar navigations={props.navigations}></Navbar>
      </div>
    </header>
  );
};

const mapStateToProps = ({
  appState: { notification, isLoading },
  navigationState: { navigations },
}) => ({
  notification,
  isLoading,
  navigations,
});

export default connect(mapStateToProps, {
  logoutUser,
  fetchNavigations,
})(Header);
