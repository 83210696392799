import { createState, removeState, updateState } from "../../store/utils";
import {
  SET_ANNOUNCEMENTS_DATA,
  SET_ANNOUNCEMENT_DATA,
  SET_ANNOUNCEMENTS_METADATA,
  SET_SEARCHED_ANNOUNCEMENTS_DATA,
  RESET_SEARCHED_ANNOUNCEMENTS_DATA,
} from "./action-types";

export const initialState = {
  announcement: undefined,
  announcements: undefined,
  searchedAnnouncements: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANNOUNCEMENT_DATA:
      return { ...state, announcement: action.payload };
    case SET_ANNOUNCEMENTS_DATA:
      return { ...state, announcements: action.payload };
    case RESET_SEARCHED_ANNOUNCEMENTS_DATA:
      return { ...state, searchedAnnouncements: undefined };
    case SET_SEARCHED_ANNOUNCEMENTS_DATA:
      return { ...state, searchedAnnouncements: action.payload };
    case SET_ANNOUNCEMENTS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
