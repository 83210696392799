import { useEffect } from "react";

const PageTitle = (props) => {
  useEffect(() => {
    if (props.setFooterColor) {
      props.setFooterColor(props.theme.footer.backgroundColor || "#fff");
    }
  }, []);
  return props.children;
};

export default PageTitle;
