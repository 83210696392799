import React, { useState } from "react";

import { useHistory } from "react-router";

import CardModel from "../Model/CardDetail";

import MetaTag from "../../meta-seo/MetaTag";

// import Overlay from "../Overlay/Overlay";

const HealthCard = (props) => {
  const {
    title,
    price,
    description,
    imgPath,
    id,
    bannerurl,
    content,
    is_seperated,
    male_price,
    female_price,
    male_discount,
    female_discount,
  } = props;
  // const [modalData, setModalData] = useState(false);
  const history = useHistory();

  // const getId =

  const detailsPage = () => {
    history.push({
      pathname: `/health-packages/${id}`,
    });
  };

  return (
    <>
      <li className="cardTypeList__item">
        <div className="cardTypeList__link" onClick={detailsPage}>
          <div className="cardTypeList__imageBox">
            {bannerurl && (
              <img className="cardTypeList__image" src={bannerurl} />
            )}
          </div>
          <dl className="cardTypeList__textBox">
            <dt className="cardTypeList__title">{title}</dt>
            <dd className="cardTypeList__text">
              <div className="price">
                {!is_seperated && <span>Rs {content && content}</span>}
                {!content && is_seperated && (
                  <div className="different__pricing">
                    <p>
                      <span>Male Price</span>
                      <span>Rs. {male_discount}</span>
                    </p>
                    <p>
                      <span>Female Price</span>
                      <span>Rs. {female_discount}</span>
                    </p>
                  </div>
                )}
                {!content && is_seperated && (
                  <div className="different__pricing male_female-discount">
                    <p className={`${content || is_seperated ? "cut" : ""}`}>
                      Rs {male_price}
                    </p>
                    <p className={`${content || is_seperated ? "cut" : ""}`}>
                      Rs {female_price}
                    </p>
                  </div>
                )}
                {content && (
                  <span className={`${content || is_seperated ? "cut" : ""}`}>
                    Rs {price}
                  </span>
                )}
              </div>
              <button className="action-btn" onClick={detailsPage}>
                Know More >>
              </button>
            </dd>
          </dl>
        </div>
      </li>
    </>
  );
};

export default HealthCard;
