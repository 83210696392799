import React from "react";

const AboutImage = () => {
  return (
    <svg viewBox="0 0 727 448" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M222.211 437.367L425.649 429.396C469.58 427.681 517.962 424.089 549.062 392.951C561.591 380.399 570.876 363.669 586.95 356.202C610.295 345.345 638.313 358.22 663.33 352.166C690.421 345.527 708.207 316.084 706.515 288.175C704.823 260.265 686.796 235.181 663.632 219.562C640.468 203.942 612.712 196.697 585.077 192.601C557.441 188.504 529.484 187.253 502.009 182.369C494.254 180.977 485.634 178.676 481.686 171.835C475.099 160.413 486.923 146.388 499.451 142.352C511.98 138.316 526.12 139.183 537.561 132.665C552.607 124.068 558.589 103.868 553.795 87.1788C549.002 70.4897 535.325 57.4331 519.674 49.8453C504.024 42.2575 486.641 39.4524 469.419 37.5757C332.451 22.5615 190.467 61.792 80.6704 144.874C66.2082 155.812 61.0719 160.272 51.9071 175.912C42.7423 191.551 41.7352 213.588 54.5659 226.362C65.4025 237.159 82.4631 238.471 97.5901 236.452C112.717 234.434 127.804 229.854 142.971 231.286C158.138 232.719 174.212 242.607 176.004 257.783C177.757 272.616 165.591 285.47 152.821 293.179C134.793 304.077 114.107 309.525 94.0853 316.064C74.0637 322.602 53.8005 330.735 38.8951 345.648C23.9897 360.561 15.55 383.829 23.3653 403.424C28.844 417.187 41.4935 427.176 55.2104 432.585C68.9274 437.993 83.9738 439.386 98.7382 440.152C138.217 442.251 182.712 438.921 222.211 437.367Z"
        fill="#35C6F3"
      />
      <path
        d="M140.997 66.8372C132.94 70.3486 124.54 73.9003 115.698 74.5864C106.855 75.2725 97.2676 72.5684 91.8694 65.5053C86.4713 58.4422 86.29 48.4328 89.7343 40.3606C93.1787 32.2885 99.6847 25.7098 106.755 20.4023C122.758 8.34623 141.957 1.29103 161.945 0.121155C172.379 -0.464073 183.779 0.988907 191.292 8.19327C203.68 20.14 198.403 37.8583 185.673 46.8587C172.943 55.8591 155.258 60.6418 140.997 66.8372Z"
        fill="#35C6F3"
      />
      <path
        d="M647.986 142.591C662.459 128.09 662.459 104.58 647.986 90.0791C633.512 75.5783 610.046 75.5783 595.572 90.0791C581.099 104.58 581.099 128.09 595.572 142.591C610.046 157.092 633.512 157.092 647.986 142.591Z"
        fill="#35C6F3"
      />
      <path
        d="M506.34 433.533C506.34 429.899 507.781 426.414 510.346 423.844C512.911 421.275 516.389 419.831 520.017 419.831H13.6767C10.0494 419.831 6.57069 421.275 4.00581 423.844C1.44093 426.414 0 429.899 0 433.533C0 437.167 1.44093 440.653 4.00581 443.222C6.57069 445.792 10.0494 447.236 13.6767 447.236H520.017C518.221 447.236 516.442 446.881 514.783 446.193C513.123 445.504 511.616 444.495 510.346 443.222C509.076 441.95 508.068 440.44 507.381 438.777C506.694 437.115 506.34 435.333 506.34 433.533Z"
        fill="#9FB5FE"
      />
      <path
        d="M713.303 419.75H519.936C516.309 419.75 512.83 421.194 510.265 423.763C507.7 426.333 506.26 429.818 506.26 433.453C506.26 437.087 507.7 440.572 510.265 443.142C512.83 445.711 516.309 447.155 519.936 447.155H713.303C715.099 447.155 716.878 446.801 718.537 446.112C720.196 445.423 721.704 444.414 722.974 443.142C724.244 441.869 725.251 440.359 725.939 438.696C726.626 437.034 726.98 435.252 726.98 433.453C726.98 431.653 726.626 429.871 725.939 428.209C725.251 426.546 724.244 425.036 722.974 423.763C721.704 422.491 720.196 421.482 718.537 420.793C716.878 420.105 715.099 419.75 713.303 419.75Z"
        fill="#84A0EF"
      />
      <path
        d="M476.166 190.199L544.993 207.857V317.174C544.991 331.114 547.915 344.9 553.574 357.635L561.812 376.221C567.949 390.042 571.943 404.721 573.656 419.75H427.885L437.553 385.08C441.782 369.936 442.087 353.961 438.44 338.666L436.828 331.865C436.001 328.409 434.39 325.191 432.119 322.461C429.848 319.731 426.979 317.563 423.736 316.124L423.333 315.943C417.352 313.301 412.48 308.648 409.56 302.789C406.64 296.93 405.855 290.233 407.34 283.856L422.991 216.313L476.166 190.199Z"
        fill="white"
      />
      <path
        d="M553.574 357.676L562.88 376.726L513.188 395.756C508.988 397.367 504.408 397.705 500.018 396.729C495.627 395.753 491.62 393.506 488.494 390.267L482.672 384.213L480.034 384.818C473.961 386.185 467.661 386.183 461.589 384.812C455.517 383.44 449.825 380.733 444.926 376.887L440.071 373.053V357.656L553.574 357.676Z"
        fill="#D1DAFF"
      />
      <path
        d="M525.515 316.084C520.963 318.647 515.746 321.513 510.046 324.156C498.213 329.863 485.773 334.21 472.964 337.112C466.821 338.545 460.878 339.554 455.803 340.361C461.691 340.831 467.613 340.668 473.468 339.877C486.883 338.147 499.793 333.639 511.376 326.638C516.429 323.59 521.154 320.026 525.475 316.003L525.515 316.084Z"
        fill="#D1DAFF"
      />
      <path
        d="M526.18 331.986C518.123 334.166 507.951 336.991 497.518 339.13C487.084 341.269 476.569 342.783 468.392 343.953C488.393 345.463 508.416 341.317 526.18 331.986Z"
        fill="#D1DAFF"
      />
      <path
        d="M541.246 395.937C535.204 396.624 528.476 397.37 521.205 397.834C513.933 398.299 506.239 398.5 498.524 398.319C490.81 398.137 483.136 397.512 475.904 396.664C468.673 395.816 461.805 394.646 455.984 393.718C462.242 396.351 468.737 398.378 475.381 399.772C482.958 401.417 490.673 402.343 498.424 402.536C506.169 402.732 513.917 402.211 521.567 400.983C528.273 399.895 534.861 398.179 541.246 395.857V395.937Z"
        fill="#D1DAFF"
      />
      <path
        d="M534.358 411.678C525.199 411.752 516.046 411.193 506.964 410.003C497.904 408.653 488.947 406.684 480.155 404.11C483.961 406.229 487.961 407.979 492.099 409.337C501.548 412.488 511.499 413.857 521.447 413.373C525.796 413.196 530.119 412.608 534.358 411.617V411.678Z"
        fill="#D1DAFF"
      />
      <path
        d="M502.271 198.998L445.55 205.274C436.748 212.539 429.718 225.253 429.718 225.253L442.75 231.791L423.534 248.803C431.249 292.11 453.547 321.27 462.309 332.834L470.366 333.924C482.934 325.044 518.184 295.904 524.992 261.517L505.655 242.971L526.744 230.58C525.374 226.887 516.351 207.554 502.271 198.998Z"
        fill="#D1DAFF"
      />
      <path
        d="M475.542 176.235C476.605 169.696 476.331 163.009 474.736 156.579L450.565 188.04L453.788 195.749L455.661 200.229C460.297 199.424 464.542 197.121 467.747 193.67C471.996 188.751 474.707 182.687 475.542 176.235Z"
        fill="black"
      />
      <path
        d="M491.676 218.956L491.052 194.498L487.023 184.67L475.159 156.115L474.797 156.579C476.392 163.009 476.666 169.696 475.603 176.235C474.747 182.686 472.015 188.743 467.747 193.65C464.542 197.1 460.297 199.404 455.662 200.209L458.884 207.897L456.991 219.077C456.333 223.113 455.776 227.149 455.319 231.186C455.16 237.922 455.415 244.661 456.085 251.366C461.359 252.872 466.749 253.939 472.199 254.554C478.149 254.568 484.092 254.157 489.984 253.323C491.422 241.928 491.988 230.438 491.676 218.956Z"
        fill="#E9B49D"
      />
      <path
        d="M455.319 231.085C454.513 238.39 454.07 245.735 453.99 253.061C459.99 253.962 466.05 254.414 472.118 254.413C466.668 253.798 461.278 252.731 456.004 251.225C455.363 244.532 455.134 237.806 455.319 231.085Z"
        fill="black"
      />
      <path
        d="M268.539 333.54L279.416 338.141L286.989 342.924L289.386 339.049L284.512 334.589L268.539 333.54Z"
        fill="#DD8947"
      />
      <path
        d="M281.41 329.12L268.539 333.54L284.512 334.589L281.41 329.12Z"
        fill="#EBC162"
      />
      <path
        d="M522.957 229.813C522.957 229.813 512.403 197.525 486.963 184.67L490.991 194.498L491.616 218.956C492.287 245.754 488.195 272.459 479.53 297.821L465.713 338.363C465.713 338.363 508.414 299.173 521.547 261.516L500.962 242.446L522.957 229.813Z"
        fill="white"
      />
      <path
        d="M453.99 253.061C454.105 241.672 455.108 230.309 456.991 219.077L458.884 207.897L455.661 200.209L453.788 195.749C445.949 204.163 439.084 213.437 433.324 223.396L446.577 231.468L427.885 249.509C433.666 290.495 465.833 338.464 465.833 338.464L457.776 295.177C455.153 281.295 453.884 267.189 453.99 253.061Z"
        fill="white"
      />
      <path
        d="M489.964 253.182C478.029 254.877 465.913 254.837 453.99 253.061C453.864 267.153 455.106 281.224 457.696 295.076L465.753 338.363L479.571 297.821C484.539 283.333 488.021 268.377 489.964 253.182Z"
        fill="#7BE0F1"
      />
      <path
        d="M453.708 57.7964C445.978 54.8993 437.557 54.3931 429.537 56.3434C425.391 57.5074 421.553 59.5747 418.297 62.3975C420.384 67.1788 423.759 71.2837 428.043 74.2525C432.326 77.2213 437.35 78.9368 442.552 79.207C447.754 79.4773 452.928 78.2915 457.495 75.7824C462.062 73.2734 465.842 69.5402 468.412 65.0008C463.788 62.0689 458.857 59.653 453.708 57.7964Z"
        fill="#151161"
      />
      <path
        d="M453.708 57.7964C458.848 59.6415 463.772 62.0438 468.392 64.9604C470.742 60.794 471.991 56.095 472.02 51.3092C472.049 46.5234 470.858 41.8095 468.558 37.6147C466.258 33.4199 462.927 29.8834 458.88 27.3405C454.833 24.7977 450.205 23.3329 445.434 23.0851C440.664 22.8372 435.909 23.8146 431.622 25.9246C427.334 28.0345 423.656 31.2069 420.935 35.1409C418.215 39.0749 416.543 43.64 416.078 48.4031C415.612 53.1663 416.37 57.9695 418.277 62.3572C421.533 59.5343 425.371 57.467 429.517 56.3031C437.548 54.3615 445.976 54.8818 453.708 57.7964Z"
        fill="#3732A7"
      />
      <path
        d="M399.182 175.367C388.749 164.328 379.584 151.816 374.649 137.488C369.714 123.16 369.392 106.774 376.18 93.2128C379.446 86.9755 383.979 81.4925 389.488 77.1166C394.997 72.7408 401.359 69.5691 408.166 67.8058C421.944 64.3619 436.499 65.9949 449.176 72.4069C455.843 67.0995 466.74 67.5637 473.528 72.75C479.067 77.0081 481.907 83.8895 483.72 90.6499C489.233 111.516 487.251 133.659 478.12 153.209C454.856 166.427 425.831 173.773 399.182 175.367Z"
        fill="#3732A7"
      />
      <path
        d="M398.216 184.67C399.767 182.894 399.827 177.95 395.617 178.616L394.026 182.208L398.216 184.67Z"
        fill="#E0A02F"
      />
      <path
        d="M393.402 182.228C400.599 182.228 406.434 176.383 406.434 169.172C406.434 161.961 400.599 156.115 393.402 156.115C386.204 156.115 380.37 161.961 380.37 169.172C380.37 176.383 386.204 182.228 393.402 182.228Z"
        fill="#DB9387"
      />
      <path
        d="M385.848 170.483C386.105 171.978 386.799 173.362 387.841 174.462C388.884 175.562 390.228 176.327 391.705 176.661C393.181 176.995 394.724 176.883 396.137 176.339C397.55 175.795 398.77 174.844 399.643 173.605C400.517 172.366 401.003 170.895 401.042 169.379C401.082 167.863 400.671 166.369 399.863 165.087C399.054 163.804 397.885 162.791 396.502 162.175C395.118 161.558 393.584 161.367 392.092 161.624C390.094 161.974 388.315 163.103 387.145 164.763C385.975 166.423 385.509 168.479 385.848 170.483Z"
        fill="#D17E74"
      />
      <path
        d="M398.215 184.67C397.464 184.044 396.847 183.273 396.4 182.403C395.954 181.533 395.687 180.581 395.617 179.605C395.61 179.482 395.577 179.361 395.522 179.251C395.467 179.141 395.389 179.043 395.295 178.964C395.201 178.885 395.092 178.825 394.974 178.79C394.856 178.754 394.732 178.743 394.61 178.757H393.643C393.461 178.768 393.287 178.836 393.146 178.952C393.005 179.068 392.904 179.226 392.857 179.403C392.555 180.499 392.555 181.657 392.857 182.753C393.145 183.701 393.656 184.566 394.348 185.275C394.6 185.545 394.921 185.741 395.276 185.84C395.631 185.94 396.007 185.94 396.362 185.84C397.082 185.642 397.726 185.234 398.215 184.67Z"
        fill="#ECB344"
      />
      <path
        d="M474.878 146.206C467.727 143.018 447.605 131.737 440.172 103.283C436.473 113.963 430.002 123.463 421.425 130.809C412.848 138.154 402.473 143.078 391.367 145.076L394.791 164.913C395.714 170.234 397.674 175.321 400.56 179.883C403.446 184.444 407.2 188.391 411.609 191.499C416.018 194.606 420.995 196.812 426.255 197.992C431.516 199.171 436.957 199.3 442.267 198.372C447.576 197.448 452.651 195.485 457.203 192.595C461.754 189.705 465.693 185.945 468.794 181.53C471.895 177.115 474.098 172.131 475.277 166.863C476.455 161.595 476.587 156.146 475.663 150.828L474.878 146.206Z"
        fill="#E9B49D"
      />
      <path
        d="M488.937 165.741C490.488 164.005 490.629 159.061 486.419 159.687L484.767 163.239L488.937 165.741Z"
        fill="#E0A02F"
      />
      <path
        d="M485.09 162.855C492.287 162.855 498.122 157.009 498.122 149.798C498.122 142.587 492.287 136.742 485.09 136.742C477.892 136.742 472.058 142.587 472.058 149.798C472.058 157.009 477.892 162.855 485.09 162.855Z"
        fill="#E9B49D"
      />
      <path
        d="M492.744 149.798C492.744 151.315 492.295 152.798 491.454 154.059C490.613 155.32 489.417 156.303 488.019 156.883C486.62 157.464 485.081 157.615 483.596 157.32C482.112 157.024 480.748 156.293 479.677 155.221C478.607 154.148 477.878 152.782 477.583 151.294C477.287 149.807 477.439 148.265 478.018 146.864C478.598 145.463 479.579 144.265 480.837 143.422C482.096 142.58 483.576 142.13 485.09 142.13C487.118 142.135 489.062 142.945 490.496 144.382C491.93 145.819 492.739 147.766 492.744 149.798Z"
        fill="#DB9387"
      />
      <path
        d="M488.937 165.741C488.19 165.098 487.58 164.311 487.144 163.427C486.708 162.543 486.455 161.58 486.399 160.595C486.394 160.472 486.364 160.351 486.311 160.24C486.258 160.129 486.184 160.03 486.091 159.949C485.999 159.868 485.891 159.806 485.774 159.768C485.658 159.73 485.534 159.716 485.412 159.727H484.445C484.261 159.74 484.086 159.808 483.942 159.924C483.798 160.039 483.692 160.196 483.64 160.373C483.342 161.396 483.32 162.48 483.574 163.514C483.829 164.549 484.352 165.498 485.09 166.265C485.34 166.539 485.66 166.738 486.015 166.841C486.37 166.945 486.747 166.948 487.104 166.851C487.809 166.664 488.445 166.279 488.937 165.741Z"
        fill="#ECB344"
      />
      <path
        d="M408.73 138.961C406.596 139.307 404.591 140.209 402.916 141.576C401.24 142.944 399.953 144.728 399.182 146.751C405.896 144.024 413.112 142.758 420.352 143.038C418.943 141.396 417.127 140.154 415.086 139.439C413.046 138.723 410.854 138.558 408.73 138.961Z"
        fill="#3732A7"
      />
      <path
        d="M442.972 133.008C440.63 133.402 438.426 134.382 436.564 135.856C434.701 137.331 433.24 139.253 432.316 141.444C439.865 138.666 447.842 137.239 455.883 137.226C454.259 135.491 452.225 134.195 449.968 133.457C447.711 132.72 445.304 132.566 442.972 133.008Z"
        fill="#3732A7"
      />
      <path
        d="M447.967 144.733L445.631 145.258C445.118 144.834 444.494 144.566 443.834 144.486C443.174 144.406 442.505 144.517 441.905 144.805C441.306 145.094 440.802 145.548 440.452 146.115C440.102 146.681 439.921 147.336 439.93 148.002C439.894 148.487 439.957 148.974 440.118 149.433C440.278 149.893 440.53 150.314 440.86 150.67C441.19 151.027 441.59 151.311 442.035 151.506C442.48 151.7 442.96 151.801 443.445 151.801C443.931 151.801 444.411 151.7 444.856 151.506C445.3 151.311 445.7 151.027 446.03 150.67C446.36 150.314 446.613 149.893 446.773 149.433C446.933 148.974 446.997 148.487 446.96 148.002C446.959 147.737 446.925 147.473 446.859 147.215L448.35 145.339C448.396 145.276 448.423 145.201 448.426 145.124C448.429 145.046 448.408 144.969 448.367 144.904C448.325 144.838 448.265 144.787 448.194 144.756C448.122 144.726 448.043 144.718 447.967 144.733Z"
        fill="#272425"
      />
      <path
        d="M402.465 149.334L404.862 149.475C405.384 148.874 406.094 148.469 406.877 148.325C407.785 148.2 408.705 148.435 409.444 148.978C410.182 149.522 410.68 150.333 410.832 151.238C410.984 152.144 410.778 153.073 410.258 153.829C409.737 154.585 408.944 155.108 408.045 155.287C407.59 155.362 407.124 155.347 406.676 155.241C406.227 155.135 405.803 154.942 405.429 154.672C405.054 154.402 404.737 154.061 404.495 153.668C404.252 153.275 404.09 152.838 404.016 152.381C403.976 152.121 403.976 151.855 404.016 151.594L402.224 150C402.168 149.949 402.128 149.882 402.11 149.809C402.092 149.735 402.097 149.658 402.122 149.586C402.148 149.515 402.195 149.453 402.256 149.408C402.317 149.363 402.39 149.338 402.465 149.334Z"
        fill="#272425"
      />
      <path
        d="M427.885 167.941C424.712 168.488 421.496 168.745 418.277 168.707C418.711 169.638 419.395 170.429 420.252 170.993C421.11 171.556 422.107 171.869 423.132 171.896C425.206 171.896 427.019 169.817 427.885 167.941Z"
        fill="#DB9387"
      />
      <path
        d="M447.685 173.551C438.861 176.767 429.541 178.406 420.151 178.394C421.73 180.853 423.966 182.82 426.603 184.072C429.241 185.324 432.176 185.81 435.076 185.477C440.756 184.63 446.034 179.06 447.685 173.551Z"
        fill="white"
      />
      <path
        d="M413.584 110.992C417.522 109.039 421.023 106.304 423.873 102.952C426.723 99.601 428.862 95.7037 430.161 91.4976L388.063 112.344C392.186 113.866 396.575 114.53 400.962 114.298C405.35 114.065 409.644 112.941 413.584 110.992Z"
        fill="#151161"
      />
      <path
        d="M420.291 87.4413C422.654 87.4328 424.99 86.9396 427.155 85.9921C429.32 85.0446 431.269 83.6629 432.88 81.9321L407.662 82.0936C409.29 83.8029 411.251 85.1594 413.423 86.0793C415.596 86.9991 417.933 87.4627 420.291 87.4413Z"
        fill="#151161"
      />
      <path
        d="M456.79 100.902C458.378 103.26 460.433 105.267 462.827 106.798C465.221 108.329 467.904 109.351 470.708 109.801L453.648 84.6564C453.036 87.4356 452.999 90.311 453.539 93.105C454.08 95.8991 455.186 98.5525 456.79 100.902Z"
        fill="#151161"
      />
      <path
        d="M415.901 166.77C413.262 163.904 409.858 162.734 408.307 164.147L401.378 170.524C402.929 169.091 406.333 170.281 408.972 173.147C411.61 176.013 412.476 179.524 410.986 180.957L417.915 174.58C419.426 173.147 418.539 169.656 415.901 166.77Z"
        fill="#797979"
      />
      <path
        d="M408.891 173.147C406.252 170.281 402.848 169.111 401.297 170.524L396.584 174.862C398.135 173.429 401.539 174.6 404.157 177.486C406.776 180.371 407.682 183.863 406.172 185.295L410.905 180.957C412.476 179.605 411.61 176.033 408.891 173.147Z"
        fill="#B1B1B1"
      />
      <path
        d="M404.238 177.587C401.62 174.701 398.195 173.551 396.665 174.963L387.822 183.035L397.37 193.469L406.192 185.396C407.763 183.863 406.877 180.372 404.238 177.587Z"
        fill="#797979"
      />
      <path
        d="M451.19 166.77C448.551 163.904 445.147 162.734 443.596 164.147L436.667 170.524C438.218 169.091 441.622 170.281 444.261 173.147C446.9 176.013 447.766 179.524 446.275 180.957L453.204 174.58C454.614 173.147 453.748 169.656 451.19 166.77Z"
        fill="#797979"
      />
      <path
        d="M444.18 173.147C441.542 170.281 438.138 169.111 436.587 170.524L431.853 174.862C433.424 173.429 436.808 174.6 439.447 177.486C442.085 180.371 442.972 183.863 441.461 185.295L446.195 180.957C447.685 179.605 446.799 176.033 444.18 173.147Z"
        fill="#B1B1B1"
      />
      <path
        d="M439.447 177.587C436.808 174.701 433.404 173.551 431.853 174.963L423.011 183.035L432.558 193.469L441.401 185.396C442.972 183.863 442.086 180.372 439.447 177.587Z"
        fill="#797979"
      />
      <path
        d="M380.168 184.488L364.396 198.998H428.429V239.177L443.898 225.051C444.74 224.283 445.412 223.348 445.874 222.306C446.335 221.264 446.575 220.137 446.577 218.997V188.726C446.58 187.912 446.422 187.105 446.112 186.352C445.802 185.599 445.347 184.915 444.772 184.339C444.197 183.764 443.514 183.307 442.763 182.997C442.012 182.687 441.206 182.528 440.393 182.531H385.747C383.711 182.475 381.725 183.172 380.168 184.488Z"
        fill="#3732A7"
      />
      <path
        d="M428.429 246.482V278.488C421.561 290.596 398.397 294.208 398.397 294.208V246.482H428.429Z"
        fill="#3732A7"
      />
      <path
        d="M354.225 198.998H435.882L389.917 242.931C386.897 245.82 384.494 249.293 382.853 253.14C381.211 256.986 380.367 261.127 380.369 265.31V387.159H301.814V260.851C301.821 256.56 302.72 252.319 304.454 248.396C306.188 244.473 308.719 240.955 311.885 238.067L354.225 198.998Z"
        fill="#9FB5FE"
      />
      <path
        d="M435.882 198.998V239.702L410.14 263.131C408.974 264.196 408.043 265.492 407.405 266.938C406.767 268.383 406.436 269.946 406.434 271.526V387.159H380.37V265.31C380.367 261.127 381.212 256.986 382.853 253.14C384.494 249.293 386.897 245.82 389.917 242.931L435.882 198.998Z"
        fill="#84A0EF"
      />
      <path
        d="M397.933 387.159H284.149V419.811H397.933V387.159Z"
        fill="#B6C9FF"
      />
      <path
        d="M446.195 387.159H397.954V419.811H446.195V387.159Z"
        fill="#9FB5FE"
      />
      <path
        d="M386.835 394.727H383.35C383.885 394.727 384.397 394.939 384.775 395.318C385.152 395.696 385.365 396.209 385.365 396.745V410.165C385.365 410.7 385.152 411.213 384.775 411.592C384.397 411.97 383.885 412.183 383.35 412.183H386.835C387.369 412.183 387.882 411.97 388.259 411.592C388.637 411.213 388.849 410.7 388.849 410.165V396.745C388.849 396.209 388.637 395.696 388.259 395.318C387.882 394.939 387.369 394.727 386.835 394.727Z"
        fill="#4E4E4E"
      />
      <path
        d="M383.35 394.727H361.556C361.022 394.727 360.51 394.939 360.132 395.318C359.754 395.696 359.542 396.209 359.542 396.745V410.165C359.542 410.7 359.754 411.213 360.132 411.592C360.51 411.97 361.022 412.183 361.556 412.183H383.35C383.885 412.183 384.397 411.97 384.775 411.592C385.152 411.213 385.365 410.7 385.365 410.165V396.745C385.365 396.209 385.152 395.696 384.775 395.318C384.397 394.939 383.885 394.727 383.35 394.727Z"
        fill="#5E5E5E"
      />
      <path
        d="M380.752 398.743V408.873L377.026 405.523L379.463 403.061L380.752 398.743Z"
        fill="#D1422F"
      />
      <path
        d="M375.777 398.743H380.752L379.463 403.061H374.488L375.777 398.743Z"
        fill="#D6634F"
      />
      <path
        d="M372.05 405.523H377.026L379.463 403.061H374.488L372.05 405.523Z"
        fill="#DC925B"
      />
      <path
        d="M380.752 408.873H375.777L372.05 405.523H377.026L380.752 408.873Z"
        fill="#D6634F"
      />
      <path
        d="M370.076 408.873V398.743L366.35 402.092L368.787 404.554L370.076 408.873Z"
        fill="#59A635"
      />
      <path
        d="M365.101 408.873H370.077L368.788 404.554H363.812L365.101 408.873Z"
        fill="#84DC5B"
      />
      <path
        d="M361.375 402.092H366.35L368.787 404.554H363.812L361.375 402.092Z"
        fill="#59943D"
      />
      <path
        d="M370.076 398.743H365.101L361.375 402.092H366.35L370.076 398.743Z"
        fill="#84DC5B"
      />
      <path
        d="M317.283 395.534H314C315.364 395.534 316.672 396.075 317.639 397.04C318.605 398.004 319.151 399.313 319.156 400.68V407.904C319.151 409.271 318.605 410.58 317.639 411.544C316.672 412.509 315.364 413.05 314 413.05H317.283C318.647 413.05 319.956 412.509 320.922 411.544C321.889 410.58 322.434 409.271 322.439 407.904V400.68C322.434 399.313 321.889 398.004 320.922 397.04C319.956 396.075 318.647 395.534 317.283 395.534Z"
        fill="#4E4E4E"
      />
      <path
        d="M314 395.534H302.418C301.744 395.534 301.076 395.667 300.453 395.925C299.829 396.184 299.263 396.563 298.786 397.041C298.309 397.519 297.931 398.086 297.673 398.71C297.415 399.335 297.282 400.004 297.282 400.68V407.904C297.282 408.58 297.415 409.249 297.673 409.874C297.931 410.498 298.309 411.065 298.786 411.543C299.263 412.021 299.829 412.4 300.453 412.659C301.076 412.917 301.744 413.05 302.418 413.05H314C315.364 413.05 316.673 412.509 317.639 411.544C318.606 410.58 319.151 409.271 319.157 407.904V400.68C319.151 399.313 318.606 398.004 317.639 397.04C316.673 396.075 315.364 395.534 314 395.534Z"
        fill="#5E5E5E"
      />
      <path
        d="M285.68 280.082C283.887 280.082 282.417 283.15 282.417 286.923C282.417 290.697 283.887 293.744 285.68 293.744H301.794V280.082H285.68Z"
        fill="#3732A7"
      />
      <path
        d="M292.327 336.547C286.848 336.547 282.417 345.83 282.417 357.292C282.417 368.755 286.848 378.017 292.327 378.017H301.733V336.547H292.327Z"
        fill="#3732A7"
      />
      <path
        d="M293.717 267.328C292.81 267.328 292.085 268.862 292.085 270.739C292.085 272.616 292.81 274.149 293.717 274.149H301.774V267.328H293.717Z"
        fill="#3732A7"
      />
      <path
        d="M457.072 326.235H406.434V338.343H457.072V326.235Z"
        fill="#3732A7"
      />
      <path
        d="M522.957 253.061L523.199 262.546C523.77 284.42 528.873 305.937 538.185 325.73L544.127 338.363L522.957 253.061Z"
        fill="#D1DAFF"
      />
      <path
        d="M511.235 359.512C509.502 348.171 503.621 339.332 497.518 338.605C496.85 338.509 496.171 338.509 495.504 338.605C488.756 339.634 484.828 350.713 486.742 363.225C488.655 375.737 495.685 385.161 502.433 384.132C509.18 383.103 513.148 372.104 511.235 359.512Z"
        fill="#E4D3D5"
      />
      <path
        d="M505.957 348.857L474.313 351.763L475.361 373.336L503.298 375.354C506.069 371.576 507.778 367.125 508.249 362.461C508.72 357.798 507.936 353.093 505.977 348.837L505.957 348.857Z"
        fill="#E9B49D"
      />
      <path
        d="M513.773 348.15L505.958 348.857C507.912 353.111 508.695 357.812 508.224 362.471C507.753 367.13 506.045 371.578 503.279 375.353L517.197 376.322L513.773 348.15Z"
        fill="black"
      />
      <path
        d="M405.507 339.594L416.122 334.549C417.281 334.006 418.531 333.684 419.808 333.6L432.377 332.834C434.113 332.733 435.842 333.117 437.372 333.944L464.706 348.837H474.334C475.325 348.845 476.3 349.098 477.171 349.573C478.042 350.048 478.783 350.731 479.329 351.561C481.546 355.031 482.703 359.074 482.657 363.194C482.61 367.313 481.362 371.33 479.067 374.748C478.334 375.838 477.221 376.614 475.947 376.924C474.672 377.234 473.328 377.055 472.178 376.423L466.76 373.335L458.482 374.244C451.695 374.983 444.887 373.059 439.487 368.876L416.102 371.055C415.46 371.119 414.817 370.95 414.289 370.58C413.761 370.21 413.382 369.663 413.222 369.037C413.14 368.673 413.134 368.296 413.204 367.929C413.273 367.563 413.417 367.215 413.627 366.906C413.837 366.598 414.107 366.336 414.422 366.137C414.737 365.937 415.089 365.805 415.457 365.748L427.885 363.952L409.012 364.698C408.343 364.728 407.686 364.513 407.163 364.094C406.641 363.674 406.288 363.079 406.172 362.418C406.059 361.739 406.202 361.042 406.575 360.463C406.947 359.885 407.522 359.466 408.186 359.29L417.915 356.384L429.033 355.173L415.639 354.911L405.668 356.707C404.856 356.851 404.019 356.676 403.332 356.217C402.645 355.759 402.162 355.052 401.982 354.245C401.813 353.489 401.924 352.698 402.295 352.018C402.665 351.339 403.27 350.817 403.996 350.552L414.45 346.415L427.966 343.953L418.962 343.065L408.045 346.173C407.591 346.302 407.116 346.338 406.648 346.276C406.18 346.215 405.729 346.059 405.324 345.817C404.918 345.575 404.566 345.253 404.29 344.87C404.013 344.487 403.817 344.051 403.714 343.59C403.505 342.819 403.57 341.999 403.897 341.27C404.224 340.542 404.793 339.949 405.507 339.594Z"
        fill="#EBC162"
      />
      <path
        d="M439.105 334.993L446.859 366.05L445.067 363.487C442.196 359.391 440.188 354.753 439.163 349.854C438.138 344.956 438.118 339.899 439.105 334.993Z"
        fill="#DD8947"
      />
      <path
        d="M427.885 363.972L426.536 363.548C421.425 361.968 415.934 362.131 410.925 364.012L409.012 364.719L427.885 363.972Z"
        fill="#DD8947"
      />
      <path
        d="M429.033 355.173L423.555 353.902C418.423 352.725 413.046 353.286 408.266 355.496L405.668 356.707L415.639 354.911L429.033 355.173Z"
        fill="#DD8947"
      />
      <path
        d="M427.885 343.892L418.962 343.065L408.045 346.173L415.337 341.854C416.334 341.267 417.454 340.921 418.608 340.844C419.762 340.767 420.918 340.961 421.984 341.41L427.885 343.892Z"
        fill="#DD8947"
      />
      <path
        d="M419.647 370.813L426.778 368.795C430.737 367.741 434.903 367.741 438.863 368.795L439.568 368.976L419.647 370.813Z"
        fill="#DD8947"
      />
      <path
        d="M586.305 343.671L560.12 223.234C559.303 219.504 557.446 216.083 554.764 213.368C552.083 210.653 548.688 208.756 544.973 207.897C536.21 216.569 529.893 227.409 526.664 239.318L522.937 253.061L544.107 338.363L497.497 338.605C503.54 339.433 509.482 348.171 511.214 359.512C513.128 372.105 509.2 383.123 502.473 384.152L562.9 376.726C566.678 376.26 570.318 375.01 573.587 373.056C576.856 371.101 579.682 368.485 581.885 365.374C584.088 362.263 585.618 358.726 586.379 354.989C587.141 351.252 587.115 347.397 586.305 343.671Z"
        fill="white"
      />
      <path
        d="M544.127 338.363H574.683L567.513 334.327C563.762 332.141 559.363 331.347 555.087 332.085C550.811 332.823 546.931 335.046 544.127 338.363Z"
        fill="#D1DAFF"
      />
      <path
        d="M549.888 215.243C546.728 216.879 543.79 218.914 541.146 221.297C535.051 226.81 530.461 233.791 527.812 241.578C526.641 244.957 525.896 248.468 525.596 252.032C526.681 248.69 527.945 245.409 529.383 242.204C532.636 234.984 537.014 228.329 542.355 222.488C544.721 219.895 547.237 217.442 549.888 215.142V215.243Z"
        fill="#D1DAFF"
      />
      <path
        d="M526.16 252.052C529.121 248.695 532.254 245.496 535.547 242.466C539.112 239.237 542.999 236.412 546.081 234.132C541.584 235.331 537.435 237.58 533.972 240.695C530.51 243.809 527.833 247.701 526.16 252.052Z"
        fill="#D1DAFF"
      />
      <path
        d="M559.959 273.544C550.169 289.297 544.919 307.456 544.792 326.013C546.484 318.566 548.619 308.92 551.378 299.455C554.138 289.991 557.421 280.667 559.959 273.463V273.544Z"
        fill="#D1DAFF"
      />
      <path
        d="M543.986 267.248C539.505 283.227 539.255 300.1 543.261 316.205C543.08 309.364 542.757 300.545 542.898 291.726C543.039 282.908 543.603 274.089 543.986 267.248Z"
        fill="#D1DAFF"
      />
      <path
        d="M283.605 355.052C283.819 353.398 283.632 351.716 283.061 350.148C282.231 351.591 281.845 353.249 281.954 354.911C279.818 354.81 277.442 354.527 275.307 354.427C275.032 354.118 274.813 353.762 274.662 353.377C273.877 351.637 273.693 349.684 274.138 347.828C273.203 348.274 272.442 349.02 271.976 349.947C271.51 350.875 271.364 351.931 271.563 352.95C271.761 353.969 272.292 354.893 273.072 355.577C273.852 356.26 274.836 356.665 275.871 356.727L282.699 357.09L288.057 356.646C288.871 356.577 289.632 356.21 290.193 355.614C290.754 355.018 291.076 354.236 291.098 353.418C290.488 353.92 289.81 354.335 289.084 354.649C287.349 355.371 285.427 355.512 283.605 355.052Z"
        fill="#DD8947"
      />
      <path
        d="M288.882 350.209L284.33 348.574L276.837 347.424C275.917 347.287 274.978 347.427 274.138 347.828C273.693 349.684 273.877 351.637 274.662 353.377C274.813 353.762 275.031 354.118 275.306 354.427C277.441 354.528 279.818 354.81 281.953 354.911C281.845 353.249 282.231 351.592 283.061 350.148C283.632 351.716 283.818 353.398 283.605 355.052C285.433 355.517 287.363 355.375 289.104 354.649C289.829 354.335 290.508 353.92 291.118 353.418C291.134 352.714 290.926 352.024 290.524 351.447C290.122 350.87 289.547 350.436 288.882 350.209Z"
        fill="#EBC162"
      />
      <path
        d="M284.612 364.073C283.964 362.341 283.77 360.472 284.048 358.644C284.962 360.125 285.578 361.77 285.861 363.488C287.647 363.193 289.3 362.354 290.594 361.086C290.811 360.872 291.013 360.642 291.199 360.4C291.234 359.801 291.094 359.205 290.796 358.685C290.51 358.213 290.103 357.825 289.618 357.563C289.133 357.3 288.587 357.172 288.036 357.191H282.658L275.83 356.828V359.209C275.832 360.529 276.263 361.811 277.059 362.862C279.441 363.875 282.034 364.291 284.612 364.073Z"
        fill="#EBC162"
      />
      <path
        d="M290.595 361.086C289.3 362.354 287.648 363.193 285.861 363.488C285.578 361.77 284.962 360.125 284.048 358.644C283.77 360.472 283.964 362.341 284.612 364.073C282.041 364.258 279.462 363.815 277.099 362.781C277.994 363.989 279.297 364.829 280.765 365.142L282.195 365.425C283.783 365.758 285.438 365.477 286.828 364.638L289.688 362.902C290.127 362.643 290.495 362.278 290.758 361.842C291.022 361.405 291.173 360.91 291.199 360.4C291.014 360.642 290.812 360.871 290.595 361.086Z"
        fill="#DD8947"
      />
      <path
        d="M282.86 347.444C281.694 346.872 280.582 346.197 279.536 345.426C280.139 344.076 280.527 342.639 280.685 341.168C279.549 342.111 278.681 343.336 278.167 344.72L270.372 340.341C270.835 339.029 271.218 337.657 271.681 336.305C270.558 337.175 269.7 338.343 269.203 339.675C268.176 338.979 267.416 337.955 267.048 336.769C266.831 336.078 266.762 335.349 266.847 334.63C266.446 335.077 266.149 335.609 265.979 336.185C265.81 336.762 265.771 337.369 265.865 337.963C265.96 338.557 266.186 339.122 266.526 339.617C266.867 340.112 267.313 340.525 267.834 340.825L282.538 349.422C283.478 349.947 284.585 350.089 285.628 349.819C286.671 349.548 287.569 348.886 288.137 347.969C288.289 347.705 288.418 347.428 288.52 347.142C287.927 347.592 287.237 347.896 286.506 348.029C285.259 348.195 283.992 347.991 282.86 347.444Z"
        fill="#DD8947"
      />
      <path
        d="M286.506 342.076L271.62 333.802C270.843 333.403 269.957 333.267 269.096 333.417C268.234 333.566 267.445 333.992 266.847 334.63C266.762 335.349 266.831 336.078 267.048 336.769C267.416 337.954 268.176 338.979 269.203 339.675C269.7 338.342 270.558 337.175 271.681 336.305C271.218 337.616 270.835 338.989 270.372 340.341L278.167 344.72C278.681 343.335 279.549 342.111 280.685 341.168C280.527 342.639 280.139 344.076 279.536 345.426C280.582 346.197 281.694 346.872 282.86 347.444C283.986 347.987 285.246 348.191 286.486 348.029C287.217 347.896 287.907 347.592 288.5 347.141C288.823 346.197 288.799 345.169 288.434 344.241C288.068 343.313 287.385 342.545 286.506 342.076Z"
        fill="#EBC162"
      />
      <path
        d="M286.848 335.699C285.704 334.387 284.698 332.96 283.847 331.441L281.933 328.354C281.237 327.367 280.75 326.247 280.503 325.064C280.391 323.789 280.703 322.512 281.389 321.432C281.89 320.634 282.463 319.884 283.101 319.192C282.22 319.186 281.354 319.418 280.593 319.864C279.832 320.309 279.204 320.951 278.776 321.723C278.347 322.494 278.134 323.367 278.158 324.25C278.182 325.132 278.442 325.992 278.912 326.739L285.196 336.93C285.798 337.898 286.743 338.602 287.841 338.902C288.939 339.201 290.11 339.074 291.118 338.545L291.38 338.363H291.259C289.551 337.95 288.011 337.02 286.848 335.699Z"
        fill="#DD8947"
      />
      <path
        d="M293.173 332.975L288.842 322.36C288.468 321.453 287.834 320.678 287.02 320.132C286.206 319.586 285.249 319.294 284.27 319.293H283.102C282.464 319.985 281.89 320.735 281.39 321.533C280.703 322.613 280.391 323.89 280.503 325.165C280.751 326.348 281.237 327.468 281.934 328.455L283.847 331.542C284.699 333.061 285.704 334.488 286.848 335.8C288.014 337.113 289.553 338.036 291.259 338.444H291.38C292.273 337.888 292.942 337.036 293.269 336.036C293.597 335.036 293.563 333.952 293.173 332.975Z"
        fill="#EBC162"
      />
      <path
        d="M488.816 261.032C488.816 260.85 488.816 260.669 488.816 260.467C481.368 260.844 473.906 260.844 466.458 260.467C462.188 260.245 457.958 259.821 453.95 259.337C453.95 260.003 453.95 260.669 453.95 261.355C458.011 262.215 462.132 262.761 466.277 262.99C473.845 263.433 481.437 262.773 488.816 261.032Z"
        fill="#59C7D3"
      />
      <path
        d="M465.29 284.744C465.305 287.447 465.747 290.13 466.599 292.695C467.526 295.644 468.884 298.439 470.628 300.989C472.263 303.369 474.207 305.52 476.408 307.386L477.274 304.864C475.772 303.093 474.361 301.248 473.045 299.334C469.987 294.739 467.389 289.851 465.29 284.744Z"
        fill="#59C7D3"
      />
      <path
        d="M463.598 302.2C465.66 307.295 469.304 311.59 473.991 314.449L474.656 312.431C473.87 311.846 473.105 311.221 472.339 310.595C469.247 307.989 466.327 305.185 463.598 302.2Z"
        fill="#59C7D3"
      />
      <path
        d="M513.068 377.19C514.734 374.511 515.92 371.561 516.572 368.472C517.339 364.932 517.488 361.287 517.016 357.696C516.563 354.099 515.486 350.609 513.833 347.384C512.414 344.57 510.519 342.023 508.233 339.856C509.923 342.394 511.347 345.099 512.483 347.929C513.736 351.137 514.601 354.484 515.062 357.898C515.485 361.308 515.485 364.759 515.062 368.169C514.704 371.218 514.029 374.221 513.047 377.129L513.068 377.19Z"
        fill="#D1DAFF"
      />
      <path
        d="M347.235 254.817H312.207C311.851 254.848 311.492 254.805 311.153 254.69C310.814 254.576 310.503 254.391 310.239 254.149C309.975 253.907 309.764 253.613 309.62 253.285C309.476 252.957 309.401 252.602 309.401 252.244C309.401 251.885 309.476 251.531 309.62 251.203C309.764 250.875 309.975 250.58 310.239 250.338C310.503 250.096 310.814 249.912 311.153 249.797C311.492 249.682 311.851 249.639 312.207 249.671H347.154C347.796 249.728 348.394 250.023 348.829 250.5C349.264 250.976 349.505 251.598 349.505 252.244C349.505 252.889 349.264 253.512 348.829 253.988C348.394 254.464 347.796 254.76 347.154 254.817H347.235Z"
        fill="#E0DEDF"
      />
      <path
        d="M370.741 254.817H357.83C357.474 254.848 357.115 254.805 356.776 254.69C356.437 254.576 356.126 254.391 355.862 254.149C355.598 253.907 355.387 253.613 355.243 253.285C355.099 252.957 355.024 252.602 355.024 252.244C355.024 251.885 355.099 251.531 355.243 251.203C355.387 250.875 355.598 250.58 355.862 250.338C356.126 250.096 356.437 249.912 356.776 249.797C357.115 249.682 357.474 249.639 357.83 249.671H370.741C371.098 249.639 371.457 249.682 371.796 249.797C372.135 249.912 372.446 250.096 372.71 250.338C372.974 250.58 373.184 250.875 373.329 251.203C373.473 251.531 373.547 251.885 373.547 252.244C373.547 252.602 373.473 252.957 373.329 253.285C373.184 253.613 372.974 253.907 372.71 254.149C372.446 254.391 372.135 254.576 371.796 254.69C371.457 254.805 371.098 254.848 370.741 254.817Z"
        fill="#E0DEDF"
      />
      <path
        d="M518.788 394.565C515.444 394.565 512.237 395.896 509.872 398.265C507.507 400.634 506.179 403.848 506.179 407.198C506.179 410.548 507.507 413.762 509.872 416.131C512.237 418.5 515.444 419.831 518.788 419.831H606.166V394.565H518.788Z"
        fill="#C85D6F"
      />
      <path
        d="M527.731 407.198C527.731 403.848 529.06 400.634 531.424 398.265C533.789 395.896 536.996 394.565 540.34 394.565H534.64C531.296 394.565 528.089 395.896 525.724 398.265C523.359 400.634 522.031 403.848 522.031 407.198C522.031 410.548 523.359 413.762 525.724 416.131C528.089 418.5 531.296 419.831 534.64 419.831H540.34C536.998 419.826 533.794 418.493 531.43 416.125C529.067 413.757 527.736 410.547 527.731 407.198Z"
        fill="#ECCD7C"
      />
      <path
        d="M569.063 407.198C569.063 403.848 570.392 400.634 572.757 398.265C575.121 395.896 578.328 394.565 581.673 394.565H576.073C572.913 394.819 569.965 396.255 567.815 398.589C565.665 400.923 564.471 403.982 564.471 407.158C564.471 410.333 565.665 413.393 567.815 415.726C569.965 418.06 572.913 419.497 576.073 419.75H581.773C578.427 419.772 575.209 418.462 572.826 416.108C570.443 413.755 569.09 410.55 569.063 407.198Z"
        fill="#ECCD7C"
      />
      <path
        d="M590.897 407.198C590.903 409.723 591.906 412.142 593.688 413.927C595.47 415.713 597.885 416.718 600.405 416.723H664.478V397.653H600.405C597.881 397.658 595.463 398.666 593.681 400.456C591.899 402.245 590.897 404.67 590.897 407.198Z"
        fill="#FEFBF7"
      />
      <path
        d="M624.213 402.799H591.663C591.391 402.799 591.129 402.69 590.937 402.497C590.744 402.304 590.636 402.043 590.636 401.77C590.636 401.497 590.744 401.235 590.937 401.042C591.129 400.849 591.391 400.74 591.663 400.74H624.213C624.486 400.74 624.747 400.849 624.94 401.042C625.133 401.235 625.241 401.497 625.241 401.77C625.241 402.043 625.133 402.304 624.94 402.497C624.747 402.69 624.486 402.799 624.213 402.799Z"
        fill="#EFE1DF"
      />
      <path
        d="M637.85 402.799H630.659C630.522 402.801 630.387 402.777 630.26 402.726C630.133 402.676 630.017 402.6 629.92 402.505C629.822 402.409 629.745 402.294 629.692 402.168C629.639 402.042 629.612 401.906 629.612 401.77C629.612 401.633 629.639 401.497 629.692 401.371C629.745 401.245 629.822 401.13 629.92 401.035C630.017 400.939 630.133 400.863 630.26 400.813C630.387 400.762 630.522 400.738 630.659 400.74H637.85C638.122 400.74 638.384 400.849 638.576 401.042C638.769 401.235 638.877 401.497 638.877 401.77C638.877 402.043 638.769 402.304 638.576 402.497C638.384 402.69 638.122 402.799 637.85 402.799Z"
        fill="#EFE1DF"
      />
      <path
        d="M610.96 407.319H589.609C589.46 407.349 589.306 407.346 589.158 407.309C589.011 407.272 588.873 407.203 588.755 407.106C588.638 407.01 588.543 406.888 588.478 406.751C588.413 406.613 588.379 406.463 588.379 406.31C588.379 406.158 588.413 406.007 588.478 405.87C588.543 405.732 588.638 405.611 588.755 405.514C588.873 405.417 589.011 405.348 589.158 405.311C589.306 405.275 589.46 405.271 589.609 405.301H610.96C611.109 405.271 611.263 405.275 611.41 405.311C611.558 405.348 611.695 405.417 611.813 405.514C611.931 405.611 612.025 405.732 612.09 405.87C612.155 406.007 612.189 406.158 612.189 406.31C612.189 406.463 612.155 406.613 612.09 406.751C612.025 406.888 611.931 407.01 611.813 407.106C611.695 407.203 611.558 407.272 611.41 407.309C611.263 407.346 611.109 407.349 610.96 407.319Z"
        fill="#EFE1DF"
      />
      <path
        d="M650.338 407.319H619.963C619.814 407.349 619.66 407.346 619.513 407.309C619.365 407.272 619.227 407.203 619.11 407.106C618.992 407.01 618.897 406.888 618.832 406.751C618.767 406.613 618.733 406.463 618.733 406.31C618.733 406.158 618.767 406.007 618.832 405.87C618.897 405.732 618.992 405.611 619.11 405.514C619.227 405.417 619.365 405.348 619.513 405.311C619.66 405.275 619.814 405.271 619.963 405.301H650.338C650.57 405.348 650.78 405.474 650.93 405.658C651.08 405.842 651.163 406.072 651.163 406.31C651.163 406.548 651.08 406.778 650.93 406.962C650.78 407.146 650.57 407.272 650.338 407.319Z"
        fill="#EFE1DF"
      />
      <path
        d="M658.174 407.319H655.998C655.765 407.272 655.556 407.146 655.406 406.962C655.255 406.778 655.173 406.548 655.173 406.31C655.173 406.072 655.255 405.842 655.406 405.658C655.556 405.474 655.765 405.348 655.998 405.301H658.174C658.323 405.271 658.477 405.275 658.624 405.311C658.772 405.348 658.909 405.417 659.027 405.514C659.145 405.611 659.239 405.732 659.304 405.87C659.369 406.007 659.403 406.158 659.403 406.31C659.403 406.463 659.369 406.613 659.304 406.751C659.239 406.888 659.145 407.01 659.027 407.106C658.909 407.203 658.772 407.272 658.624 407.309C658.477 407.346 658.323 407.349 658.174 407.319Z"
        fill="#EFE1DF"
      />
      <path
        d="M630.659 413.111H590.898C590.762 413.114 590.627 413.089 590.501 413.038C590.375 412.987 590.261 412.912 590.165 412.816C590.069 412.719 589.994 412.605 589.943 412.479C589.892 412.353 589.868 412.218 589.87 412.082C589.87 411.809 589.979 411.547 590.171 411.354C590.364 411.161 590.625 411.052 590.898 411.052H630.659C630.931 411.052 631.192 411.161 631.385 411.354C631.578 411.547 631.686 411.809 631.686 412.082C631.686 412.355 631.578 412.616 631.385 412.809C631.192 413.002 630.931 413.111 630.659 413.111Z"
        fill="#EFE1DF"
      />
      <path
        d="M664.478 416.723H600.405C597.957 416.61 595.646 415.556 593.953 413.781C592.26 412.005 591.316 409.644 591.316 407.188C591.316 404.732 592.26 402.371 593.953 400.595C595.646 398.82 597.957 397.766 600.405 397.653H664.478C664.695 397.678 664.915 397.656 665.124 397.59C665.332 397.524 665.524 397.414 665.687 397.269C665.85 397.123 665.981 396.944 666.07 396.744C666.159 396.544 666.206 396.328 666.206 396.109C666.206 395.89 666.159 395.673 666.07 395.474C665.981 395.274 665.85 395.095 665.687 394.949C665.524 394.804 665.332 394.694 665.124 394.628C664.915 394.562 664.695 394.54 664.478 394.565H600.405C597.061 394.565 593.854 395.896 591.489 398.265C589.124 400.634 587.796 403.848 587.796 407.198C587.796 410.548 589.124 413.762 591.489 416.131C593.854 418.5 597.061 419.831 600.405 419.831H664.478C664.682 419.831 664.883 419.791 665.072 419.713C665.26 419.635 665.431 419.52 665.575 419.376C665.719 419.231 665.833 419.06 665.911 418.872C665.989 418.683 666.029 418.481 666.029 418.277C666.029 418.073 665.989 417.871 665.911 417.682C665.833 417.494 665.719 417.323 665.575 417.178C665.431 417.034 665.26 416.919 665.072 416.841C664.883 416.763 664.682 416.723 664.478 416.723Z"
        fill="#A54A59"
      />
      <path
        d="M559.214 336.527C556.687 336.527 554.263 337.532 552.477 339.322C550.69 341.112 549.686 343.54 549.686 346.072C549.686 348.603 550.69 351.031 552.477 352.821C554.263 354.611 556.687 355.617 559.214 355.617H625.18V336.527H559.214Z"
        fill="#4E5A68"
      />
      <path
        d="M613.659 346.072C613.659 347.983 614.416 349.815 615.765 351.166C617.113 352.517 618.942 353.276 620.85 353.276H669.191V338.868H620.85C618.942 338.868 617.113 339.627 615.765 340.978C614.416 342.329 613.659 344.161 613.659 346.072Z"
        fill="#FEFBF7"
      />
      <path
        d="M638.817 342.762H614.344C614.231 342.781 614.116 342.774 614.007 342.743C613.897 342.713 613.796 342.659 613.709 342.585C613.622 342.511 613.553 342.419 613.505 342.315C613.457 342.212 613.433 342.099 613.433 341.985C613.433 341.871 613.457 341.759 613.505 341.655C613.553 341.552 613.622 341.46 613.709 341.386C613.796 341.312 613.897 341.258 614.007 341.227C614.116 341.197 614.231 341.19 614.344 341.208H638.917C639.03 341.19 639.145 341.197 639.254 341.227C639.364 341.258 639.465 341.312 639.552 341.386C639.639 341.46 639.708 341.552 639.756 341.655C639.804 341.759 639.828 341.871 639.828 341.985C639.828 342.099 639.804 342.212 639.756 342.315C639.708 342.419 639.639 342.511 639.552 342.585C639.465 342.659 639.364 342.713 639.254 342.743C639.145 342.774 639.03 342.781 638.917 342.762H638.817Z"
        fill="#EFE1DF"
      />
      <path
        d="M649.129 342.762H643.671C643.487 342.733 643.319 342.638 643.198 342.496C643.077 342.353 643.011 342.172 643.011 341.985C643.011 341.798 643.077 341.618 643.198 341.475C643.319 341.333 643.487 341.238 643.671 341.208H649.129C649.314 341.238 649.481 341.333 649.602 341.475C649.723 341.618 649.789 341.798 649.789 341.985C649.789 342.172 649.723 342.353 649.602 342.496C649.481 342.638 649.314 342.733 649.129 342.762Z"
        fill="#EFE1DF"
      />
      <path
        d="M628.806 346.173H612.692C612.484 346.173 612.284 346.09 612.136 345.942C611.989 345.795 611.906 345.594 611.906 345.386C611.906 345.177 611.989 344.977 612.136 344.829C612.284 344.682 612.484 344.599 612.692 344.599H628.806C629.014 344.599 629.214 344.682 629.361 344.829C629.509 344.977 629.591 345.177 629.591 345.386C629.591 345.594 629.509 345.795 629.361 345.942C629.214 346.09 629.014 346.173 628.806 346.173Z"
        fill="#EFE1DF"
      />
      <path
        d="M658.536 346.173H635.614C635.405 346.173 635.206 346.09 635.058 345.942C634.911 345.795 634.828 345.594 634.828 345.386C634.828 345.177 634.911 344.977 635.058 344.829C635.206 344.682 635.405 344.599 635.614 344.599H658.536C658.744 344.599 658.944 344.682 659.091 344.829C659.239 344.977 659.321 345.177 659.321 345.386C659.321 345.594 659.239 345.795 659.091 345.942C658.944 346.09 658.744 346.173 658.536 346.173Z"
        fill="#EFE1DF"
      />
      <path
        d="M664.478 346.173H662.826C662.618 346.173 662.418 346.09 662.271 345.942C662.123 345.795 662.041 345.594 662.041 345.386C662.041 345.177 662.123 344.977 662.271 344.829C662.418 344.682 662.618 344.599 662.826 344.599H664.478C664.686 344.599 664.886 344.682 665.033 344.829C665.181 344.977 665.263 345.177 665.263 345.386C665.263 345.594 665.181 345.795 665.033 345.942C664.886 346.09 664.686 346.173 664.478 346.173Z"
        fill="#EFE1DF"
      />
      <path
        d="M643.671 350.552H613.659C613.452 350.547 613.255 350.462 613.109 350.316C612.963 350.169 612.878 349.972 612.873 349.765C612.878 349.56 612.964 349.365 613.11 349.221C613.257 349.078 613.454 348.998 613.659 348.998H643.671C643.773 348.995 643.875 349.013 643.97 349.051C644.065 349.088 644.152 349.144 644.225 349.215C644.299 349.287 644.357 349.372 644.396 349.467C644.436 349.561 644.457 349.663 644.457 349.765C644.457 349.974 644.374 350.174 644.227 350.321C644.079 350.469 643.879 350.552 643.671 350.552Z"
        fill="#EFE1DF"
      />
      <path
        d="M669.231 353.276H620.89C618.983 353.276 617.153 352.517 615.805 351.166C614.456 349.815 613.699 347.983 613.699 346.072C613.699 344.161 614.456 342.329 615.805 340.978C617.153 339.627 618.983 338.868 620.89 338.868H669.231C669.541 338.868 669.838 338.744 670.057 338.525C670.276 338.305 670.4 338.007 670.4 337.697C670.4 337.387 670.276 337.089 670.057 336.869C669.838 336.65 669.541 336.527 669.231 336.527H620.89C618.363 336.527 615.94 337.532 614.153 339.322C612.366 341.112 611.362 343.54 611.362 346.072C611.362 348.603 612.366 351.031 614.153 352.821C615.94 354.611 618.363 355.617 620.89 355.617H669.231C669.541 355.617 669.838 355.494 670.057 355.274C670.276 355.055 670.4 354.757 670.4 354.447C670.4 354.136 670.276 353.839 670.057 353.619C669.838 353.4 669.541 353.276 669.231 353.276Z"
        fill="#3D444D"
      />
      <path
        d="M608.563 355.617H685.325C685.86 355.617 686.372 355.83 686.75 356.208C687.127 356.587 687.34 357.1 687.34 357.635C687.34 358.171 687.127 358.684 686.75 359.062C686.372 359.441 685.86 359.653 685.325 359.653H608.563C608.028 359.653 607.516 359.441 607.138 359.062C606.761 358.684 606.548 358.171 606.548 357.635C606.548 357.1 606.761 356.587 607.138 356.208C607.516 355.83 608.028 355.617 608.563 355.617Z"
        fill="#8C5233"
      />
      <path
        d="M608.563 392.487H685.325C685.86 392.487 686.372 392.699 686.75 393.078C687.127 393.456 687.34 393.97 687.34 394.505C687.34 395.04 687.127 395.553 686.75 395.932C686.372 396.31 685.86 396.523 685.325 396.523H608.563C608.028 396.523 607.516 396.31 607.138 395.932C606.761 395.553 606.548 395.04 606.548 394.505C606.548 393.97 606.761 393.456 607.138 393.078C607.516 392.699 608.028 392.487 608.563 392.487Z"
        fill="#8C5233"
      />
      <path
        d="M608.583 392.487H684.399V359.734H608.583V392.487Z"
        fill="#EFE1DF"
      />
      <path
        d="M524.065 369.017V383.143C524.065 385.605 525.041 387.966 526.779 389.707C528.517 391.448 530.873 392.426 533.331 392.426H608.563V359.734H533.331C532.114 359.734 530.909 359.974 529.785 360.441C528.661 360.907 527.639 361.591 526.779 362.453C525.919 363.315 525.236 364.338 524.77 365.464C524.305 366.591 524.065 367.798 524.065 369.017Z"
        fill="white"
      />
      <path
        d="M556.998 366.373H522.957C522.685 366.373 522.424 366.265 522.231 366.072C522.038 365.879 521.93 365.617 521.93 365.344C521.93 365.071 522.038 364.809 522.231 364.616C522.424 364.423 522.685 364.315 522.957 364.315H556.998C557.134 364.312 557.269 364.337 557.394 364.388C557.52 364.438 557.635 364.514 557.731 364.61C557.826 364.706 557.902 364.821 557.953 364.947C558.003 365.073 558.028 365.208 558.025 365.344C558.025 365.617 557.917 365.879 557.724 366.072C557.532 366.265 557.271 366.373 556.998 366.373Z"
        fill="#EFE1DF"
      />
      <path
        d="M581.672 366.373H565.296C565.16 366.376 565.024 366.351 564.897 366.301C564.77 366.25 564.655 366.175 564.557 366.079C564.459 365.983 564.382 365.869 564.329 365.743C564.276 365.617 564.249 365.481 564.249 365.344C564.249 365.207 564.276 365.072 564.329 364.946C564.382 364.82 564.459 364.705 564.557 364.609C564.655 364.514 564.77 364.438 564.897 364.388C565.024 364.337 565.16 364.312 565.296 364.315H581.672C581.945 364.315 582.206 364.424 582.399 364.617C582.591 364.81 582.699 365.071 582.699 365.344C582.699 365.617 582.591 365.879 582.399 366.072C582.206 366.265 581.945 366.373 581.672 366.373Z"
        fill="#EFE1DF"
      />
      <path
        d="M584.13 373.598H546.705C546.486 373.535 546.294 373.403 546.157 373.221C546.02 373.039 545.945 372.817 545.945 372.589C545.945 372.361 546.02 372.139 546.157 371.957C546.294 371.775 546.486 371.642 546.705 371.58H584.13C584.349 371.642 584.541 371.775 584.678 371.957C584.815 372.139 584.889 372.361 584.889 372.589C584.889 372.817 584.815 373.039 584.678 373.221C584.541 373.403 584.349 373.535 584.13 373.598Z"
        fill="#EFE1DF"
      />
      <path
        d="M562.155 381.408H529.746C529.513 381.361 529.304 381.235 529.153 381.051C529.003 380.867 528.921 380.636 528.921 380.399C528.921 380.161 529.003 379.93 529.153 379.746C529.304 379.562 529.513 379.436 529.746 379.39H562.175C562.407 379.436 562.617 379.562 562.767 379.746C562.917 379.93 563 380.161 563 380.399C563 380.636 562.917 380.867 562.767 381.051C562.617 381.235 562.407 381.361 562.175 381.408H562.155Z"
        fill="#EFE1DF"
      />
      <path
        d="M595.873 381.408H581.109C580.876 381.361 580.667 381.235 580.516 381.051C580.366 380.867 580.284 380.636 580.284 380.399C580.284 380.161 580.366 379.93 580.516 379.746C580.667 379.562 580.876 379.436 581.109 379.39H595.873C596.106 379.436 596.315 379.562 596.465 379.746C596.616 379.93 596.698 380.161 596.698 380.399C596.698 380.636 596.616 380.867 596.465 381.051C596.315 381.235 596.106 381.361 595.873 381.408Z"
        fill="#EFE1DF"
      />
      <path
        d="M573.656 388.551H522.957C522.739 388.489 522.546 388.356 522.409 388.174C522.272 387.992 522.198 387.77 522.198 387.542C522.198 387.314 522.272 387.092 522.409 386.91C522.546 386.728 522.739 386.596 522.957 386.533H573.656C573.875 386.596 574.067 386.728 574.204 386.91C574.341 387.092 574.416 387.314 574.416 387.542C574.416 387.77 574.341 387.992 574.204 388.174C574.067 388.356 573.875 388.489 573.656 388.551Z"
        fill="#EFE1DF"
      />
      <path
        d="M533.331 396.624H608.563C609.097 396.624 609.609 396.411 609.987 396.033C610.365 395.654 610.577 395.141 610.577 394.606C610.577 394.07 610.365 393.557 609.987 393.179C609.609 392.8 609.097 392.588 608.563 392.588H533.331C530.874 392.588 528.517 391.61 526.779 389.869C525.042 388.128 524.065 385.767 524.065 383.305V369.178C524.065 366.716 525.042 364.355 526.779 362.614C528.517 360.873 530.874 359.895 533.331 359.895H608.563C609.097 359.895 609.609 359.683 609.987 359.304C610.365 358.926 610.577 358.413 610.577 357.877C610.577 357.342 610.365 356.829 609.987 356.45C609.609 356.072 609.097 355.859 608.563 355.859H533.331C529.785 355.865 526.387 357.278 523.88 359.79C521.373 362.302 519.962 365.707 519.956 369.259V383.385C520.004 386.909 521.433 390.273 523.936 392.75C526.438 395.227 529.813 396.619 533.331 396.624Z"
        fill="#BB6C4E"
      />
      <path
        d="M82.2616 272.636V236.997H108.769V272.797C108.796 276.096 109.727 279.323 111.46 282.128C113.194 284.932 115.663 287.205 118.599 288.699C128.563 293.895 136.499 302.29 141.136 312.541C145.772 322.792 146.842 334.305 144.172 345.237C141.503 356.169 135.249 365.887 126.413 372.835C117.576 379.783 106.668 383.56 95.4348 383.56C84.2017 383.56 73.2935 379.783 64.457 372.835C55.6205 365.887 49.3668 356.169 46.6974 345.237C44.028 334.305 45.0972 322.792 49.7339 312.541C54.3707 302.29 62.3066 293.895 72.271 288.699C75.2492 287.201 77.7574 284.908 79.5199 282.074C81.2824 279.241 82.2311 275.975 82.2616 272.636Z"
        fill="white"
      />
      <path
        d="M81.1133 236.997H109.917C110.916 236.997 111.874 236.6 112.58 235.892C113.287 235.184 113.684 234.225 113.684 233.224C113.684 232.221 113.287 231.259 112.582 230.548C111.876 229.837 110.918 229.435 109.917 229.43H81.1133C80.109 229.43 79.1458 229.83 78.4357 230.541C77.7255 231.252 77.3265 232.217 77.3265 233.224C77.3319 234.226 77.7332 235.186 78.4428 235.893C79.1523 236.6 80.1125 236.997 81.1133 236.997Z"
        fill="white"
      />
      <path
        d="M105.285 338.181C100.141 339.51 94.9212 340.521 89.654 341.209C77.3352 342.56 64.8811 340.538 53.6193 335.356C68.2427 332.45 90.8022 333.439 105.285 338.181Z"
        fill="#BE6573"
      />
      <path
        d="M89.654 341.208C94.9212 340.521 100.141 339.51 105.285 338.181C108.729 337.374 112.173 336.547 115.658 335.901C122.803 334.322 130.198 334.253 137.371 335.699C136.753 346.435 132.05 356.525 124.229 363.891C116.409 371.257 106.067 375.339 95.333 375.295C84.5993 375.251 74.2911 371.085 66.531 363.655C58.7709 356.225 54.1498 346.097 53.6193 335.356C64.8811 340.537 77.3352 342.56 89.654 341.208Z"
        fill="#E16878"
      />
      <path
        d="M113.261 321.492C113.261 321.975 113.404 322.447 113.671 322.849C113.939 323.251 114.32 323.563 114.765 323.748C115.211 323.933 115.701 323.981 116.173 323.887C116.646 323.793 117.08 323.56 117.421 323.219C117.762 322.877 117.994 322.442 118.088 321.969C118.182 321.495 118.134 321.004 117.95 320.558C117.765 320.112 117.453 319.73 117.052 319.462C116.651 319.194 116.18 319.051 115.698 319.051C115.051 319.051 114.432 319.308 113.974 319.766C113.517 320.224 113.261 320.845 113.261 321.492Z"
        fill="#E16878"
      />
      <path
        d="M96.9856 324.903C96.9856 325.463 97.1515 326.01 97.4623 326.475C97.773 326.94 98.2146 327.302 98.7311 327.515C99.2475 327.728 99.8156 327.783 100.363 327.672C100.911 327.562 101.413 327.291 101.807 326.893C102.2 326.496 102.467 325.991 102.574 325.442C102.68 324.892 102.622 324.324 102.405 323.808C102.188 323.292 101.824 322.852 101.358 322.544C100.891 322.236 100.344 322.074 99.7854 322.078C99.041 322.083 98.3289 322.383 97.8044 322.912C97.2799 323.442 96.9856 324.157 96.9856 324.903Z"
        fill="#E16878"
      />
      <path
        d="M86.6124 314.369C86.6124 314.768 86.7306 315.158 86.9519 315.49C87.1732 315.822 87.4878 316.08 87.8559 316.233C88.2239 316.386 88.6289 316.426 89.0196 316.348C89.4104 316.27 89.7693 316.078 90.051 315.796C90.3326 315.513 90.5245 315.154 90.6022 314.762C90.6799 314.371 90.64 313.965 90.4876 313.596C90.3351 313.228 90.077 312.912 89.7457 312.691C89.4145 312.469 89.025 312.351 88.6267 312.351C88.0925 312.351 87.5801 312.563 87.2024 312.942C86.8246 313.32 86.6124 313.833 86.6124 314.369Z"
        fill="#E16878"
      />
      <path
        d="M98.4156 306.498C98.4156 307.258 98.6406 308 99.062 308.631C99.4834 309.262 100.082 309.753 100.783 310.043C101.483 310.332 102.254 310.407 102.997 310.257C103.74 310.108 104.422 309.741 104.956 309.202C105.49 308.664 105.853 307.979 105.999 307.234C106.144 306.489 106.065 305.717 105.773 305.017C105.48 304.317 104.986 303.719 104.354 303.3C103.722 302.882 102.98 302.66 102.223 302.664C101.721 302.664 101.224 302.763 100.761 302.956C100.298 303.149 99.8772 303.432 99.5235 303.788C99.1697 304.145 98.8897 304.568 98.6996 305.033C98.5094 305.498 98.4129 305.996 98.4156 306.498Z"
        fill="#E16878"
      />
      <path
        d="M81.9997 299.577C81.9997 300.028 82.1332 300.469 82.3833 300.844C82.6334 301.219 82.9889 301.511 83.4048 301.683C83.8207 301.856 84.2783 301.901 84.7199 301.813C85.1614 301.725 85.5669 301.508 85.8853 301.189C86.2036 300.87 86.4204 300.464 86.5082 300.021C86.596 299.579 86.5509 299.121 86.3787 298.704C86.2064 298.287 85.9146 297.931 85.5403 297.681C85.166 297.43 84.726 297.296 84.2758 297.296C83.9762 297.294 83.679 297.351 83.4016 297.464C83.1243 297.578 82.8723 297.746 82.6604 297.958C82.4485 298.17 82.2809 298.423 82.1675 298.701C82.0541 298.979 81.997 299.276 81.9997 299.577Z"
        fill="#E16878"
      />
      <path
        d="M73.5601 321.492C73.5601 321.975 73.703 322.447 73.9708 322.849C74.2386 323.251 74.6193 323.563 75.0646 323.748C75.51 323.933 76 323.981 76.4728 323.887C76.9456 323.793 77.3798 323.56 77.7207 323.219C78.0615 322.877 78.2937 322.442 78.3877 321.969C78.4818 321.495 78.4335 321.004 78.249 320.558C78.0645 320.112 77.7522 319.73 77.3514 319.462C76.9506 319.194 76.4793 319.051 75.9973 319.051C75.3509 319.051 74.731 319.308 74.2739 319.766C73.8168 320.224 73.5601 320.845 73.5601 321.492Z"
        fill="#E16878"
      />
      <path
        d="M94.6695 294.814C95.9377 294.814 96.9658 293.784 96.9658 292.513C96.9658 291.243 95.9377 290.213 94.6695 290.213C93.4014 290.213 92.3733 291.243 92.3733 292.513C92.3733 293.784 93.4014 294.814 94.6695 294.814Z"
        fill="#E16878"
      />
      <path
        d="M116.403 350.814C118.027 350.814 119.344 349.495 119.344 347.868C119.344 346.241 118.027 344.922 116.403 344.922C114.779 344.922 113.462 346.241 113.462 347.868C113.462 349.495 114.779 350.814 116.403 350.814Z"
        fill="#BE6573"
      />
      <path
        d="M109.534 357.999C109.582 358.46 109.799 358.888 110.143 359.198C110.488 359.509 110.934 359.681 111.398 359.681C111.861 359.681 112.308 359.509 112.652 359.198C112.996 358.888 113.213 358.46 113.261 357.999C113.288 357.737 113.26 357.473 113.179 357.223C113.097 356.973 112.965 356.743 112.789 356.548C112.613 356.353 112.399 356.196 112.159 356.09C111.919 355.983 111.66 355.927 111.398 355.927C111.135 355.927 110.876 355.983 110.636 356.09C110.396 356.196 110.182 356.353 110.006 356.548C109.83 356.743 109.698 356.973 109.616 357.223C109.535 357.473 109.507 357.737 109.534 357.999Z"
        fill="#BE6573"
      />
      <path
        d="M97.5295 349.119C97.5414 349.752 97.7399 350.367 98.0998 350.887C98.4598 351.407 98.9652 351.809 99.5526 352.042C100.14 352.275 100.783 352.329 101.401 352.196C102.018 352.064 102.583 351.752 103.024 351.299C103.465 350.846 103.763 350.272 103.879 349.65C103.996 349.028 103.926 348.385 103.679 347.803C103.432 347.221 103.018 346.724 102.49 346.377C101.962 346.029 101.344 345.846 100.712 345.85C100.288 345.853 99.8687 345.939 99.4785 346.106C99.0883 346.272 98.7349 346.514 98.4388 346.818C98.1428 347.122 97.9099 347.482 97.7539 347.877C97.5978 348.272 97.5215 348.694 97.5295 349.119Z"
        fill="#BE6573"
      />
      <path
        d="M95.1126 360.803C95.0796 361.193 95.1278 361.586 95.2541 361.956C95.3803 362.326 95.5819 362.666 95.846 362.954C96.1101 363.242 96.4311 363.472 96.7885 363.629C97.1459 363.786 97.5321 363.867 97.9225 363.867C98.3129 363.867 98.699 363.786 99.0564 363.629C99.4139 363.472 99.7348 363.242 99.9989 362.954C100.263 362.666 100.465 362.326 100.591 361.956C100.717 361.586 100.765 361.193 100.732 360.803C100.673 360.098 100.351 359.441 99.8304 358.962C99.3101 358.483 98.6291 358.217 97.9225 358.217C97.2158 358.217 96.5349 358.483 96.0145 358.962C95.4942 359.441 95.1723 360.098 95.1126 360.803Z"
        fill="#BE6573"
      />
      <path
        d="M80.308 363.972C80.308 364.598 80.4935 365.211 80.8409 365.732C81.1884 366.253 81.6823 366.659 82.2602 366.899C82.838 367.139 83.4739 367.201 84.0873 367.079C84.7007 366.957 85.2642 366.655 85.7065 366.212C86.1487 365.769 86.4499 365.204 86.5719 364.59C86.694 363.975 86.6313 363.338 86.392 362.759C86.1526 362.18 85.7473 361.686 85.2273 361.337C84.7072 360.989 84.0958 360.803 83.4703 360.803C82.6316 360.803 81.8273 361.137 81.2342 361.731C80.6412 362.326 80.308 363.131 80.308 363.972Z"
        fill="#BE6573"
      />
      <path
        d="M83.4701 351.137C83.4661 351.454 83.5566 351.765 83.7299 352.03C83.9032 352.296 84.1515 352.503 84.443 352.626C84.7346 352.749 85.0562 352.783 85.3667 352.722C85.6773 352.661 85.9627 352.509 86.1864 352.285C86.4101 352.06 86.5621 351.774 86.6229 351.463C86.6836 351.152 86.6504 350.83 86.5275 350.538C86.4046 350.246 86.1975 349.997 85.9327 349.823C85.668 349.65 85.3576 349.559 85.0412 349.563C84.6245 349.563 84.2249 349.729 83.9303 350.024C83.6356 350.319 83.4701 350.72 83.4701 351.137Z"
        fill="#BE6573"
      />
      <path
        d="M67.4166 352.045C67.405 352.634 67.5674 353.213 67.8834 353.71C68.1994 354.206 68.6549 354.598 69.1925 354.836C69.7302 355.074 70.3261 355.147 70.9053 355.047C71.4844 354.947 72.0211 354.677 72.4477 354.272C72.8743 353.867 73.1719 353.344 73.303 352.77C73.4342 352.196 73.393 351.596 73.1847 351.046C72.9763 350.495 72.6102 350.018 72.1322 349.675C71.6543 349.333 71.0858 349.139 70.4984 349.119C70.1029 349.103 69.7081 349.166 69.3375 349.306C68.9668 349.445 68.6278 349.657 68.3405 349.93C68.0532 350.203 67.8234 350.53 67.6647 350.894C67.506 351.257 67.4216 351.649 67.4166 352.045Z"
        fill="#BE6573"
      />
      <path
        d="M231.638 408.813C232.22 408.813 232.778 408.581 233.19 408.168C233.602 407.756 233.833 407.196 233.833 406.613V130.99C233.833 130.407 233.602 129.848 233.19 129.435C232.778 129.023 232.22 128.791 231.638 128.791C231.055 128.791 230.497 129.023 230.085 129.435C229.673 129.848 229.442 130.407 229.442 130.99V406.613C229.442 407.196 229.673 407.756 230.085 408.168C230.497 408.581 231.055 408.813 231.638 408.813Z"
        fill="#505050"
      />
      <path
        d="M213.51 419.831H249.786L235.324 406.613H227.992L213.51 419.831Z"
        fill="#CBCBCB"
      />
      <path
        d="M68.4841 251.749H249.766C250.348 251.749 250.907 251.518 251.318 251.105C251.73 250.693 251.961 250.133 251.961 249.55C251.961 248.966 251.73 248.407 251.318 247.994C250.907 247.582 250.348 247.35 249.766 247.35H68.4841C67.9018 247.35 67.3434 247.582 66.9316 247.994C66.5199 248.407 66.2886 248.966 66.2886 249.55C66.2886 250.133 66.5199 250.693 66.9316 251.105C67.3434 251.518 67.9018 251.749 68.4841 251.749Z"
        fill="#505050"
      />
      <path
        d="M223.682 249.55C223.682 251.146 224.154 252.707 225.039 254.034C225.925 255.362 227.183 256.396 228.655 257.007C230.128 257.618 231.748 257.778 233.31 257.466C234.873 257.155 236.309 256.386 237.436 255.257C238.563 254.128 239.33 252.69 239.641 251.124C239.952 249.558 239.792 247.935 239.182 246.46C238.572 244.985 237.54 243.725 236.215 242.838C234.89 241.951 233.332 241.477 231.739 241.477C229.602 241.477 227.552 242.328 226.041 243.842C224.53 245.355 223.682 247.409 223.682 249.55Z"
        fill="#6D7797"
      />
      <path
        d="M77.6691 411.94H113.341L120.512 419.831H70.4984L77.6691 411.94Z"
        fill="#98999B"
      />
      <path
        d="M88.6668 404.03H102.364L109.534 411.94H81.4961L88.6668 404.03Z"
        fill="#CBCBCB"
      />
      <path
        d="M80.9523 379.854C83.3142 379.769 85.6499 380.372 87.6768 381.59C89.7037 382.808 91.3349 384.588 92.373 386.715C93.3188 384.219 93.3188 381.462 92.373 378.966C94.2289 379.216 95.95 380.075 97.2676 381.408C98.5444 382.74 99.3265 384.471 99.4833 386.311C100.546 385.393 101.806 384.733 103.165 384.383C104.524 384.033 105.946 384.002 107.319 384.293C106.58 384.578 105.942 385.075 105.484 385.721C105.025 386.367 104.767 387.134 104.74 387.926C104.62 389.54 105.022 391.135 105.043 392.769C105.044 394.131 104.77 395.478 104.238 396.731C103.705 397.984 102.925 399.115 101.945 400.058C100.964 401.001 99.8036 401.735 98.5325 402.216C97.2614 402.698 95.9061 402.916 94.5484 402.859C90.8623 402.678 86.7734 400.68 85.585 397.189C85.0211 395.161 84.8772 393.039 85.162 390.953C85.4643 388.879 85.4643 386.771 85.162 384.697C84.8778 383.62 84.3503 382.624 83.6203 381.784C82.8904 380.944 81.9775 380.284 80.9523 379.854Z"
        fill="#E2AC42"
      />
      <path
        d="M87.5793 389.197C87.1595 391.69 87.4818 394.252 88.5058 396.563C89.2155 397.877 90.2967 398.953 91.6139 399.654C92.9312 400.355 94.4257 400.651 95.9101 400.504C97.3946 400.358 98.8027 399.775 99.958 398.83C101.113 397.885 101.964 396.619 102.404 395.191C102.866 393.204 102.914 391.143 102.545 389.137C102.011 390.585 101.003 391.808 99.6849 392.608C99.2022 390.362 97.8934 388.381 96.0189 387.058C95.9911 388.315 95.6095 389.539 94.9183 390.589C94.227 391.638 93.254 392.471 92.1113 392.991C91.8843 391.744 91.3538 390.572 90.5667 389.58C89.7797 388.587 88.7605 387.804 87.5994 387.3C87.6493 387.932 87.6426 388.567 87.5793 389.197Z"
        fill="#D78C1B"
      />
      <path
        d="M88.9085 363.387C90.3842 364.085 91.5322 365.329 92.1112 366.858C92.6762 368.364 93.1603 369.9 93.5614 371.459C93.9747 373.004 94.8121 374.402 95.9785 375.495C94.6837 375.383 93.4463 374.909 92.4066 374.128C91.3669 373.347 90.5667 372.289 90.0969 371.075C89.1859 368.619 88.7816 366.004 88.9085 363.387Z"
        fill="#E2AC42"
      />
      <path
        d="M105.063 380.5C105.827 378.7 107.104 377.165 108.734 376.088C110.364 375.012 112.275 374.44 114.227 374.445C113.627 376.181 112.464 377.666 110.924 378.663C109.198 379.849 107.156 380.489 105.063 380.5Z"
        fill="#E2AC42"
      />
      <path
        d="M80.0257 378.865C79.4184 377.499 78.3278 376.406 76.9641 375.797C75.5875 375.22 74.45 374.187 73.7413 372.871C73.395 373.744 73.2909 374.694 73.4398 375.622C73.5887 376.549 73.9852 377.419 74.5873 378.138C75.35 378.728 76.2544 379.105 77.2092 379.233C78.164 379.36 79.1355 379.233 80.0257 378.865Z"
        fill="#E2AC42"
      />
      <path
        d="M103.25 190.623C102.718 190.633 102.186 190.673 101.659 190.744C102.095 189.27 102.319 187.741 102.323 186.204C102.317 184.124 101.899 182.067 101.095 180.15C104.906 179.831 108.436 178.009 110.906 175.083C113.376 172.158 114.586 168.369 114.268 164.55C113.95 160.731 112.131 157.196 109.211 154.721C106.291 152.245 102.509 151.034 98.6979 151.352C95.8235 151.345 93.0144 152.21 90.6409 153.834C89.4564 150.137 87.2748 146.839 84.337 144.305C81.3992 141.771 77.8196 140.101 73.9935 139.477C70.1675 138.854 66.2439 139.302 62.6562 140.773C59.0684 142.243 55.9562 144.678 53.6632 147.81C51.3702 150.941 49.9857 154.646 49.6625 158.516C49.3394 162.386 50.0903 166.271 51.8323 169.74C53.5742 173.209 56.2394 176.129 59.5334 178.176C62.8275 180.223 66.6222 181.318 70.4985 181.34H71.284C70.5026 183.73 70.2988 186.272 70.6894 188.756C71.08 191.24 72.0539 193.596 73.5309 195.629C75.0079 197.663 76.9458 199.316 79.185 200.452C81.4242 201.589 83.9009 202.176 86.411 202.166C88.1785 202.159 89.9333 201.866 91.6077 201.299C91.5877 201.661 91.5877 202.025 91.6077 202.388C91.5818 205.383 92.7069 208.274 94.7499 210.46C93.46 210.62 92.2434 211.149 91.2464 211.984C90.2495 212.82 89.5146 213.926 89.1301 215.17C88.7456 216.414 88.7279 217.743 89.079 218.996C89.4302 220.25 90.1353 221.376 91.1096 222.238C92.0839 223.099 93.2859 223.661 94.5712 223.855C95.8564 224.049 97.1702 223.867 98.3546 223.33C99.5391 222.794 100.544 221.927 101.248 220.832C101.952 219.737 102.325 218.462 102.323 217.16C102.325 216.027 102.048 214.912 101.518 213.911C102.091 213.999 102.67 214.046 103.25 214.052C106.272 213.927 109.129 212.636 111.224 210.449C113.318 208.262 114.487 205.348 114.487 202.318C114.487 199.287 113.318 196.374 111.224 194.187C109.129 192 106.272 190.708 103.25 190.583V190.623Z"
        fill="white"
      />
      <path
        d="M117.249 122.737C117.249 125.371 116.469 127.946 115.009 130.136C113.548 132.327 111.472 134.034 109.042 135.042C106.613 136.05 103.94 136.314 101.361 135.8C98.7827 135.286 96.4139 134.017 94.5547 132.155C92.6955 130.292 91.4294 127.919 90.9165 125.335C90.4035 122.752 90.6668 120.073 91.673 117.64C92.6791 115.206 94.3831 113.126 96.5693 111.662C98.7554 110.199 101.326 109.418 103.955 109.418C107.481 109.418 110.862 110.821 113.355 113.319C115.848 115.817 117.249 119.204 117.249 122.737Z"
        fill="white"
      />
      <path
        d="M52.8938 129.396C52.8938 130.713 52.504 132.001 51.7736 133.096C51.0432 134.191 50.0051 135.045 48.7905 135.549C47.576 136.053 46.2395 136.185 44.9501 135.928C43.6607 135.671 42.4763 135.037 41.5467 134.105C40.6171 133.174 39.9841 131.987 39.7276 130.695C39.4711 129.404 39.6027 128.065 40.1058 126.848C40.6089 125.631 41.4609 124.591 42.554 123.859C43.6471 123.127 44.9322 122.737 46.2469 122.737C48.0097 122.737 49.7004 123.438 50.947 124.687C52.1935 125.936 52.8938 127.63 52.8938 129.396Z"
        fill="white"
      />
      <path
        d="M205.553 250.821L180.758 196.092C177.135 188.163 175.253 179.547 175.239 170.826V142.332H153.425V170.826C153.427 179.54 151.573 188.154 147.986 196.092L123.171 250.821C122.207 252.968 121.795 255.323 121.971 257.671C122.147 260.019 122.906 262.285 124.179 264.264C125.452 266.244 127.198 267.873 129.26 269.003C131.321 270.134 133.632 270.731 135.982 270.739H192.743C195.093 270.731 197.404 270.134 199.465 269.003C201.526 267.873 203.273 266.244 204.546 264.264C205.818 262.285 206.577 260.019 206.753 257.671C206.929 255.323 206.517 252.968 205.553 250.821Z"
        fill="white"
      />
      <path
        d="M175.239 136.056H153.566C153.151 136.056 152.74 136.138 152.357 136.299C151.974 136.46 151.627 136.696 151.336 136.993C151.046 137.29 150.817 137.642 150.664 138.029C150.51 138.415 150.436 138.828 150.444 139.244C150.441 139.657 150.52 140.066 150.675 140.447C150.831 140.829 151.061 141.176 151.351 141.469C151.641 141.762 151.986 141.994 152.366 142.152C152.746 142.311 153.154 142.392 153.566 142.392H175.239C176.058 142.371 176.837 142.03 177.409 141.442C177.981 140.854 178.301 140.065 178.301 139.244C178.311 138.416 177.996 137.617 177.423 137.02C176.85 136.423 176.065 136.077 175.239 136.056Z"
        fill="white"
      />
      <path
        d="M141.863 222.084C147.451 225.527 154.047 226.952 160.555 226.12C164.462 225.518 168.266 224.37 171.855 222.71C165.289 221.095 151.672 218.391 141.863 222.084Z"
        fill="#DD8947"
      />
      <path
        d="M200.86 252.96L184.605 217.039C183.215 217.584 181.866 218.169 180.577 218.775C177.696 220.086 174.836 221.519 171.916 222.811C168.326 224.471 164.523 225.619 160.616 226.221C154.108 227.052 147.512 225.628 141.923 222.185L127.945 253.061C127.33 254.417 127.064 255.906 127.17 257.391C127.276 258.877 127.751 260.313 128.552 261.568C129.352 262.823 130.453 263.858 131.754 264.578C133.055 265.299 134.515 265.682 136.002 265.694H192.743C194.229 265.682 195.689 265.299 196.991 264.578C198.292 263.858 199.392 262.823 200.193 261.568C200.993 260.313 201.468 258.877 201.574 257.391C201.68 255.906 201.414 254.417 200.8 253.061L200.86 252.96Z"
        fill="#EBC162"
      />
      <path
        d="M153.828 235.787C153.828 236.294 153.678 236.789 153.397 237.211C153.116 237.632 152.716 237.96 152.249 238.154C151.781 238.348 151.267 238.399 150.771 238.3C150.274 238.201 149.819 237.957 149.461 237.599C149.103 237.24 148.859 236.784 148.761 236.287C148.662 235.789 148.713 235.274 148.906 234.806C149.1 234.338 149.428 233.937 149.848 233.656C150.269 233.374 150.764 233.224 151.27 233.224C151.948 233.224 152.599 233.494 153.078 233.974C153.558 234.455 153.828 235.107 153.828 235.787Z"
        fill="#DD8947"
      />
      <path
        d="M166.518 244.182C166.518 244.74 166.352 245.287 166.042 245.751C165.732 246.216 165.292 246.578 164.777 246.792C164.261 247.006 163.694 247.062 163.147 246.953C162.6 246.844 162.098 246.575 161.704 246.179C161.309 245.784 161.041 245.281 160.932 244.733C160.823 244.185 160.879 243.617 161.092 243.101C161.306 242.584 161.667 242.143 162.131 241.833C162.595 241.522 163.14 241.356 163.698 241.356C164.446 241.356 165.163 241.654 165.692 242.184C166.22 242.714 166.518 243.432 166.518 244.182Z"
        fill="#DD8947"
      />
      <path
        d="M169.982 234.091C169.982 234.435 169.88 234.77 169.69 235.056C169.499 235.341 169.229 235.563 168.912 235.695C168.596 235.826 168.247 235.861 167.911 235.794C167.575 235.727 167.267 235.561 167.024 235.319C166.782 235.076 166.617 234.767 166.55 234.43C166.484 234.093 166.518 233.744 166.649 233.427C166.78 233.11 167.002 232.839 167.287 232.648C167.572 232.458 167.907 232.356 168.249 232.356C168.709 232.356 169.149 232.539 169.474 232.864C169.799 233.19 169.982 233.631 169.982 234.091Z"
        fill="#DD8947"
      />
      <path
        d="M182.309 240.146C182.297 240.755 182.105 241.348 181.757 241.848C181.41 242.349 180.922 242.735 180.356 242.958C179.789 243.181 179.17 243.231 178.575 243.102C177.98 242.973 177.437 242.67 177.013 242.232C176.59 241.794 176.306 241.24 176.196 240.64C176.087 240.04 176.157 239.422 176.398 238.862C176.639 238.302 177.04 237.826 177.551 237.494C178.062 237.162 178.659 236.99 179.267 236.997C179.674 237.003 180.075 237.088 180.448 237.25C180.822 237.411 181.159 237.645 181.442 237.937C181.725 238.23 181.947 238.576 182.096 238.955C182.244 239.334 182.317 239.738 182.309 240.146Z"
        fill="#DD8947"
      />
      <path
        d="M154.674 250.337C154.674 250.927 154.499 251.505 154.171 251.996C153.844 252.487 153.378 252.87 152.833 253.096C152.289 253.322 151.689 253.381 151.111 253.266C150.533 253.151 150.002 252.866 149.585 252.449C149.168 252.031 148.884 251.499 148.769 250.919C148.654 250.34 148.713 249.739 148.938 249.194C149.164 248.648 149.546 248.182 150.036 247.853C150.527 247.525 151.103 247.35 151.693 247.35C152.483 247.35 153.242 247.665 153.801 248.225C154.36 248.785 154.674 249.545 154.674 250.337Z"
        fill="#DD8947"
      />
      <path
        d="M170.626 255.866C170.658 256.22 170.615 256.577 170.502 256.914C170.388 257.251 170.205 257.561 169.966 257.823C169.726 258.086 169.435 258.295 169.11 258.439C168.785 258.582 168.434 258.656 168.078 258.656C167.723 258.656 167.372 258.582 167.047 258.439C166.722 258.295 166.431 258.086 166.191 257.823C165.951 257.561 165.769 257.251 165.655 256.914C165.541 256.577 165.499 256.22 165.53 255.866C165.587 255.228 165.88 254.635 166.352 254.202C166.823 253.77 167.439 253.53 168.078 253.53C168.718 253.53 169.334 253.77 169.805 254.202C170.277 254.635 170.57 255.228 170.626 255.866Z"
        fill="#DD8947"
      />
      <path
        d="M146.737 157.406H249.766C250.332 157.416 250.882 157.215 251.31 156.842C251.738 156.47 252.013 155.952 252.082 155.388C252.102 155.077 252.057 154.764 251.948 154.471C251.839 154.178 251.67 153.911 251.451 153.689C251.233 153.466 250.97 153.292 250.679 153.178C250.389 153.064 250.077 153.012 249.766 153.027H146.878C146.308 153.013 145.753 153.212 145.322 153.585C144.89 153.958 144.612 154.478 144.542 155.045C144.52 155.347 144.56 155.651 144.66 155.937C144.761 156.223 144.919 156.484 145.125 156.706C145.331 156.928 145.581 157.104 145.858 157.225C146.136 157.345 146.435 157.407 146.737 157.406Z"
        fill="#505050"
      />
      <path
        d="M223.682 155.227C223.682 156.823 224.154 158.384 225.039 159.711C225.925 161.039 227.183 162.073 228.655 162.684C230.128 163.295 231.748 163.455 233.31 163.144C234.873 162.832 236.309 162.064 237.436 160.935C238.563 159.806 239.33 158.367 239.641 156.802C239.952 155.236 239.792 153.613 239.182 152.138C238.572 150.663 237.54 149.402 236.215 148.515C234.89 147.628 233.332 147.155 231.739 147.155C229.602 147.155 227.552 148.005 226.041 149.519C224.53 151.033 223.682 153.086 223.682 155.227Z"
        fill="#6D7797"
      />
      <path
        d="M280.08 329.12C280.079 327.501 279.623 325.914 278.765 324.542C277.907 323.169 276.681 322.066 275.227 321.358C273.773 320.651 272.149 320.367 270.542 320.539C268.935 320.711 267.408 321.333 266.137 322.333C264.865 323.333 263.9 324.671 263.351 326.194C262.802 327.718 262.691 329.365 263.032 330.948C263.373 332.531 264.151 333.987 265.278 335.148C266.405 336.309 267.835 337.129 269.405 337.515V410.548C269.405 411.083 269.617 411.596 269.995 411.975C270.373 412.353 270.885 412.566 271.419 412.566C271.953 412.566 272.466 412.353 272.843 411.975C273.221 411.596 273.433 411.083 273.433 410.548V337.515C275.321 337.067 277.003 335.995 278.209 334.472C279.415 332.949 280.074 331.064 280.08 329.12ZM271.459 333.641C270.567 333.641 269.695 333.376 268.953 332.879C268.211 332.382 267.632 331.676 267.291 330.85C266.949 330.024 266.86 329.115 267.034 328.239C267.208 327.362 267.638 326.556 268.269 325.924C268.9 325.292 269.704 324.861 270.579 324.687C271.454 324.512 272.362 324.602 273.186 324.944C274.01 325.286 274.715 325.866 275.211 326.609C275.707 327.352 275.971 328.226 275.971 329.12C275.971 330.319 275.496 331.469 274.65 332.317C273.804 333.165 272.656 333.641 271.459 333.641Z"
        fill="#505050"
      />
      <path
        d="M162.771 313.602L165.289 320.221C165.891 321.757 166.199 323.394 166.195 325.044V385.585C166.156 389.065 167.471 392.424 169.863 394.949C172.255 397.473 175.535 398.964 179.006 399.106C180.739 399.135 182.461 398.819 184.072 398.175C185.682 397.531 187.149 396.573 188.386 395.356C189.623 394.139 190.606 392.688 191.278 391.087C191.95 389.485 192.297 387.766 192.3 386.029V325.044C192.299 323.396 192.6 321.761 193.186 320.221L195.704 313.602H162.771Z"
        fill="white"
      />
      <path
        d="M182.47 353.983C180.093 353.781 177.777 353.095 175.4 352.832C174.056 352.674 172.696 352.736 171.372 353.014V386.009C171.474 388.04 172.352 389.955 173.823 391.356C175.295 392.758 177.247 393.54 179.278 393.54C181.308 393.54 183.261 392.758 184.732 391.356C186.204 389.955 187.081 388.04 187.184 386.009V353.72C185.632 354.023 184.046 354.111 182.47 353.983Z"
        fill="#7BE0F1"
      />
      <path
        d="M200.598 313.602L203.116 320.221C203.719 321.757 204.026 323.394 204.023 325.044V385.585C203.983 389.065 205.299 392.424 207.691 394.949C210.082 397.473 213.362 398.964 216.833 399.106C218.567 399.135 220.289 398.819 221.899 398.175C223.51 397.531 224.976 396.573 226.213 395.356C227.45 394.139 228.434 392.688 229.105 391.087C229.777 389.485 230.125 387.766 230.127 386.029V325.044C230.127 323.396 230.427 321.761 231.013 320.221L233.531 313.602H200.598Z"
        fill="white"
      />
      <path
        d="M220.298 353.983C217.921 353.781 215.604 353.095 213.228 352.832C211.884 352.674 210.523 352.736 209.199 353.014V386.009C209.302 388.04 210.179 389.955 211.651 391.356C213.122 392.758 215.075 393.54 217.105 393.54C219.135 393.54 221.088 392.758 222.56 391.356C224.031 389.955 224.909 388.04 225.011 386.009V353.72C223.459 354.023 221.873 354.111 220.298 353.983Z"
        fill="#E16878"
      />
      <path
        d="M238.426 313.602L240.944 320.221C241.546 321.757 241.854 323.394 241.85 325.044V385.585C241.811 389.065 243.126 392.424 245.518 394.949C247.91 397.473 251.189 398.964 254.661 399.106C256.394 399.135 258.116 398.819 259.727 398.175C261.337 397.531 262.803 396.573 264.041 395.356C265.278 394.139 266.261 392.688 266.933 391.087C267.605 389.485 267.952 387.766 267.955 386.029V325.044C267.954 323.396 268.255 321.761 268.841 320.221L271.359 313.602H238.426Z"
        fill="white"
      />
      <path
        d="M258.125 353.983C255.748 353.781 253.432 353.095 251.055 352.832C249.711 352.674 248.351 352.736 247.026 353.014V386.009C247.129 388.04 248.006 389.955 249.478 391.356C250.949 392.758 252.902 393.54 254.932 393.54C256.963 393.54 258.915 392.758 260.387 391.356C261.858 389.955 262.736 388.04 262.838 386.009V353.72C261.286 354.023 259.7 354.111 258.125 353.983Z"
        fill="#EBC162"
      />
      <path
        d="M284.773 327.062H166.638C166.176 325.188 165.101 323.523 163.584 322.334C162.067 321.144 160.196 320.497 158.269 320.497C156.342 320.497 154.471 321.144 152.954 322.334C151.438 323.523 150.362 325.188 149.9 327.062H142.709C142.175 327.062 141.663 327.275 141.285 327.653C140.907 328.032 140.695 328.545 140.695 329.08C140.695 329.615 140.907 330.129 141.285 330.507C141.663 330.885 142.175 331.098 142.709 331.098H149.9C150.275 332.629 151.061 334.029 152.172 335.146C153.283 336.262 154.677 337.054 156.204 337.435V410.467C156.204 411.002 156.417 411.516 156.794 411.894C157.172 412.273 157.685 412.485 158.219 412.485C158.753 412.485 159.265 412.273 159.643 411.894C160.021 411.516 160.233 411.002 160.233 410.467V337.515C161.765 337.14 163.165 336.35 164.28 335.233C165.395 334.116 166.183 332.714 166.558 331.179H284.693C285.227 331.179 285.739 330.966 286.117 330.588C286.495 330.209 286.707 329.696 286.707 329.161C286.707 328.626 286.495 328.112 286.117 327.734C285.739 327.355 285.227 327.143 284.693 327.143L284.773 327.062ZM158.259 333.641C157.367 333.641 156.494 333.376 155.752 332.879C155.01 332.382 154.432 331.676 154.091 330.85C153.749 330.024 153.66 329.115 153.834 328.239C154.008 327.362 154.438 326.556 155.069 325.924C155.7 325.292 156.504 324.861 157.379 324.687C158.254 324.512 159.161 324.602 159.986 324.944C160.81 325.286 161.515 325.866 162.011 326.609C162.506 327.352 162.771 328.226 162.771 329.12C162.771 330.319 162.296 331.469 161.449 332.317C160.603 333.165 159.456 333.641 158.259 333.641Z"
        fill="#505050"
      />
      <path
        d="M181.946 408.873H134.571C132.664 408.873 130.835 409.632 129.487 410.983C128.138 412.334 127.381 414.167 127.381 416.077V419.75H189.157V415.997C189.136 414.097 188.366 412.282 187.016 410.947C185.665 409.613 183.843 408.868 181.946 408.873Z"
        fill="#87735B"
      />
      <path
        d="M293.676 408.873H175.863C174.917 408.87 173.98 409.055 173.105 409.416C172.231 409.777 171.436 410.307 170.766 410.976C170.096 411.645 169.564 412.44 169.202 413.316C168.839 414.191 168.652 415.13 168.652 416.077V419.75H300.887V415.997C300.861 414.098 300.09 412.286 298.74 410.953C297.391 409.62 295.572 408.873 293.676 408.873Z"
        fill="#AB9575"
      />
    </svg>
  );
};

export default AboutImage;
