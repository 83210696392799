import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, compose, createStore } from "redux";
// import { createLogger } from 'redux-logger';

import reducer from "./reducer";

export function initializeStore(defaultState = {}) {
  const middlewares = [
    thunkMiddleware,
    // createLogger()
  ];

  const store = createStore(
    reducer,
    defaultState,
    composeWithDevTools
      ? composeWithDevTools(applyMiddleware(...middlewares))
      : compose(applyMiddleware(...middlewares))
  );

  return store;
}

export const getStore = initializeStore;
