import React from "react";

import { Helmet } from "react-helmet";

const MetaTag = ({
  title = "",
  content = "",
  url = "https://rsdiagnosticenter.com/",
}) => {
  return (
    <Helmet>
      <title>{title ? `Riddhi Siddhi || ${title}` : "Riddhi Siddhi"}</title>
      <meta
        name="description"
        content={
          content
            ? content
            : "Riddhi Siddhi Diagnostic and Research Center  are committed to highest ground of enthusiasm and ethics with the touch of world class technology."
        }
      />
      <meta property="og:url" content={url} />
    </Helmet>
  );
};

export default MetaTag;
