import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ridhisidhi__slider-right`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FaAngleRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ridhisidhi__slider-left`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FaAngleLeft />
    </div>
  );
}

const SliderItem = ({ bannerurl, title }) => {
  return (
    <div className="sliderImageContainer">
      <img src={bannerurl} alt={title} />
    </div>
  );
};
const Index = ({ homeImages = [], isLoading = false }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 100,
    cssEase: "linear",
    initialSlide: 0,
    arrows: true,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="slider ridhisidhi__slider">
      {isLoading ? (
        <div className="loader-sm" />
      ) : (
        <Slider {...settings}>
          {homeImages
            .filter((item) => item.is_active)
            .map((slider) => {
              return <SliderItem {...slider} key={slider.id} />;
            })}
        </Slider>
      )}
    </div>
  );
};

export default Index;
