export const SET_IS_LOADING = "app/SET_IS_LOADING";
export const SET_IS_PERMISSION_LOADING = "@app/SET_IS_PERMISSION_LOADING";
export const SET_ME = "app/SET_ME";
export const SYSTEM_LOGOUT = "app/SYSTEM_LOGOUT";
export const SET_ERRORS = "app/SET_ERRORS";
export const SET_FOOTER_COLOR = "app/SET_FOOTER_COLOR";

export const SET_NOTIFICATION = "app/SET_NOTIFICATION";
export const SET_ERROR_NOTIFICATION = "app/SET_ERROR_NOTIFICATION";
export const RESET_NOTIFICATION = "app/RESET_NOTIFICATION";
