import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { fetchGalleryLists } from "../store/galleryList/actions";

import MetaTag from "../meta-seo/MetaTag";

const GalleryDetails = [
  { id: 1, title: "Bio Chemestry", imgPath: "try" },
  { id: 2, title: "Hemetology", imgPath: "try" },
  { id: 3, title: "Cytology", imgPath: "try" },
  { id: 3, title: "Histopathology", imgPath: "try" },
  { id: 3, title: "Microbiology", imgPath: "try" },
];

const Gallery = ({ fetchGalleryLists, galleryLists = [] }) => {
  useEffect(() => {
    fetchGalleryLists({});
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const communityGallery =
    galleryLists &&
    galleryLists.filter((img) => img.type.toLowerCase() === "community");

  const corporateGallery =
    galleryLists &&
    galleryLists.filter((img) => img.type.toLowerCase() === "corporate");
  const videoGallery =
    galleryLists &&
    galleryLists.filter((img) => img.type.toLowerCase() === "video");

  return (
    <>
      <MetaTag
        title="Gallery"
        content="All the images related to our organization Riddhi Siddhi"
        url="https://rsdiagnosticenter.com/gallery"
      ></MetaTag>
      {/* <Header></Header> */}
      <div className="gallery__page">
        <div className="community">
          <div className="lab__services gallery__card-section constant__padding">
            <h3 className="heading__primary line primary__color">Community</h3>
            <div className="lab__services-cards  gallery__container constant__padding-top">
              {communityGallery.map((item, i) => (
                // <LinkCard imgPath={item.imgPath} desc={item.desc}></LinkCard>
                <React.Fragment key={i}>
                  <svg
                    style={{ visibility: "hidden", position: "absolute" }}
                    width="0"
                    height="0"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                  >
                    <defs>
                      <filter id="round">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation="8"
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                          result="goo"
                        />
                        <feComposite
                          in="SourceGraphic"
                          in2="goo"
                          operator="atop"
                        />
                      </filter>
                    </defs>
                  </svg>
                  <div className="whole__card">
                    <div className="link__card">
                      <div className="link__card-image">
                        {item.bannerurl && <img src={item.bannerurl} alt="" />}
                      </div>
                    </div>
                    <Link className="text__description hide-text">
                      {item.title}
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="Corporate">
          <div className="lab__services gallery__card-section constant__padding">
            <h3 className="heading__primary line primary__color">Corporate</h3>
            <div className="lab__services-cards gallery__container constant__padding-top">
              {corporateGallery.map((item, i) => (
                // <LinkCard imgPath={item.imgPath} desc={item.desc}></LinkCard>
                <React.Fragment key={i}>
                  <svg
                    style={{ visibility: "hidden", position: "absolute" }}
                    width="0"
                    height="0"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                  >
                    <defs>
                      <filter id="round">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation="8"
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                          result="goo"
                        />
                        <feComposite
                          in="SourceGraphic"
                          in2="goo"
                          operator="atop"
                        />
                      </filter>
                    </defs>
                  </svg>
                  <div className="whole__card">
                    <div className="link__card">
                      <div className="link__card-image">
                        {item.bannerurl && <img src={item.bannerurl} alt="" />}
                      </div>
                    </div>
                    <Link className="text__description hide-text">
                      {item.title}
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ galleryListState: { galleryLists } }) => ({
  galleryLists,
});

export default connect(mapStateToProps, {
  fetchGalleryLists,
})(Gallery);
