import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

const helmet = Helmet.renderStatic();
