export default {
  me: "/users/profile/",

  login: "/login/",
  logout: "/logout/",

  changePassword: "/change-password/",
  forgotPassword: "/password-reset/",
  navigation: "/cms/nav/",
  testimonial: "/cms/testimonials/",
  health: "/cms/healthpackage/",
  infographic: "/cms/infographics/",

  service: "/cms/ourservices/",
  lab: "/cms/labservices/",
  team: "/cms/ourteam/",
  reVerifyEmail: "/re-verifyemail/",

  homeGallery: "/cms/home-gallery/",
  galleryList: "/cms/gallery/",
  resetPassword: "/password-reset/confirm/",

  announcement: "/cms/announcement/",
  contact: "/cms/feedback/",
  document: "/cms/document/",
  appointment: "/cms/appointment/",
  homeImage: "/cms/homeslider/",
  notice: "/v1/notices/",
  projectUpdate: "/v1/project-updates/",
  quickLink: "/v1/uploads/",
  quickLinkCategory: "v1/upload-categories/",
  user: "/v1/users/",
  labReport: "/report/report/",

  setPassword: "/verify-email/",

  websiteInformation: "/cms/system-info/",
};
