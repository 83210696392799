// import { createState, removeState, updateState } from "store/utils";
import {
  SET_TESTIMONIALS_DATA,
  SET_TESTIMONIAL_DATA,
  SET_TESTIMONIALS_METADATA,
  SET_SEARCHED_TESTIMONIALS_DATA,
  RESET_SEARCHED_TESTIMONIALS_DATA,
} from "./action-types";

export const initialState = {
  testimonial: undefined,
  testimonials: [],
  searchedTestimonials: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTIMONIAL_DATA:
      return { ...state, testimonial: action.payload };
    case SET_TESTIMONIALS_DATA:
      return { ...state, testimonials: action.payload };
    case RESET_SEARCHED_TESTIMONIALS_DATA:
      return { ...state, searchedTestimonials: undefined };
    case SET_SEARCHED_TESTIMONIALS_DATA:
      return { ...state, searchedTestimonials: action.payload };
    case SET_TESTIMONIALS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
