export const SET_GALLERYLIST_DATA = 'homeImage/SET_GALLERYLIST_DATA';

export const SET_GALLERYLISTS_DATA = 'homeImage/SET_GALLERYLISTS_DATA';

export const SET_GALLERYLISTS_METADATA = 'homeImage/SET_GALLERYLISTS_METADATA';

export const SET_SEARCHED_GALLERYLISTS_DATA =
  'homeImage/SET_SEARCHED_GALLERYLISTS_DATA';
export const RESET_SEARCHED_GALLERYLISTS_DATA =
  'homeImage/RESET_SEARCHED_GALLERYLISTS_DATA';

export const CREATE_GALLERYLIST_DATA = 'homeImage/CREATE_GALLERYLIST_DATA';

export const UPDATE_GALLERYLIST_DATA = 'homeImage/UPDATE_GALLERYLIST_DATA';

export const REMOVE_GALLERYLIST_DATA = 'homeImage/REMOVE_GALLERYLIST_DATA';
