import {
  SET_INFOGRAPHICS_DATA,
  SET_INFOGRAPHIC_DATA,
  SET_INFOGRAPHICS_METADATA,
  SET_SEARCHED_INFOGRAPHICS_DATA,
  RESET_SEARCHED_INFOGRAPHICS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setInfographicData = (payload) => ({
  type: SET_INFOGRAPHIC_DATA,
  payload,
});

export const setInfographicsData = (payload) => ({
  type: SET_INFOGRAPHICS_DATA,
  payload,
});

export const resetSearchedInfographicsData = (payload) => ({
  type: RESET_SEARCHED_INFOGRAPHICS_DATA,
  payload,
});

export const setSearchedInfographicsData = (payload) => ({
  type: SET_SEARCHED_INFOGRAPHICS_DATA,
  payload,
});

export const setInfographicsMetadata = (payload) => ({
  type: SET_INFOGRAPHICS_METADATA,
  payload,
});

export const clearSearchedInfographics = () => async (dispatch) => {
  try {
    dispatch(resetSearchedInfographicsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const clearSearchedPhotos = () => async (dispatch) => {
  try {
    dispatch(resetSearchedInfographicsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchInfographics =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({ url: url.infographic, query, columns, order });

      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);
      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedInfographicsData(data.results))
            : dispatch(setInfographicsData(data.results));
          metadata && dispatch(setInfographicsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchInfographic =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(url.infographic + `${id}/`);
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setInfographicData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
