// import { createState, removeState, updateState } from 'store/utils';
import {
  SET_GALLERYLISTS_DATA,
  SET_GALLERYLIST_DATA,
  SET_GALLERYLISTS_METADATA,
  SET_SEARCHED_GALLERYLISTS_DATA,
  RESET_SEARCHED_GALLERYLISTS_DATA,
} from "./action-types";

export const initialState = {
  galleryList: undefined,
  galleryLists: [],
  searchedGalleryLists: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GALLERYLIST_DATA:
      return { ...state, galleryList: action.payload };
    case SET_GALLERYLISTS_DATA:
      return { ...state, galleryLists: action.payload };
    case RESET_SEARCHED_GALLERYLISTS_DATA:
      return { ...state, searchedGalleryLists: undefined };
    case SET_SEARCHED_GALLERYLISTS_DATA:
      return { ...state, searchedGalleryLists: action.payload };
    case SET_GALLERYLISTS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
