import {
  SET_ANNOUNCEMENTS_DATA,
  SET_ANNOUNCEMENT_DATA,
  SET_ANNOUNCEMENTS_METADATA,
  SET_SEARCHED_ANNOUNCEMENTS_DATA,
  RESET_SEARCHED_ANNOUNCEMENTS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setAnnouncementData = (payload) => ({
  type: SET_ANNOUNCEMENT_DATA,
  payload,
});

export const setAnnouncementsData = (payload) => ({
  type: SET_ANNOUNCEMENTS_DATA,
  payload,
});

export const resetSearchedAnnouncementsData = (payload) => ({
  type: RESET_SEARCHED_ANNOUNCEMENTS_DATA,
  payload,
});

export const setSearchedAnnouncementsData = (payload) => ({
  type: SET_SEARCHED_ANNOUNCEMENTS_DATA,
  payload,
});

export const setAnnouncementsMetadata = (payload) => ({
  type: SET_ANNOUNCEMENTS_METADATA,
  payload,
});

export const clearSearchedAnnouncements = () => async (dispatch) => {
  try {
    dispatch(resetSearchedAnnouncementsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchAnnouncements =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({
        url: url.announcement,
        query,
        columns,
        order,
      });

      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);
      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedAnnouncementsData(data.results))
            : dispatch(setAnnouncementsData(data.results));
          metadata && dispatch(setAnnouncementsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchAnnouncement =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(url.announcement + `${id}/`);
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setAnnouncementData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
