import React from "react";

import { Route, Switch } from "react-router-dom";
import PageTitle from "../components/BodyWrapper/Index";

import { routes } from "../routes";

const Content = ({ setFooterColor }) => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Switch>
        {routes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <PageTitle {...route} setFooterColor={setFooterColor}>
                <route.component {...props} theme={route.theme} />
              </PageTitle>
            )}
          />
        ))}
      </Switch>
    </>
  );
};

export default Content;
