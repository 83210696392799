import React, { useEffect } from "react";
import { connect } from "react-redux";

import VSlider from "../../components/HeroSlider/Index";

import { fetchMe } from "../../store/app/actions";
import { fetchHomeImages } from "../../store/homeImage/actions";
const homeImage = [{ id: 1, url: "try2", title: "try" }];

const HeroSection = ({ fetchHomeImages, homeImages = [] }) => {
  useEffect(() => {
    fetchHomeImages({});
  }, []);

  return (
    <div className="hero__section">
      {/* <img src={homeImages[0].bannerurl} alt="" /> */}
      <VSlider homeImages={homeImages} />
    </div>
  );
};

const mapStateToProps = ({
  appState: { me },
  homeImageState: { homeImages },
}) => ({
  me,
  homeImages,
});

export default connect(mapStateToProps, {
  fetchMe,
  fetchHomeImages,
})(HeroSection);
