import {
  SET_HEALTHS_DATA,
  SET_HEALTH_DATA,
  SET_HEALTHS_METADATA,
  SET_SEARCHED_HEALTHS_DATA,
  RESET_SEARCHED_HEALTHS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setHealthData = (payload) => ({
  type: SET_HEALTH_DATA,
  payload,
});

export const setHealthsData = (payload) => ({
  type: SET_HEALTHS_DATA,
  payload,
});

export const resetSearchedHealthsData = (payload) => ({
  type: RESET_SEARCHED_HEALTHS_DATA,
  payload,
});

export const setSearchedHealthsData = (payload) => ({
  type: SET_SEARCHED_HEALTHS_DATA,
  payload,
});

export const setHealthsMetadata = (payload) => ({
  type: SET_HEALTHS_METADATA,
  payload,
});

export const clearSearchedHealths = () => async (dispatch) => {
  try {
    dispatch(resetSearchedHealthsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const clearSearchedPhotos = () => async (dispatch) => {
  try {
    // dispatch(resetSearchedPhotosData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchHealths =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({ url: url.health, query, columns, order });

      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);
      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedHealthsData(data.results))
            : dispatch(setHealthsData(data.results));
          metadata && dispatch(setHealthsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchHealth =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.health + `${id}/`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setHealthData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
