import React from "react";
import { Link } from "react-router-dom";

const LinkCard = ({
  desc,
  imgPath,
  title,
  description,
  bannerurl,
  content,
}) => {
  return (
    <>
      <svg
        style={{ visibility: "hidden", position: "absolute" }}
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
      <div className="whole__card">
        <div className="link__card">
          <div className="link__card-image">
            {bannerurl && <img src={bannerurl} alt="" />}
          </div>
        </div>
        <p className="text__description">{title}</p>
      </div>
    </>
  );
};

export default LinkCard;
