export const SET_DOCUMENT_DATA = "document/SET_DOCUMENT_DATA";

export const SET_DOCUMENTS_DATA = "document/SET_DOCUMENTS_DATA";

export const SET_DOCUMENTS_METADATA = "document/SET_DOCUMENTS_METADATA";

export const SET_SEARCHED_DOCUMENTS_DATA =
  "document/SET_SEARCHED_DOCUMENTS_DATA";
export const RESET_SEARCHED_DOCUMENTS_DATA =
  "document/RESET_SEARCHED_DOCUMENTS_DATA";
