import {
  SET_ME,
  SYSTEM_LOGOUT,
  SET_IS_LOADING,
  SET_ERRORS,
  SET_IS_PERMISSION_LOADING,
  SET_NOTIFICATION,
  SET_ERROR_NOTIFICATION,
  RESET_NOTIFICATION,
  SET_FOOTER_COLOR,
} from "./action-types";

const initialState = {
  isLoading: false,
  errors: null,
  me: null,
  notification: [],
  isPermissionLoading: false,
  theme: {
    footer: {
      backgroundColor: "#DDB9B9",
    },
  },
};

export default function (state = initialState, action) {
  const { notification } = state;

  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_FOOTER_COLOR:
      return {
        ...state,
        theme: {
          footer: {
            backgroundColor: action.payload,
          },
        },
      };

    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    case SET_IS_PERMISSION_LOADING: {
      return {
        ...state,
        isPermissionLoading: action.payload,
      };
    }
    case SET_ME:
      return {
        ...state,
        me: action.payload,
      };

    case SYSTEM_LOGOUT:
      return {
        ...state,
        me: null,
      };
    case SET_NOTIFICATION: {
      return {
        ...state,
        notification: [...notification, action.payload],
      };
    }
    case SET_ERROR_NOTIFICATION: {
      return {
        ...state,
        notification: [...notification, action.payload],
        isLoading: false,
        isSubmitting: false,
        isSearching: false,
      };
    }

    case RESET_NOTIFICATION: {
      return {
        ...state,
        notification: [],
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
