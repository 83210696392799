import React, { useEffect } from "react";

import { connect } from "react-redux";

import ContactForm from "./ContactForm";

import { fetchWebsiteInformations } from "../store/websiteInformation/actions";
// import { fetchMe } from "../store/app/actions";

import { resetNotification } from "../store/app/actions";

import { createContact } from "../store/contact/actions";

import Notification from "../components/Notification/index";

import MetaTag from "../meta-seo/MetaTag";

const Contact = ({
  createContact,
  fetchWebsiteInformations,
  websiteInformations = [],
  resetNotification,
  notification,
}) => {
  const onCreate = async (values, { resetForm }) => {
    const data = await createContact({ values });
    resetForm();
  };

  useEffect(() => {
    fetchWebsiteInformations({});
  }, []);

  const mail =
    websiteInformations &&
    websiteInformations
      .filter((item) => item.is_active)
      .find((item) => item.title.toLowerCase() === "mail");
  const telephone =
    websiteInformations &&
    websiteInformations
      .filter((item) => item.is_active)
      .find((item) => item.title.toLowerCase() === "telephone");

  const location = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "location";
    }
  });

  return (
    <>
      <MetaTag
        title="Contact Us"
        content="Welcome to the Riddhi Siddhi diagnostic center. Are you concerned about your health? Your health is important to us. So, we're here to help."
        url="https://rsdiagnosticenter.com/contact"
      ></MetaTag>
      <div className="contact__page">
        {/* <Header></Header>{" "} */}
        <div className="contact__container constant__margin-top">
          <div className="contact__header__image">
            <div className="contact__header"></div>
          </div>
          <div className="contact-us">
            <div className="contact-us-left">
              <span className="contact-us-title">
                Have Any Question? We’d Love to hear from you.
              </span>
              <div className="ridhisidhi-dot-com">
                <span className="ridhisidhi-dot-com-title">Mail</span>
                <p className="ridhisidhi-dot-com-para"></p>
              </div>

              <div className="info">
                <i className="fas fa-envelope"></i>{" "}
                <span>{mail && mail.description}</span>
              </div>

              <div className="contact-us-left-address">
                <span className="address-title">ADDRESS</span>
                <p className="address-para">
                  {location && location.description}
                </p>
              </div>

              <div className="phone">
                <span className="phone-title">Phone Number</span>

                <div className="phone-details">
                  <div className="phone-icon">
                    <span>
                      <i className="fas fa-phone-alt"></i>
                    </span>
                  </div>

                  <div className="phone-content">
                    <span className="phone-no">
                      {/* +977 0123 456 789,
                    <br />
                    +977 0123 456 789 */}
                      {telephone && telephone.description}
                    </span>
                  </div>
                </div>
              </div>

              <p className="any-ques">
                Any questions or remarks? Just write us a message!
              </p>
              <p className="fill-form">
                Fill up the form and our team will get back to you within <br />
                24 hours.
              </p>
            </div>

            <div className="contact-us-right">
              <h2>Give your Feedback</h2>
              <ContactForm onCreate={onCreate}></ContactForm>
            </div>
          </div>
        </div>
        {!!(notification && notification.length) && (
          <Notification
            data={notification[0]}
            resetNotification={resetNotification}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  appState: { notification, isLoading },
  websiteInformationState: { websiteInformations },
}) => ({
  websiteInformations,
  notification,
  isLoading,
});

export default connect(mapStateToProps, {
  createContact,
  resetNotification,
  fetchWebsiteInformations,
})(Contact);
