export const SET_HOME_IMAGE_DATA = "homeImage/SET_HOME_IMAGE_DATA";

export const SET_HOME_IMAGES_DATA = "homeImage/SET_HOME_IMAGES_DATA";

export const SET_HOME_IMAGES_METADATA = "homeImage/SET_HOME_IMAGES_METADATA";

export const SET_SEARCHED_HOME_IMAGES_DATA =
  "homeImage/SET_SEARCHED_HOME_IMAGES_DATA";
export const RESET_SEARCHED_HOME_IMAGES_DATA =
  "homeImage/RESET_SEARCHED_HOME_IMAGES_DATA";
