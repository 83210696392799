import React from "react";

const ServiceCard = ({ dummyService, onClick }) => {
  return (
    <>
      <div className="service__cards">
        {dummyService.map((item, i) => {
          return (
            <div className="service__item" key={i} onClick={() => onClick(i)}>
              <div className="service__item-icon">{item.icon}</div>
              <p className="service__item-text heading__secondary">
                {item.name}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ServiceCard;
/* A meta tag for SEO. */
