import React, { useState, useEffect } from "react";

import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";

import { convertToHTML } from "draft-convert";

import { connect } from "react-redux";

import MetaTag from "../../meta-seo/MetaTag";

const CardModel = (props) => {
  const {
    title,
    price,
    description,
    imgPath,
    id,
    bannerurl,
    content,
    is_seperated,
    male_price,
    female_price,
    female_discount,
    male_discount,
  } = props;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    // const about = websiteInformations.find((item) => item.slug === "about");

    if (description) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
      );
    }
  }, [description]);

  useEffect(() => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <>
      <MetaTag
        title={title}
        content={`All the tests in ${title} package`}
      ></MetaTag>
      <div className="updated__description">
        <div className="product__main">
          <div className="product__image">
            {bannerurl && <img src={bannerurl} alt="" />}
          </div>
          {/* info */}
          <div className="product__info">
            <h4 className="product__title">{title}</h4>
            {/* content */}
            <p className="product__content"></p>
          </div>
        </div>
        <div className="test__packages">
          <h2 className="product__heading">Test Name</h2>
          <ul
            className="test"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></ul>
        </div>
        <table className={`pricing ${is_seperated && "desktop__price"}`}>
          <thead>
            <tr>
              <th>Total Price</th>
              {!is_seperated && <th>Discounted Price</th>}
              {!content && is_seperated && (
                <>
                  <th>Male Discounted Price</th>
                  <th>Female Discounted Price</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {content && (
                  <span className={`${content || is_seperated ? "cut" : ""}`}>
                    Rs. {price && price}
                  </span>
                )}
                {!content && is_seperated && (
                  <div className="discounted__section">
                    <p>
                      <dl>Male Price:</dl>
                      <span
                        className={`${content || is_seperated ? "cut" : ""}`}
                      >
                        Rs. {male_price && male_price}
                      </span>{" "}
                    </p>
                    <p>
                      <dl>Female Price:</dl>
                      <span
                        className={`${content || is_seperated ? "cut" : ""}`}
                      >
                        Rs. {female_price && female_price}
                      </span>
                    </p>
                  </div>
                )}
              </td>
              {!is_seperated && <td>Rs. {content ? content : ""}</td>}
              {!content && is_seperated && (
                <>
                  <td>Rs. {male_discount}</td>
                  <td>Rs. {female_discount}</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
        {is_seperated && !content && (
          <div className="mobile__pricing pricing">
            <p className="head">Total Price</p>
            <div>
              {content && (
                <span className={`${content || is_seperated ? "cut" : ""}`}>
                  Rs. {price && price}
                </span>
              )}
              {!content && is_seperated && (
                <div className="discounted__section">
                  <p>
                    <dl>Male Price:</dl>
                    <span className={`${content || is_seperated ? "cut" : ""}`}>
                      Rs. {male_price && male_price}
                    </span>{" "}
                  </p>
                  <p>
                    <dl>Female Price:</dl>
                    <span className={`${content || is_seperated ? "cut" : ""}`}>
                      Rs. {female_price && female_price}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <p className="head">Male Discounted Price</p>
            <p>Rs. {male_discount && male_discount}</p>
            <p className="head">Female Discounted Price</p>
            <p>Rs. {female_discount && female_discount}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CardModel;
