import React from "react";

// import Group from "../../assets/svg/group";
import Man from "../../assets/svg/man";

const Welcome = () => {
  return (
    <div className="constant__padding welcome">
      <h1 className="heading__primary line">
        Welcome To Riddhi Siddhi Diagonist
      </h1>
      <p className="normal__heading constant__margin-top"></p>
      <div className="welcome__cards constant_margin-top">
        <div className="welcome__card welcome__card-1">
          <div className="welcome__image">
            <img
              src={require(`../../assets/images/chairman-png.png`).default}
              alt=""
            />
            {/* <Man></Man> */}
          </div>
          <h2 className="welcome__heading">Message from Chairman</h2>
          <p className="welcome__info">
            We are committed to highest ground of enthusiasm and ethics with the
            touch of world class technology.
          </p>
        </div>
        <div className="welcome__card welcome__card-2">
          <div className="welcome__image">
            <img
              src={require(`../../assets/images/team.jpg`).default}
              alt=""
              style={{ transform: "scale(4.5)" }}
            />
            {/* <Man></Man> */}
          </div>
          <h2 className="welcome__heading">Our Team</h2>
          <p className="welcome__info">
            We are focused on providing reliable and accurate diagnostic
            facility at an affordable price.
          </p>
        </div>
        {/* <div className="welcome__card welcome__card-3">
          <div className="welcome__image">
            <img src={require(`../../assets/images/qM.png`).default} alt="" />
          </div>
          <h2 className="welcome__heading">
            Accreditation & Quality Management
          </h2>
          <p className="welcome__info">
            We are focused on providing reliable and accurate diagnostic
            facility at an affordable price.
          </p>
        </div>
        <div className="welcome__card welcome__card-4">
          <div className="welcome__image">
            <img src={require(`../../assets/images/cup.png`).default} alt="" />
          </div>
          <h2 className="welcome__heading">Awards</h2>
          <p className="welcome__info">
            We are focused on providing reliable and accurate diagnostic
            facility at an affordable price.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Welcome;
