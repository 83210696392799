export const SET_TESTIMONIAL_DATA = "homeImage/SET_TESTIMONIAL_DATA";

export const SET_TESTIMONIALS_DATA = "homeImage/SET_TESTIMONIALS_DATA";

export const SET_TESTIMONIALS_METADATA = "homeImage/SET_TESTIMONIALS_METADATA";

export const SET_SEARCHED_TESTIMONIALS_DATA =
  "homeImage/SET_SEARCHED_TESTIMONIALS_DATA";
export const RESET_SEARCHED_TESTIMONIALS_DATA =
  "homeImage/RESET_SEARCHED_TESTIMONIALS_DATA";
