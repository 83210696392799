import React, { useEffect } from "react";
import { connect } from "react-redux";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

import { fetchWebsiteInformations } from "../store/websiteInformation/actions";
import { setFooterColor } from "../store/app/actions";

const Layout = ({
  fetchWebsiteInformations,
  websiteInformations,
  footer,
  setFooterColor,
}) => {
  useEffect(() => {
    fetchWebsiteInformations({});
  }, []);

  return (
    <>
      <Header websiteInformations={websiteInformations} />
      <Content setFooterColor={setFooterColor} />
      <Footer
        websiteInformations={websiteInformations}
        color={footer.backgroundColor}
      />
    </>
  );
};

const mapStateToProps = ({
  appState: {
    theme: { footer },
  },
  websiteInformationState: { websiteInformations },
}) => ({
  websiteInformations,
  footer,
});

export default connect(mapStateToProps, {
  fetchWebsiteInformations,
  setFooterColor,
})(Layout);
