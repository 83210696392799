import {
  SET_TEAMS_DATA,
  SET_TEAM_DATA,
  SET_TEAMS_METADATA,
  SET_SEARCHED_TEAMS_DATA,
  RESET_SEARCHED_TEAMS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setTeamData = (payload) => ({
  type: SET_TEAM_DATA,
  payload,
});

export const setTeamsData = (payload) => ({
  type: SET_TEAMS_DATA,
  payload,
});

export const resetSearchedTeamsData = (payload) => ({
  type: RESET_SEARCHED_TEAMS_DATA,
  payload,
});

export const setSearchedTeamsData = (payload) => ({
  type: SET_SEARCHED_TEAMS_DATA,
  payload,
});

export const setTeamsMetadata = (payload) => ({
  type: SET_TEAMS_METADATA,
  payload,
});

export const clearSearchedTeams = () => async (dispatch) => {
  try {
    dispatch(resetSearchedTeamsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchTeams =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({
        url: url.team,
        query,
        columns,
        order,
      });

      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);
      // const xrt = await network({ dispatch }).get(link);
      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedTeamsData(data.results))
            : dispatch(setTeamsData(data.results));
          metadata && dispatch(setTeamsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchTeam =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(url.team + `${id}/`);
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setTeamData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
