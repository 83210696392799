import { combineReducers } from "redux";

import appState from "./app/reducers";
import websiteInformationState from "./websiteInformation/reducers";
import homeImageState from "./homeImage/reducers";
import testimonialState from "./testimonials/reducers";
import infographicState from "./infographics/reducers";
import serviceState from "./service/reducers";
import healthState from "./health/reducers";
import announcementState from "./announcement/reducers";
import labState from "./lab/reducers";
import homeGalleryState from "./homeGallery/reducers";
import galleryListState from "./galleryList/reducers";
import documentState from "./document/reducers";
import teamState from "./team/reducers";
import labReportState from "./labReport/reducers";
import navigationState from "./navigation/reducers";

const rootReducer = combineReducers({
  appState,
  websiteInformationState,
  homeImageState,
  testimonialState,
  serviceState,
  healthState,
  announcementState,
  labState,
  homeGalleryState,
  galleryListState,
  documentState,
  teamState,
  labReportState,
  navigationState,
  infographicState,
});

export default rootReducer;
