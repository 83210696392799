// import { createState, removeState, updateState } from "store/utils";
import {
  SET_HOME_IMAGES_DATA,
  SET_HOME_IMAGE_DATA,
  SET_HOME_IMAGES_METADATA,
  SET_SEARCHED_HOME_IMAGES_DATA,
  RESET_SEARCHED_HOME_IMAGES_DATA,
} from "./action-types";

export const initialState = {
  homeImage: undefined,
  homeImages: [],
  searchedHomeImages: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_IMAGE_DATA:
      return { ...state, homeImage: action.payload };
    case SET_HOME_IMAGES_DATA:
      return { ...state, homeImages: action.payload };
    case RESET_SEARCHED_HOME_IMAGES_DATA:
      return { ...state, searchedHomeImages: undefined };
    case SET_SEARCHED_HOME_IMAGES_DATA:
      return { ...state, searchedHomeImages: action.payload };
    case SET_HOME_IMAGES_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
