import React from "react";

const Heart = () => {
  return (
    <svg
      width="90"
      height="77"
      viewBox="0 0 90 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.72 11.56C47.55 9.16004 49.92 7.00004 52.49 5.00004C61.49 -1.81996 71.92 -1.61996 80.54 5.43004C88.69 12.09 91.74 23.79 87.71 32.92C84.61 39.92 67.09 41.35 62.33 34.92C60.47 32.41 58.84 29.74 56.04 25.54L44.56 43.82C41.69 37.82 39.44 32.69 36.7 27.82C35.54 25.76 33.35 24.27 31.63 22.53C29.99 24.34 27.63 25.9 26.82 28.03C24.37 34.41 22 39.12 13.25 38.92C4.17997 38.71 0.929974 34.87 0.149974 26.87C-0.870026 16.38 3.33997 7.90004 12.21 3.00004C21.08 -1.89996 29.67 -0.799959 38.52 6.50004C40.48 8.12004 42.46 9.72004 44.72 11.56Z"
        fill="black"
      />
      <path
        d="M54.69 42.29L75.8 47.81C66.15 57.54 57 67 47.42 76.18C46.42 77.18 42.65 77.1 41.57 76.06C32.27 67.06 23.28 57.77 14.91 49.27L33.31 38.27C36.12 43.6 39.68 50.35 44.41 59.34C48.9 51.92 52.14 46.54 54.69 42.29Z"
        fill="black"
      />
    </svg>
  );
};

export default Heart;
