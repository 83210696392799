import { createState, removeState, updateState } from "../../store/utils";
import {
  SET_HEALTHS_DATA,
  SET_HEALTH_DATA,
  SET_HEALTHS_METADATA,
  SET_SEARCHED_HEALTHS_DATA,
  RESET_SEARCHED_HEALTHS_DATA,
} from "./action-types";

export const initialState = {
  health: undefined,
  healths: [],
  searchedHealths: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HEALTH_DATA:
      return { ...state, health: action.payload };
    case SET_HEALTHS_DATA:
      return { ...state, healths: action.payload };
    case RESET_SEARCHED_HEALTHS_DATA:
      return { ...state, searchedHealths: undefined };
    case SET_SEARCHED_HEALTHS_DATA:
      return { ...state, searchedHealths: action.payload };
    case SET_HEALTHS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
