export const SET_SERVICE_DATA = "homeImage/SET_SERVICE_DATA";

export const SET_SERVICES_DATA = "homeImage/SET_SERVICES_DATA";

export const SET_SERVICES_METADATA = "homeImage/SET_SERVICES_METADATA";

export const SET_SEARCHED_SERVICES_DATA =
  "homeImage/SET_SEARCHED_SERVICES_DATA";
export const RESET_SEARCHED_SERVICES_DATA =
  "homeImage/RESET_SEARCHED_SERVICES_DATA";
