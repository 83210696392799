import Contact from "./Page/Contact";
import Gallery from "./Page/Gallery";
import TabPage from "./Page/TabPage";
import HomePage from "./Page/Homepage/Index";
import Team from "./Page/Team";
import Service from "./Page/ServicePage/Servicepage";
import About from "./Page/About";
import DetailPage from "./Page/DetailPage";
import LabServiceDetail from "./Page/LabServiceDetail";
import Appointment from "./Page/Appointment";
import ErrorPage from "./components/400Errors/index";
// import Report from "./Page/Report/UserLabReport";

export const routes = [
  {
    title: "Home",
    setNavigationTitle: true,
    path: "/",
    exact: true,
    content: "",
    component: HomePage,

    theme: {
      footer: {
        backgroundColor: "#fff",
        // backgroundColor: "#ddb9b9",
      },
    },
  },
  {
    title: "Contact",
    setNavigationTitle: true,
    path: "/contact",
    exact: true,
    content: "",
    component: Contact,
    theme: {
      footer: {
        backgroundColor: "#fff",
      },
    },
  },
  {
    title: "Gallery",
    setNavigationTitle: true,
    path: "/gallery",
    exact: true,
    content: "",
    component: Gallery,
    theme: {
      footer: {
        backgroundColor: "#fff",
      },
    },
  },
  {
    title: "team",
    setNavigationTitle: true,
    path: "/team",
    exact: true,
    content: "",
    component: Team,
    theme: {
      footer: {
        backgroundColor: "#fff",
      },
    },
  },
  {
    title: "tab-page",
    setNavigationTitle: true,
    path: "/service/:active",
    exact: true,
    content: "",
    component: TabPage,
    theme: {
      footer: {
        backgroundColor: "#34386E",
      },
    },
  },
  {
    title: "detail",
    setNavigationTitle: true,
    path: "/details-page",
    exact: true,
    content: "",
    component: DetailPage,
    theme: {
      footer: {
        backgroundColor: "#34386E",
      },
    },
  },
  {
    title: "Lab Services",
    setNavigationTitle: true,
    path: "/lab-service-detail",
    exact: true,
    content: "",
    component: LabServiceDetail,
    theme: {
      footer: {
        backgroundColor: "#bad2f1",
      },
    },
  },
  {
    title: "detail",
    setNavigationTitle: true,
    path: "/health-packages/:id",
    exact: true,
    content: "",
    component: DetailPage,
    theme: {
      footer: {
        backgroundColor: "#34386E",
      },
    },
  },
  {
    title: "tabbed-page",
    setNavigationTitle: true,
    path: "/tabbed-page",
    exact: true,
    content: "",
    component: TabPage,
    theme: {
      footer: {
        backgroundColor: "#34386E",
      },
    },
  },
  {
    title: "About Us",
    setNavigationTitle: true,
    path: "/about",
    exact: true,
    content: "",
    component: About,
    theme: {
      footer: {
        backgroundColor: "#BAD2F1",
      },
    },
  },
  {
    title: "Our Services",
    setNavigationTitle: true,
    path: "/service",
    exact: true,
    content: "",
    component: Service,
    theme: {
      footer: {
        backgroundColor: "#BAD2F1",
      },
    },
  },
  {
    title: "Clinical Research",
    setNavigationTitle: true,
    path: "/clinical-research",
    exact: true,
    content: "",
    component: Document,
    theme: {
      footer: {
        backgroundColor: "#96c2bb",
      },
    },
  },
  {
    title: "Appointment",
    setNavigationTitle: true,
    path: "/appointment",
    exact: true,
    content: "",
    component: Appointment,
    theme: {
      footer: {
        backgroundColor: "#fff",
      },
    },
  },
  // {
  {
    title: "Page Not Found",
    setNavigationTitle: true,
    path: "",
    exact: true,
    content: "",
    component: ErrorPage,
    theme: {
      footer: {
        backgroundColor: "#fff",
      },
    },
  },
  // {
  //   title: "Report",
  //   setNavigationTitle: true,
  //   path: "/report",
  //   exact: true,
  //   component: Report,
  //   theme: {
  //     footer: {
  //       backgroundColor: "#fff",
  //     },
  //   },
  // },
];
