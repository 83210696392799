import React, { useEffect } from "react";
import { connect } from "react-redux";
import { appointmentUser, resetNotification } from "../../store/app/actions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import moment from "moment";

import { fetchWebsiteInformations } from "../../store/websiteInformation/actions";

import Notification from "../../components/Notification/index";

const AppointmentForm = ({
  appointmentUser,
  resetNotification,
  notification,
  isLoading,
  websiteInformations = [],
  fetchWebsiteInformations,
}) => {
  useEffect(() => {
    fetchWebsiteInformations({});
  }, []);

  const sunToFri =
    websiteInformations &&
    websiteInformations.find(
      (item) => item.title.toLowerCase() === "sunday to friday"
    );

  const sat =
    websiteInformations &&
    websiteInformations.find((item) => item.title.toLowerCase() === "saturday");

  let data = [];

  if (sunToFri && sat) {
    data = [
      {
        id: 1,
        name: sunToFri.description,
        weekDay: true,
      },
      {
        id: 2,
        name: sat.description,
        weekDay: false,
      },
    ];
  }

  const initialValues = {
    patient_name: "",
    purpose: "",
    date: "",
    time: "",
    remarks: "",
    today: moment(new Date()).subtract(1, "day"),
    newDay: "",
  };

  // const date = new Date();
  const validationSchema = Yup.object({
    patient_name: Yup.string().required().label("Patient Name"),
    purpose: Yup.string().required().label("purpose"),
    date: Yup.date("Expiration Date")
      .nullable()
      .min(
        Yup.ref("today"),
        ({ min }) => "Appointment Date can not be in past date"
      ),

    time: Yup.object().required().label("time"),
    remarks: Yup.string().required().label("remarks"),
  });

  const onSubmit = async (values, { resetForm }) => {
    await appointmentUser({ values: { ...values, time: values.time.name } });
  };

  return (
    <>
      <div className="red__border appointment__form">
        <div className="form__container register__container">
          <div className="image__content constant__padding">
            <div className="image">
              <img
                src={require(`../../assets/images/logo.png`).default}
                alt=""
              />
            </div>
            <div className="welcome__text heading__primary">
              <h3 className="heading__primary">Ridhi Sidhi</h3>
              <p className="heading__secondary">DIAGNOSTIC & RESEARCH CENTER</p>
              <p className="normal__text">
                We are Nepal’s first certified clinical laboratory and are
                committed to provide Quality, Reliable and Affordable Diagnostic
                Service
              </p>
            </div>
            <div className="outline__image">
              <img
                src={require(`../../assets/images/SeekPng 2.png`).default}
                alt=""
              />
            </div>
          </div>
          <div className="form__content-sign">
            <h2 className="heading__primary">Appointment Page</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount={false}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({ values, errors, handleChange, setFieldValue }) => {
                return (
                  <Form className="form">
                    <div className="control-group">
                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="patient">Patient Name</label>
                        </div>
                        <input
                          type="text"
                          id="patient"
                          name="patient_name"
                          value={values.patient_name}
                          onChange={handleChange}
                        />
                        {errors && errors.patient_name && (
                          <>{errors.patient_name}</>
                        )}
                      </div>
                      {/* password */}
                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="purpose">Purpose Of Visit</label>
                        </div>
                        <input
                          type="text"
                          id="purpose"
                          name="purpose"
                          value={values.purpose}
                          onChange={handleChange}
                        />
                        {errors && errors.purpose && <>{errors.purpose}</>}
                      </div>
                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="date">Date</label>
                        </div>
                        <input
                          type="date"
                          id="date"
                          name="date"
                          value={values.date}
                          onChange={(e) => {
                            if (new Date(e.target.value).getDay() === 6) {
                              setFieldValue("time", {
                                id: 2,
                                name: sat.description,
                                weekDay: false,
                              });
                            } else {
                              setFieldValue("time", {
                                id: 1,
                                name: sunToFri.description,
                                weekDay: true,
                              });
                            }
                            setFieldValue("date", e.target.value);
                          }}
                        />
                        {errors && errors.date && <>{errors.date}</>}
                      </div>
                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="name">Time</label>
                        </div>
                        <select
                          name="time"
                          id=""
                          value={values.time}
                          onChange={(e) => {
                            return setFieldValue("time", e.target.value);
                          }}
                        >
                          {data
                            .filter((item) => {
                              if (new Date(values.date).getDay() === 6) {
                                return item.weekDay === false;
                              }
                              return item.weekDay === true;
                            })
                            .map((item, i) => {
                              return (
                                <option value={item.name} name="time" key={i}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                        {errors && errors.time && <>{errors.time}</>}
                      </div>

                      <div className="form--control">
                        <div className="form__group">
                          <label htmlFor="name">Remarks</label>
                        </div>
                        <input
                          type="text"
                          id="name"
                          name="remarks"
                          value={values.remarks}
                          onChange={handleChange}
                        />
                        {errors && errors.remarks && <>{errors.remarks}</>}
                      </div>
                    </div>

                    <div className="form-actions">
                      <button className="btn btn--transparent" type="submit">
                        Submit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>{" "}
      {!!(notification && notification.length) && (
        <Notification
          data={notification[0]}
          resetNotification={resetNotification}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  appState: { notification, isLoading },
  websiteInformationState: { websiteInformations },
}) => ({
  notification,
  isLoading,
  websiteInformations,
});
export default connect(mapStateToProps, {
  appointmentUser,
  resetNotification,
  fetchWebsiteInformations,
})(AppointmentForm);
