import React from "react";

const SliderItem = ({
  description,
  bannerurl,
  title,
  designation,
  differentSection,
}) => {
  return (
    <>
      {!differentSection && (
        <div className="drop-shadow">
          <div className="slider__items">
            <div className="slider__profile">
              <div className={`${"slider__profile-img"}`}>
                {bannerurl && <img src={bannerurl} alt="" />}
              </div>
            </div>

            <div className="quote top__block-quote">
              <svg
                viewBox="0 0 196 195"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 0C3.58172 0 0 3.58173 0 8.00001V84.0606C0 84.9288 0.13829 85.7646 0.394043 86.5474V133.279C0.394043 159.785 18.6655 182.793 44.4828 188.797L69.6569 194.651L72.8281 181.015L47.654 175.161C28.1778 170.632 14.394 153.274 14.394 133.279V92.0606H81.0909C85.5092 92.0606 89.0909 88.4789 89.0909 84.0606V8C89.0909 3.58172 85.5092 0 81.0909 0H8Z"
                  fill="#34C6F3"
                  fill-opacity="0.4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M114.909 0C110.491 0 106.909 3.58173 106.909 8.00001V84.0606C106.909 84.9285 107.047 85.7641 107.303 86.5466V133.279C107.303 159.785 125.574 182.793 151.392 188.797L176.566 194.651L179.737 181.015L154.563 175.161C135.087 170.632 121.303 153.274 121.303 133.279V92.0606H188C192.418 92.0606 196 88.4789 196 84.0606V8C196 3.58172 192.418 0 188 0H114.909Z"
                  fill="#34C6F3"
                  fill-opacity="0.4"
                />
              </svg>
            </div>

            <p>{description}</p>

            <div className="quote bottom__block-quote">
              <svg
                viewBox="0 0 196 195"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M188 195C192.418 195 196 191.418 196 187V110.939C196 110.071 195.862 109.235 195.606 108.453V61.7215C195.606 35.2153 177.334 12.2071 151.517 6.20303L126.343 0.348587L123.172 13.9847L148.346 19.8391C167.822 24.3685 181.606 41.7256 181.606 61.7215V102.939H114.909C110.491 102.939 106.909 106.521 106.909 110.939V187C106.909 191.418 110.491 195 114.909 195H188Z"
                  fill="#34C6F3"
                  fill-opacity="0.4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M81.0909 195C85.5092 195 89.0909 191.418 89.0909 187V110.939C89.0909 110.072 88.9527 109.236 88.6971 108.453V61.7215C88.6971 35.2153 70.4256 12.2071 44.6084 6.20303L19.4342 0.348587L16.263 13.9847L41.4372 19.8391C60.9134 24.3685 74.6971 41.7256 74.6971 61.7215V102.939H8C3.58173 102.939 0 106.521 0 110.939V187C0 191.418 3.58173 195 8.00001 195H81.0909Z"
                  fill="#34C6F3"
                  fill-opacity="0.4"
                />
              </svg>
            </div>
            {/* image container */}
            <div className="slider__profile">
              <div className="info">
                <p className="profile__name">{title}</p>
                <span>{designation}</span>
              </div>
            </div>

            {/* text */}
          </div>
        </div>
      )}
      {differentSection && (
        <div className="another__slider">
          <div className="another__slider-image">
            <img src={bannerurl && bannerurl} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default SliderItem;
