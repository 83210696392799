import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

// import Navbar from "../../components/Navbar/";
import Tabs from "../../components/Tabs/Index";
import HealthCard from "../../components/Card/HealthCard";
import LinkCard from "../../components/LinkCard/LinkCard";
import Terms from "../../components/Terms/";

// import { fetchMe } from "../../store/app/actions";
import { fetchServices } from "../../store/service/actions";
import { fetchHealths } from "../../store/health/actions";
import { fetchLabs } from "../../store/lab/actions";

import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";

import { convertToHTML } from "draft-convert";

import MetaTag from "../../meta-seo/MetaTag";

const tabs = [
  {
    id: 1,
    name: "Covid 19",
    title: "Covid 19",
    imgPath: "covid",
    text: "COVID-19 testing involves analyzing samples to assess the current or past presence of SARS-CoV-2. The two main branches detect either the presence of the virus or of antibodies produced in response to infection. Molecular tests for viral presence through its molecular components are used to diagnose individual cases and to allow public health authorities to trace and contain outbreak",
  },
  {
    id: 2,
    name: "Swin Flu Testing",
    title: "Swin Flu Testing",
    imgPath: "swinflue 2",
    text: "Nepal’s 1 st and only private Laboratory for swine flu PCR testing which is authorize by Ministry of Health and Population.Reports of the swine flu will be provided on same day.",
  },
  {
    id: 3,
    name: "Hemetelogy",
    title: "Hemetelogy",
    imgPath: "image 10",
    text: "Gangway boom coffer rigging tackle gabion Shiver me timbers aye draft barque. Scourge of the seven seas pressgang Blimey galleon six pounders yawl grapple jolly boat lugger Jack Ketch. Splice the main brace boatswain snow Chain Shot salmagundi keel crow's nest Shiver me timbers jib ye.",
  },
  {
    id: 4,
    name: "Blood Chemestry",
    title: "Blood Chemestry",
    imgPath: "image 11",
    text: "Blood chemistry tests are blood tests that measure amounts of certain chemicals in a sample of blood. Blood chemistry tests give important information about how well a person’s kidneys, liver, and other organs are working.",
  },
  {
    id: 5,
    name: "Drug Scanning",
    title: "Drug Scanning",
    imgPath: "image 12",
    text: "Drug screening is the process by which potential drugs are identified and optimized before selection of a candidate drug to progress to clinical trials. It can involve screening large libraries of chemicals for a particular biological activity in high-throughput screening assays.",
  },
];

const cardData = [
  {
    id: 1,
    title: "Basic Health",
    description: "testasdfasdf",
    price: "450",
    imgPath: "try",
  },
  {
    id: 2,
    title: "Basic Health",
    description: "testasdfasdf",
    price: "850",
    imgPath: "try",
  },
  {
    id: 3,
    title: "Basic Health",
    description: "testasdfasdf",
    price: "950",
    imgPath: "try",
  },
];

const GalleryDetails = [
  { id: 1, desc: "Bio Chemestry", imgPath: "try" },
  { id: 2, desc: "Hemetology", imgPath: "try" },
  { id: 3, desc: "Cytology", imgPath: "try" },
  { id: 3, desc: "Histopathology", imgPath: "try" },
  { id: 3, desc: "Microbiology", imgPath: "try" },
];

const DataCard = ({ index, lab }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [show, setShow] = useState(false);

  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    if (lab && lab.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(lab.description))
        )
      );
    }
  }, [lab]);

  useEffect(() => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  let labServiceContent = "";

  // function splitText(content) {
  //   // return
  //   for (let i = 0; i <= 9; i++) {
  //     (content.__html.querySelectorAll());
  //     // labServiceContent.push()
  //   }
  // }

  // splitText(createMarkup(convertedContent));

  return (
    <div className="lab__card-new">
      <div className="lab__card-description" onClick={() => setShow(!show)}>
        <p className="plus" onClick={() => setShow(!show)}>
          {" "}
          {show ? "➖" : "➕"}
        </p>
        <h1 className="title">{lab.title}</h1>
      </div>
      {/* { show && <p className="minus"  dangerouslySetInnerHTML={createMarkup(convertedContent)}> </p>} */}
      {/* {show && <p className="minus">{lab.description}</p>} */}
      {/* <ul>

      </ul> */}
      {show && (
        <ul
          className="minus"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></ul>
      )}
    </div>
  );
};

const Servicepage = ({
  fetchServices,
  fetchHealths,
  fetchLabs,
  healths = [],
  labs = [],
  services = [],
}) => {
  useEffect(() => {
    fetchLabs({});
    fetchServices({});
    fetchHealths({});
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const arr = [1, 2, 3, 4, 5, 6];

  return (
    <>
      <MetaTag
        title="Service"
        content="Service provided by Riddhi Siddhi diagnostic center"
        url="https://rsdiagnosticenter.com/service"
      ></MetaTag>
      <div className="servicepage">
        <div className="health__package-info constant__padding">
          <h3 className="heading__primary line primary__color">
            Health Packages
          </h3>
          <div className="wrap">
            <Link to="/tabbed-page?active=1" className="view-all">
              View All
            </Link>
          </div>
          <ul className="constant__margin-top cardTypeList arrange__cards">
            {healths.slice(0, 4).map((card, i) => (
              <HealthCard {...card} key={i}></HealthCard>
            ))}
          </ul>
        </div>
        {/*  */}
        <div className="lab__services constant__padding">
          <h3 className="heading__primary line primary__color">Lab Services</h3>
          <div className="wrap">
            <span
              to="/lab-service-detail"
              className="view-all"
              onClick={() => {
                history.push({
                  pathname: "/lab-service-detail",
                  state: { detail: labs },
                });
              }}
            >
              View All
            </span>
          </div>
          <div className="row">
            <div>
              {labs.slice(labs.length / 2, labs.length).map((lab, i) => {
                return <DataCard key={i} lab={lab}></DataCard>;
              })}
            </div>
            <div>
              {labs.slice(0, labs.length / 2).map((lab, i) => {
                return <DataCard key={i} lab={lab}></DataCard>;
              })}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="home__collection constant__padding primary__color">
          <h3 className="heading__primary line">Home Collection</h3>
          <Terms></Terms>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  appState: { me },
  serviceState: { services },
  healthState: { healths },
  labState: { labs },
}) => ({
  me,
  services,
  healths,
  labs,
});

export default connect(mapStateToProps, {
  fetchServices,
  fetchHealths,
  fetchLabs,
})(Servicepage);
