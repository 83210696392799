// import { createState, removeState, updateState } from "store/utils";
import {
  SET_LAB_REPORTS_DATA,
  SET_LAB_REPORT_DATA,
  SET_LAB_REPORTS_METADATA,
  SET_SEARCHED_LAB_REPORTS_DATA,
  RESET_SEARCHED_LAB_REPORTS_DATA,
} from "./action-types";

export const initialState = {
  labReport: undefined,
  labReports: [],
  searchedLabReports: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAB_REPORT_DATA:
      return { ...state, labReport: action.payload };
    case SET_LAB_REPORTS_DATA:
      return { ...state, labReports: action.payload };
    case RESET_SEARCHED_LAB_REPORTS_DATA:
      return { ...state, searchedLabReports: undefined };
    case SET_SEARCHED_LAB_REPORTS_DATA:
      return { ...state, searchedLabReports: action.payload };
    case SET_LAB_REPORTS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
