import { createState, removeState, updateState } from "../../store/utils";
import {
  SET_SERVICES_DATA,
  SET_SERVICE_DATA,
  SET_SERVICES_METADATA,
  SET_SEARCHED_SERVICES_DATA,
  RESET_SEARCHED_SERVICES_DATA,
} from "./action-types";

export const initialState = {
  service: undefined,
  services: [],
  searchedServices: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICE_DATA:
      return { ...state, service: action.payload };
    case SET_SERVICES_DATA:
      return { ...state, services: action.payload };
    case RESET_SEARCHED_SERVICES_DATA:
      return { ...state, searchedServices: undefined };
    case SET_SEARCHED_SERVICES_DATA:
      return { ...state, searchedServices: action.payload };
    case SET_SERVICES_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
