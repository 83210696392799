const dotenv = require("dotenv");
dotenv.config();

const ROOT = process.cwd();

const processEnvironment = process.env.NODE_ENV;

const {
  NODE_ENV = "development",

  API_PORT,
  API_HOST,
  REACT_APP_API_URL,
  API_PREFIX,

  APP_PORT,
  APP_HOST,
  APP_URL,
  APP_NAME,
  APP_DESCRIPTION,
  APP_MAIN_URL,
} = process.env;

const isProduction = processEnvironment === "production";
const isStaging = processEnvironment === "staging";
const isTesting = processEnvironment === "testing";
const isDevelopment = processEnvironment === "development";

export const configuration = {
  root: ROOT,
  env: {
    name: NODE_ENV || "",
    isDevelopment: isDevelopment,
    isStaging: isStaging,
    isTesting: isTesting,
    isProduction: isProduction,
  },
  api: {
    port: Number(API_PORT),
    host: API_HOST,
    preFix: API_PREFIX,
    url: REACT_APP_API_URL,
  },
  app: {
    port: Number(APP_PORT),
    host: APP_HOST,
    url: APP_URL,
    name: APP_NAME,
    description: APP_DESCRIPTION,
    mainUrl: APP_MAIN_URL,
  },
};
