// import { createState, removeState, updateState } from "store/utils";
import {
  SET_DOCUMENTS_DATA,
  SET_DOCUMENT_DATA,
  SET_DOCUMENTS_METADATA,
  SET_SEARCHED_DOCUMENTS_DATA,
  RESET_SEARCHED_DOCUMENTS_DATA,
} from "./action-types";

export const initialState = {
  document: undefined,
  documents: undefined,
  searchedDocuments: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT_DATA:
      return { ...state, document: action.payload };
    case SET_DOCUMENTS_DATA:
      return { ...state, documents: action.payload };
    case RESET_SEARCHED_DOCUMENTS_DATA:
      return { ...state, searchedDocuments: undefined };
    case SET_SEARCHED_DOCUMENTS_DATA:
      return { ...state, searchedDocuments: action.payload };
    case SET_DOCUMENTS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
