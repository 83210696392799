import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import HealthCard from "../components/Card/HealthCard";
import ServiceCard from "../components/ServiceCard/ServiceCard";

import Heart from "../assets/svg/Heart";
import HomeCar from "../assets/svg/HomeCar";
import Term from "../components/Terms/";
import { useLocation } from "react-router-dom";

import { fetchMe } from "../store/app/actions";

import { fetchHealths } from "../store/health/actions";
import { fetchLabs } from "../store/lab/actions";

import MetaTag from "../meta-seo/MetaTag";

const dummyService = [
  { id: 1, name: "Home Collection", icon: <HomeCar></HomeCar> },
  { id: 2, name: "Health Packages", icon: <Heart></Heart> },
  // { id: 3, name: "New Test", icon: <Heart></Heart> },
  // { id: 4, name: "T Collection", icon: <Heart></Heart> },
];

const HomeCollection = () => {
  return (
    <div className="health__container">
      <Term></Term>
    </div>
  );
};

const HealthPackage = ({ healths = [] }) => {
  return (
    <div className="health__container">
      <h3 className="heading__primary line">Health Packages</h3>
      <div className="key__points">
        <h3 className="heading__primary">Ways to prevent diseases </h3>
        <ol className="suggestion__list">
          <li>
            Be a non-smoker and avoid second hand smoke. If you smoke, get help
            to quit.
          </li>
          <li>Be physically active everyday.</li>
          <li>Eat healthy foods.</li>
          <li>Achieve a healthy weight.</li>
          <li>Control your blood pressure.</li>
          <li>Limit your intake of alcohol.</li>
          <li>Reduce your stress.</li>
          <li>Be screened or tested regularly.</li>
        </ol>
        <div className="outline">
          <p>
            RSD offers you the best health package at best price best price with
            free report interpretation by the doctor.
          </p>
        </div>
      </div>
      <ul className="constant__margin-top cardTypeList">
        {healths
          .filter((item) => item.is_active)
          .map((card, i) => (
            <HealthCard {...card} key={i}></HealthCard>
          ))}
      </ul>
    </div>
  );
};

const NewTest = () => {
  return (
    <div className="health__container">
      <h3 className="heading__primary line">New Test</h3>
      <div className="test__data">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo cum enim,
        aliquam repellat assumenda omnis modi ut error voluptatum fugiat
        quibusdam! Sapiente, ratione praesentium at deserunt eveniet,
        exercitationem doloribus illo delectus quos suscipit necessitatibus
        atque quae temporibus! Quaerat reprehenderit mollitia aperiam! Error,
        labore excepturi! Expedita nemo, in eligendi, impedit nisi alias beatae,
        aliquam eveniet aliquid dicta voluptatem cumque dolor excepturi itaque
        voluptates saepe non aspernatur ipsa dolorum nobis? Illum incidunt
        commodi esse cum minus quas officia quaerat vel in vitae?
      </div>
    </div>
  );
};

const TestPackage = () => {
  return (
    <div className="health__container">
      <h3 className="heading__primary line">Test Packages</h3>
    </div>
  );
};

const TabPage = (props) => {
  const { fetchHealths, healths = [] } = props;

  const [active, setActive] = useState(0);

  const location = useLocation();

  useEffect(() => {
    fetchHealths({});
    // props.healths = [];
  }, []);

  useEffect(() => {
    const active = new URLSearchParams(location.search).get("active");
    active && setActive(active);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const componentDirectory = {
    0: <HomeCollection />,
    1: <HealthPackage healths={props.healths} />,
    // 2: <NewTest />,
    // 3: <TestPackage />,
  };

  return (
    <>
      <MetaTag
        title={active == 0 ? "Home Collection" : "Health Package"}
        content={
          active === 0
            ? "More information about Riddi Siddhi diagnostic center"
            : "All the health packages present in Riddhi Siddhi diagnostic center"
        }
      ></MetaTag>
      <div className="healthpage constant__margin-top tabbed__page-cards">
        <div className="health__package-info constant__padding">
          {componentDirectory[active]}
          <ServiceCard
            dummyService={dummyService}
            onClick={(i) => setActive(i)}
          ></ServiceCard>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  appState: { me },
  healthState: { healths },
  labState: { labs },
}) => ({
  me,
  healths,
  labs,
});

export default connect(mapStateToProps, {
  fetchMe,
  fetchHealths,
  fetchLabs,
})(TabPage);
