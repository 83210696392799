export const SET_NAVIGATION_DATA = 'navigation/SET_NAVIGATION_DATA';

export const SET_NAVIGATIONS_DATA = 'navigation/SET_NAVIGATIONS_DATA';

export const SET_NAVIGATIONS_METADATA = 'navigation/SET_NAVIGATIONS_METADATA';

export const SET_SEARCHED_NAVIGATIONS_DATA =
  'navigation/SET_SEARCHED_NAVIGATIONS_DATA';
export const RESET_SEARCHED_NAVIGATIONS_DATA =
  'navigation/RESET_SEARCHED_NAVIGATIONS_DATA';

export const CREATE_NAVIGATION_DATA = 'navigation/CREATE_NAVIGATION_DATA';

export const UPDATE_NAVIGATION_DATA = 'navigation/UPDATE_NAVIGATION_DATA';

export const REMOVE_NAVIGATION_DATA = 'navigation/REMOVE_NAVIGATION_DATA';
