export const SET_LAB_REPORT_DATA = "labReport/SET_LAB_REPORT_DATA";

export const SET_LAB_REPORTS_DATA = "labReport/SET_LAB_REPORTS_DATA";

export const SET_LAB_REPORTS_METADATA = "labReport/SET_LAB_REPORTS_METADATA";

export const SET_SEARCHED_LAB_REPORTS_DATA =
  "labReport/SET_SEARCHED_LAB_REPORTS_DATA";
export const RESET_SEARCHED_LAB_REPORTS_DATA =
  "labReport/RESET_SEARCHED_LAB_REPORTS_DATA";
