import { Form, Formik } from "formik";
import * as Yup from "yup";

import FormikBase from "../containers/FormikBase/index";

const contactForm = ({ formData, onCreate, onEdit, innerRef }) => {
  const initialValues = (formData && {
    title: "",
    subject: "",
    contact: "",
    is_active: "",
    email: "",
    message: "",
  }) || {
    title: "",
    subject: "",
    contact: "",
    is_active: true,
    email: "",
    message: "",
    // group: {}
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = {
    title: Yup.string().required().label("Title"),
    subject: Yup.string().required().label("Subject"),
    email: Yup.string().required().label("Email"),
    message: Yup.string().required().label("Message"),
    contact: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  };

  return (
    <FormikBase
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(props, otherProps) => {
        const values = { ...props };

        formData ? onEdit(values, otherProps) : onCreate(values, otherProps);
      }}
      innerRef={innerRef}
      renderForm={({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form className="contact__us-form">
            <div className="group__elements">
              <div className="form__group">
                <input
                  onChange={handleChange}
                  value={values.title}
                  placeholder="Name"
                  type="text"
                  name="title"
                  label="Full Name"
                />
                {errors && errors.title && <>{errors.title}</>}
              </div>
              <div className="form__group">
                <input
                  onChange={handleChange}
                  value={values.email}
                  type="email"
                  placeholder="Email"
                  name="email"
                  id=""
                  label="email"
                />
                {errors && errors.email && <>{errors.email}</>}
              </div>
            </div>

            <div className="group__elements">
              <div className="form__group">
                <input
                  onChange={handleChange}
                  value={values.subject}
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  id=""
                  label="Subject"
                />
                {errors && errors.subject && <>{errors.subject}</>}
              </div>
              <div className="form__group">
                <input
                  onChange={handleChange}
                  value={values.contact}
                  type="number"
                  placeholder="Contact Number"
                  name="contact"
                  id=""
                  label="Contact"
                />
                {errors && errors.contact && <>{errors.contact}</>}
              </div>
            </div>

            <div className="form__group">
              <textarea
                placeholder="Message"
                onChange={handleChange}
                value={values.message}
                name="message"
                id=""
                cols="30"
                rows="10"
                label="message"
              ></textarea>
              {errors && errors.message && <>{errors.message}</>}
            </div>
            <div className="form__group">
              <button type="submit">Submit</button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default contactForm;
