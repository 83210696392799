export const SET_HOMEGALLERY_DATA = 'homeImage/SET_HOMEGALLERY_DATA';

export const SET_HOMEGALLERYS_DATA = 'homeImage/SET_HOMEGALLERYS_DATA';

export const SET_HOMEGALLERYS_METADATA = 'homeImage/SET_HOMEGALLERYS_METADATA';

export const SET_SEARCHED_HOMEGALLERYS_DATA =
  'homeImage/SET_SEARCHED_HOMEGALLERYS_DATA';
export const RESET_SEARCHED_HOMEGALLERYS_DATA =
  'homeImage/RESET_SEARCHED_HOMEGALLERYS_DATA';

export const CREATE_HOMEGALLERY_DATA = 'homeImage/CREATE_HOMEGALLERY_DATA';

export const UPDATE_HOMEGALLERY_DATA = 'homeImage/UPDATE_HOMEGALLERY_DATA';

export const REMOVE_HOMEGALLERY_DATA = 'homeImage/REMOVE_HOMEGALLERY_DATA';
