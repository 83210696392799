import React from "react";
import LinkCard from "../../components/LinkCard/LinkCard";
import { Link } from "react-router-dom";

const GalleryDetails = [
  { id: 1, desc: "Video Gallery", imgPath: "try" },
  { id: 2, desc: "Community Gallery", imgPath: "try" },
  { id: 3, desc: "Corporate Gallery", imgPath: "try" },
];

const Gallery = ({ homeGallerys = [] }) => {
  return (
    <>
      <div className="constant__padding gallery">
        <div className="heading__primary line primary__color">Gallery</div>
        <div className="gallery__container">
          {homeGallerys.map((item, i) => (
            <Link to="/gallery">
              <LinkCard {...item} key={i}></LinkCard>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
