// import { createState, removeState, updateState } from "store/utils";
import {
  SET_NAVIGATIONS_DATA,
  SET_NAVIGATION_DATA,
  SET_NAVIGATIONS_METADATA,
  SET_SEARCHED_NAVIGATIONS_DATA,
  RESET_SEARCHED_NAVIGATIONS_DATA,
} from "./action-types";

export const initialState = {
  navigation: undefined,
  navigations: undefined,
  searchedNavigations: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATION_DATA:
      return { ...state, navigation: action.payload };
    case SET_NAVIGATIONS_DATA:
      return { ...state, navigations: action.payload };
    case RESET_SEARCHED_NAVIGATIONS_DATA:
      return { ...state, searchedNavigations: undefined };
    case SET_SEARCHED_NAVIGATIONS_DATA:
      return { ...state, searchedNavigations: action.payload };
    case SET_NAVIGATIONS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
