import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

import { GoLocation } from "react-icons/go";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import { FiMail } from "react-icons/fi";

const footerInfo = [
  { id: 1, desc: "SUNDAY TO FRIDAY:-  7AM to 7PM" },
  { id: 2, desc: "SATURDAY:-   7AM to 12PM" },
];

const footerLocationInfo = [
  { id: 1, desc: "SUNDAY TO FRIDAY:-  7AM to 7PM" },
  { id: 2, desc: "SATURDAY:-   7AM to 12PM" },
];

const Footer = ({ websiteInformations = [], color }) => {
  const facebookLink = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "facebook";
    }
  });

  const location = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "location";
    }
  });
  const twitterLink = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "twitter";
    }
  });

  const instagramLink = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "instagram";
    }
  });

  const youtubeLink = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "youtube";
    }
  });

  const mail = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "mail";
    }
  });

  const number = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "phone number";
    }
  });

  const poBox = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "po box";
    }
  });

  const sunToFri = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "sunday to friday";
    }
  });

  const sat = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "saturday";
    }
  });

  const maps = websiteInformations.find((item) => {
    if (item.is_active) {
      return item.title.toLowerCase() === "google maps";
    }
  });

  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <footer
      className="constant__padding footer"
      style={{ backgroundColor: color }}
    >
      <div
        className="footer__items footer__items-1"
        style={{ borderRightColor: color }}
      >
        <div className="footer__logo">
          <img src={require(`../assets/images/logo.png`).default} alt="" />
        </div>
        <div className="footer__info">
          {/* {footerInfo.map((info) => ( */}
          <div className="footer__info-item">
            <div className="img">
              <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C6.729 0 0 6.729 0 15C0 23.271 6.729 30 15 30C23.271 30 30 23.271 30 15C30 6.729 23.271 0 15 0ZM15 27C8.3835 27 3 21.6165 3 15C3 8.3835 8.3835 3 15 3C21.6165 3 27 8.3835 27 15C27 21.6165 21.6165 27 15 27Z"
                  fill="#FF1010"
                />
                <path
                  d="M16.5 7.5H13.5V15.621L18.4395 20.5605L20.5605 18.4395L16.5 14.379V7.5Z"
                  fill="#FF1010"
                />
              </svg>
            </div>
            <span>
              {sunToFri && `${sunToFri.title}: ${sunToFri.description}`}
            </span>
          </div>
          <div className="footer__info-item">
            <div className="img">
              <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C6.729 0 0 6.729 0 15C0 23.271 6.729 30 15 30C23.271 30 30 23.271 30 15C30 6.729 23.271 0 15 0ZM15 27C8.3835 27 3 21.6165 3 15C3 8.3835 8.3835 3 15 3C21.6165 3 27 8.3835 27 15C27 21.6165 21.6165 27 15 27Z"
                  fill="#FF1010"
                />
                <path
                  d="M16.5 7.5H13.5V15.621L18.4395 20.5605L20.5605 18.4395L16.5 14.379V7.5Z"
                  fill="#FF1010"
                />
              </svg>
            </div>
            <span> {sat && `${sat.title}: ${sat.description}`}</span>
          </div>
          {/* ))} */}
        </div>
      </div>
      <div
        className="footer__items footer__items-2"
        style={{ borderRightColor: color }}
      >
        <ul className="extra__info">
          <li>
            {location && (
              <>
                <GoLocation></GoLocation>
                {/* <span> abc, Old Baneswor, Kathmandu, Nepal</span> */}
                <span>{location && location.description}</span>
              </>
            )}
          </li>
          <li>
            {poBox && (
              <>
                <BsFillFileEarmarkPostFill></BsFillFileEarmarkPostFill>
                <span>{poBox && poBox.description}</span>
              </>
            )}
          </li>
          <li>
            {number && (
              <>
                <IoIosCall></IoIosCall>
                <span>{number.description}</span>
              </>
            )}
          </li>
          <li>
            {mail && (
              <>
                <FiMail></FiMail>
                <span>{mail.description}</span>
              </>
            )}
          </li>
        </ul>
      </div>
      <div className="footer__items footer__items-3">
        <div className="map">
          {maps && (
            <iframe
              src={`${maps.description}`}
              style="border:0;"
              allowFullScreen=""
              loading="lazy"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          )}
        </div>
        <div className="social__links">
          {facebookLink && (
            <Link
              to={{
                pathname: facebookLink.description,
              }}
              target="_blank"
            >
              <FaFacebookSquare></FaFacebookSquare>
            </Link>
          )}
          {twitterLink && (
            <Link
              to={{
                pathname: twitterLink.description,
              }}
              target="_blank"
            >
              <FaTwitterSquare></FaTwitterSquare>
            </Link>
          )}
          {instagramLink && (
            <Link
              to={{
                pathname: instagramLink.description,
              }}
              target="_blank"
            >
              <FaInstagram></FaInstagram>
            </Link>
          )}
          {youtubeLink && (
            <Link
              to={{
                pathname: youtubeLink.description,
              }}
              target="_blank"
            >
              <FaYoutube></FaYoutube>
            </Link>
          )}
        </div>
      </div>
      <div className="copyright" style={{ borderTopColor: color }}>
        &copy; Copyright {currentYear}, All rights reserved to Riddhi Siddhi
        <Link
          to={{ pathname: "https://mangosoftsolution.com/" }}
          target="_blank"
        >
          Powered By: Mangosoft Solution
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
