// import { createState, removeState, updateState } from "../../store/utils";
import {
  SET_LABS_DATA,
  SET_LAB_DATA,
  SET_LABS_METADATA,
  SET_SEARCHED_LABS_DATA,
  RESET_SEARCHED_LABS_DATA,
} from "./action-types";

export const initialState = {
  lab: undefined,
  labs: [],
  searchedLabs: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAB_DATA:
      return { ...state, lab: action.payload };
    case SET_LABS_DATA:
      return { ...state, labs: action.payload };
    case RESET_SEARCHED_LABS_DATA:
      return { ...state, searchedLabs: undefined };
    case SET_SEARCHED_LABS_DATA:
      return { ...state, searchedLabs: action.payload };
    case SET_LABS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
