import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SliderItem from "./SliderItem";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`slide-next`} onClick={onClick}>
      <svg viewBox="0 0 54 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 16.9999C0.75 16.5026 0.947544 16.0257 1.29917 15.6741C1.65081 15.3225 2.12772 15.1249 2.625 15.1249H46.8488L35.0475 3.32743C34.6954 2.97535 34.4976 2.49784 34.4976 1.99993C34.4976 1.50202 34.6954 1.0245 35.0475 0.672427C35.3996 0.320352 35.8771 0.122559 36.375 0.122559C36.8729 0.122559 37.3504 0.320352 37.7025 0.672427L52.7025 15.6724C52.8771 15.8466 53.0156 16.0535 53.1102 16.2813C53.2047 16.5091 53.2533 16.7533 53.2533 16.9999C53.2533 17.2466 53.2047 17.4908 53.1102 17.7186C53.0156 17.9463 52.8771 18.1533 52.7025 18.3274L37.7025 33.3274C37.3504 33.6795 36.8729 33.8773 36.375 33.8773C35.8771 33.8773 35.3996 33.6795 35.0475 33.3274C34.6954 32.9754 34.4976 32.4978 34.4976 31.9999C34.4976 31.502 34.6954 31.0245 35.0475 30.6724L46.8488 18.8749H2.625C2.12772 18.8749 1.65081 18.6774 1.29917 18.3258C0.947544 17.9741 0.75 17.4972 0.75 16.9999Z"
          fill="#F48C06"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`slide-prev`} onClick={onClick}>
      <svg viewBox="0 0 54 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.25 16.9999C53.25 16.5026 53.0525 16.0257 52.7008 15.6741C52.3492 15.3225 51.8723 15.1249 51.375 15.1249H7.15125L18.9525 3.32743C19.3046 2.97535 19.5024 2.49784 19.5024 1.99993C19.5024 1.50202 19.3046 1.0245 18.9525 0.672427C18.6004 0.320352 18.1229 0.122559 17.625 0.122559C17.1271 0.122559 16.6496 0.320352 16.2975 0.672427L1.2975 15.6724C1.12289 15.8466 0.984352 16.0535 0.889828 16.2813C0.795304 16.5091 0.746651 16.7533 0.746651 16.9999C0.746651 17.2466 0.795304 17.4908 0.889828 17.7186C0.984352 17.9463 1.12289 18.1533 1.2975 18.3274L16.2975 33.3274C16.6496 33.6795 17.1271 33.8773 17.625 33.8773C18.1229 33.8773 18.6004 33.6795 18.9525 33.3274C19.3046 32.9754 19.5024 32.4978 19.5024 31.9999C19.5024 31.502 19.3046 31.0245 18.9525 30.6724L7.15125 18.8749H51.375C51.8723 18.8749 52.3492 18.6774 52.7008 18.3258C53.0525 17.9741 53.25 17.4972 53.25 16.9999Z"
          fill="#2F327D"
        />
      </svg>
    </div>
  );
}

const slider = ({ testimonials, differentSection }) => {
  let settings = {
    dots: false,
    speed: 500,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: differentSection ? 3 : 1,
    centerMode: differentSection ? true : false,
    className: differentSection ? "center" : "",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // className: "center",
    // centerMode: true,
    // centerPadding: "60rem",
    speed: 500,
    responsive: differentSection && [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider__container">
      <Slider {...settings} className="custom__slider">
        {testimonials.map((item) => {
          if (item.is_active) {
            return (
              <SliderItem
                {...settings}
                {...item}
                key={item.id}
                differentSection={differentSection}
              />
            );
          }
        })}
      </Slider>
    </div>
  );
};

export default slider;
