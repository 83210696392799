import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Terms from "../components/Terms/";
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";

import { convertToHTML } from "draft-convert";
import { data } from "./mock";

const atoz = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Z",
];
const DataCard = ({ index, lab }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (lab && lab.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(lab.description))
        )
      );
    }
  }, [lab]);

  //  useEffect(() => {
  //   if (lab && lab.description) {
  //     setEditorState(
  //       EditorState.createWithContent(
  //         convertFromRaw(JSON.parse(lab.description))
  //       )
  //     );
  //   }
  // }, [lab]);

  useEffect(() => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className="lab__card-new">
      <div className="lab__card-description" onClick={() => setShow(!show)}>
        <p className="plus" onClick={() => setShow(!show)}>
          {" "}
          {show ? "➖" : "➕"}
        </p>
        <h1 className="title">{lab.title}</h1>
      </div>
      {/* { show && <p className="minus"  dangerouslySetInnerHTML={createMarkup(convertedContent)}> </p>} */}
      {/* {show && <p className="minus">{lab.description}</p>} */}
      {/* <ul>

        </ul> */}
      {show && (
        <ul
          className="minus"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        ></ul>
      )}
    </div>
  );
};

const Servicepage = () => {
  const [active, setActive] = useState("A");

  const location = useLocation();

  const [labs, setLabs] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    location.state.detail && setLabs(location.state.detail);
  }, []);

  const filteredLabs =
    labs && labs?.filter((i) => i?.title?.startsWith(active));

  return (
    <>
      <div className="servicepage detail-service-page">
        {/*  */}
        <div className="lab__services constant__padding">
          <h3 className="heading__primary line primary__color">Lab Services</h3>
          <div className="lab_servicesCard-filter">
            {atoz?.map((i) => (
              <div
                onClick={() => {
                  setActive(i);
                }}
                className={
                  active === i
                    ? "lab_servicesCard-filterA active"
                    : "lab_servicesCard-filterA"
                }
              >
                {" "}
                {i}{" "}
              </div>
            ))}
          </div>
          {/* <div className="lab__services-cards gallery__container constant__padding-top">
            {labs &&
              labs.map((lab, i) => {
                return <DataCard key={i} lab={lab}></DataCard>;
              })}
             
          </div> */}

          {/* <div className="lab__services-cards gallery__container constant__padding-top" >
            {filteredLabs &&
              filteredLabs.map((lab, i) => {
                return <DataCard key={i} lab={lab}></DataCard>;
              })}
             
          </div> */}
          <div className="row constant__padding-top">
            {filteredLabs && filteredLabs.length > 0 ? (
              <>
                <div>
                  {filteredLabs
                    .slice(labs.length / 2, labs.length)
                    .map((lab, i) => {
                      return <DataCard key={i} lab={lab}></DataCard>;
                    })}
                </div>
                <div>
                  {filteredLabs.slice(0, labs.length / 2).map((lab, i) => {
                    return <DataCard key={i} lab={lab}></DataCard>;
                  })}
                </div>
              </>
            ) : (
              <h1 className="no__color">No tests available</h1>
            )}
          </div>
        </div>
        {/*  */}
        <div className="home__collection constant__padding primary__color">
          <h3 className="heading__primary line">Home Collection</h3>
          <Terms></Terms>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ appState: { me } }) => ({
  me,
});

export default connect(mapStateToProps, {})(Servicepage);
