import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";

const FormikBase = ({
  renderForm,
  validationSchema,
  onReset,
  onSubmit,
  innerRef,
  initialValues,
  ...props
}) => {
  return (
    <Formik
      onSubmit={onSubmit}
      onReset={onReset}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      innerRef={(el) => {
        if (innerRef) innerRef.current = el;
      }}
      initialValues={initialValues}
      validationSchema={Yup.object().shape(validationSchema)}
    >
      {(formikProps) => renderForm({ ...formikProps })}
    </Formik>
  );
};

export default FormikBase;
