export const SET_ANNOUNCEMENT_DATA = "announcement/SET_ANNOUNCEMENT_DATA";

export const SET_ANNOUNCEMENTS_DATA = "announcement/SET_ANNOUNCEMENTS_DATA";

export const SET_ANNOUNCEMENTS_METADATA =
  "announcement/SET_ANNOUNCEMENTS_METADATA";

export const SET_SEARCHED_ANNOUNCEMENTS_DATA =
  "announcement/SET_SEARCHED_ANNOUNCEMENTS_DATA";
export const RESET_SEARCHED_ANNOUNCEMENTS_DATA =
  "announcement/RESET_SEARCHED_ANNOUNCEMENTS_DATA";
