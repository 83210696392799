export const SET_WEBSITE_INFORMATION_DATA =
  "websiteInformation/SET_WEBSITE_INFORMATION_DATA";

export const SET_WEBSITE_INFORMATIONS_DATA =
  "websiteInformation/SET_WEBSITE_INFORMATIONS_DATA";

export const SET_WEBSITE_INFORMATIONS_METADATA =
  "websiteInformation/SET_WEBSITE_INFORMATIONS_METADATA";

export const SET_SEARCHED_WEBSITE_INFORMATIONS_DATA =
  "websiteInformation/SET_SEARCHED_WEBSITE_INFORMATIONS_DATA";
export const RESET_SEARCHED_WEBSITE_INFORMATIONS_DATA =
  "websiteInformation/RESET_SEARCHED_WEBSITE_INFORMATIONS_DATA";
