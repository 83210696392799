import {
  SET_HOME_IMAGES_DATA,
  SET_HOME_IMAGE_DATA,
  SET_HOME_IMAGES_METADATA,
  SET_SEARCHED_HOME_IMAGES_DATA,
  RESET_SEARCHED_HOME_IMAGES_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setHomeImageData = (payload) => ({
  type: SET_HOME_IMAGE_DATA,
  payload,
});

export const setHomeImagesData = (payload) => ({
  type: SET_HOME_IMAGES_DATA,
  payload,
});

export const resetSearchedHomeImagesData = (payload) => ({
  type: RESET_SEARCHED_HOME_IMAGES_DATA,
  payload,
});

export const setSearchedHomeImagesData = (payload) => ({
  type: SET_SEARCHED_HOME_IMAGES_DATA,
  payload,
});

export const setHomeImagesMetadata = (payload) => ({
  type: SET_HOME_IMAGES_METADATA,
  payload,
});

export const clearSearchedHomeImages = () => async (dispatch) => {
  try {
    dispatch(resetSearchedHomeImagesData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const clearSearchedPhotos = () => async (dispatch) => {
  try {
    dispatch(resetSearchedHomeImagesData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchHomeImages =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({ url: url.homeImage, query, columns, order });

      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);

      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedHomeImagesData(data.results))
            : dispatch(setHomeImagesData(data.results));
          metadata && dispatch(setHomeImagesMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchHomeImage =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.homeImage + `${id}/`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setHomeImageData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
