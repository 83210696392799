import React from "react";

import AboutImage from "../assets/svg/AboutImage";

import MetaTag from "../meta-seo/MetaTag";

const cardDetails = [
  {
    id: 1,
    title: "Mission",
    description: "To provide world class lab services.",
    bannerUrl: "mission",
  },
  {
    id: 2,
    title: "Vision",
    description: "Innovation and Quality with affordability.",
    bannerUrl: "vision",
  },
  {
    id: 3,
    title: "Quality Commitment",
    description:
      "Quality is our first and foremost priority.The lab reports you get from here is done from highest quality machines of international standard. We assure you that your health is our first priority because we care.",
    bannerUrl: "quality",
  },
];

const ImageDescription = ({ imgPath, description, title, list }) => {
  return (
    <div className="who-we-text">
      <img
        src={require(`../assets/images/${imgPath}.png`).default}
        alt=""
        style={{ pointerEvents: "none" }}
      />
      <h3 className="heading__primary line">{title}</h3>
      {!list && <p className="who-we-paragraph">{description}</p>}
      {list && (
        <ol
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: description }}
        ></ol>
      )}
    </div>
  );
};

const About = () => {
  return (
    <>
      <MetaTag
        title="About"
        content="International standard laboratary and diagnostic center with high quality services and innovations."
        url="https://rsdiagnosticenter.com/about"
      ></MetaTag>
      <div className="aboutpage">
        <div className="constant__padding who-we common__setting">
          <div className="who-we__image-container">
            <div className="who-we__img">
              <AboutImage></AboutImage>
            </div>
          </div>
          {
            <ImageDescription
              imgPath="SeekPng"
              title="Who We Are"
              description="International standard laboratary and diagnostic center with high quality 
              services and innovations."
            ></ImageDescription>
          }
        </div>
        <div className="constant__padding technology common__setting">
          <ImageDescription
            imgPath="microscope"
            title="Technology & Equipment"
            description="<li>we use dry chemistry (vitros 4600) for routine bio-chemistry test. </li>
           <li> We  use clia (Eciq) for special test.</li>
           <li> We run qc sample every day to assure quality report.</li>"
            list={true}
          ></ImageDescription>
          <div
            to={{ pathname: "https://www.youtube.com" }}
            target="_blank"
            className="youtube__content"
          >
            <img
              src={require(`../assets/images/1S3A3090-min.jpg`).default}
              alt=""
            />
          </div>
        </div>
        <div className="constant__padding technology common__setting why__us">
          <div className="who-we-text">
            {/* <img src={require(`../assets/images/rect.png`).default} alt="" /> */}
            <h3 className="heading__primary line">
              Why should you choose us for your lab test
            </h3>
            <ol className="who-we-paragraph why__we-list">
              <li>
                Results from the latest technology of international standard.
              </li>
              <li>The most accurate results at a very reasonable price.</li>
              <li>Centrally located with ambient environment.</li>
              <li> State of art facility with innovative approach.</li>
              <li>Run by highly qualified and competent staff.</li>
            </ol>
            <div className="focus__text">
              <h2>Your well-being one call away</h2>
              <span>
                just give us a call and get your sample collected at your home
              </span>
            </div>
          </div>
          <div className="youtube__content">
            <img src={require(`../assets/images/rect.png`).default} alt="" />
          </div>
        </div>
        <div className="constant__padding chairman__message common__setting">
          <div className="who-we-text">
            <h3 className="heading__primary line">Message From Chairman</h3>
            <p className="who-we-paragraph">
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "1.8rem",
                  fontWeight: "bold",
                }}
              >
                Welcome to the Riddhi Siddhi Diagnostic and Research Center.
              </span>
              With the motto of “Innovation and Quality with Affordibility”. We
              are committed to highest ground of enthusiasm and ethics with the
              touch of world class technology. Our outstanding team of
              biochemist, microbiologist, pathologist, technical and
              non-technical staff are always at par excellence in striving the
              quality healthcare services. Innovative approach to services
              provided is an added advantage at the Riddhi Siddhi Diagnostic.
              Easy reach to people of all sectors and centered location also
              makes RDS committed to excellence in the field of healthcare .
            </p>
          </div>
          <div className="chairman__container">
            <div className="chairman__picture"></div>
            <img
              src={require(`../assets/images/chairman-png.png`).default}
              alt=""
            />
          </div>
        </div>
        <div className="constant__padding mission__cards">
          {cardDetails.map((card, i) => {
            return (
              <div className="mission__card" key={i}>
                <div className="image__content">
                  <img
                    src={
                      require(`../assets/images/${card.bannerUrl}.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="content">
                  <h2 className="card__heading">{card.title}</h2>
                  <p className="card__description">{card.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default About;
