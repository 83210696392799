import url from "../../constants/url";

import network from "../../utils/network";

import jsCookie from "js-cookie";

import { CREATE_CONTACT } from "./action-types";

import {
  SET_IS_LOADING,
  SET_IS_PERMISSION_LOADING,
  SET_NOTIFICATION,
  SET_ERROR_NOTIFICATION,
  RESET_NOTIFICATION,
  SET_FOOTER_COLOR,
} from "./action-types";

export const setIsLoading = (payload) => ({ type: SET_IS_LOADING, payload });

export const setErrorMessage = (err) => {
  let obs = err && err.response && err.response.data;

  let message;

  if (err.response.data.results) {
    obs = err.response.data.results;
  }

  if (obs) {
    const errorKeyArray = Object.keys(obs);

    const errorMessageArray = Object.values(obs);

    if (errorMessageArray.length) {
      message = errorMessageArray[0];
    }
  }

  return {
    type: SET_ERROR_NOTIFICATION,
    payload: {
      name: (err && err.response && err.response.name) || "Error",
      message: message || "Error has occurred",
      level: "error",
    },
  };
};

export const setNotification = (payload) => ({
  type: SET_NOTIFICATION,
  payload,
});

export const setErrorNotification = (payload) => ({
  type: SET_ERROR_NOTIFICATION,
  payload,
});
export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
});

export const createContact =
  ({ values }) =>
  async (dispatch) => {
    try {
      // dispatch(setIsLoading(false));

      const { data, status } = await network({
        requireToken: false,
        dispatch,
      }).post(url.contact, values);

      if (status >= 200 && status <= 300 && data) {
        dispatch(
          setNotification({
            name: "Contact",
            message: "Form Successfully Submitted",
            level: "success",
          })
        );
        dispatch(setIsLoading(false));
        return;
      }
    } catch (error) {
      // dispatch(setErrorMessage(error));
      error.response && dispatch(setErrorMessage(error));
    }
  };
