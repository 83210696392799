import {
  SET_NAVIGATIONS_DATA,
  SET_NAVIGATION_DATA,
  SET_NAVIGATIONS_METADATA,
  SET_SEARCHED_NAVIGATIONS_DATA,
  RESET_SEARCHED_NAVIGATIONS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setNavigationData = (payload) => ({
  type: SET_NAVIGATION_DATA,
  payload,
});

export const setNavigationsData = (payload) => ({
  type: SET_NAVIGATIONS_DATA,
  payload,
});

export const resetSearchedNavigationsData = (payload) => ({
  type: RESET_SEARCHED_NAVIGATIONS_DATA,
  payload,
});

export const setSearchedNavigationsData = (payload) => ({
  type: SET_SEARCHED_NAVIGATIONS_DATA,
  payload,
});

export const setNavigationsMetadata = (payload) => ({
  type: SET_NAVIGATIONS_METADATA,
  payload,
});

export const clearSearchedNavigations = () => async (dispatch) => {
  try {
    dispatch(resetSearchedNavigationsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchNavigations =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({ url: url.navigation, query, columns, order });

      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);
      if (status === 200 || status > 200) {
        if (data && data) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedNavigationsData(data))
            : dispatch(setNavigationsData(data));
          metadata && dispatch(setNavigationsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchNavigation =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(url.navigation + `${id}/`);
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setNavigationData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const verifyNavigation =
  ({ id, email, navigationname }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { status } = await network({ dispatch }).put(
        url.verifyNavigation(id),
        {
          is_verified: true,
          email,
          navigationname,
        }
      );

      if (status === 200 || status > 200) {
        dispatch(setIsLoading(false));
        return true;
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
