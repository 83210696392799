// import { createState, removeState, updateState } from 'store/utils';
import {
  SET_INFOGRAPHICS_DATA,
  SET_INFOGRAPHIC_DATA,
  SET_INFOGRAPHICS_METADATA,
  SET_SEARCHED_INFOGRAPHICS_DATA,
  RESET_SEARCHED_INFOGRAPHICS_DATA,
} from "./action-types";

export const initialState = {
  infographic: undefined,
  infographics: [],
  searchedInfographics: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INFOGRAPHIC_DATA:
      return { ...state, infographic: action.payload };
    case SET_INFOGRAPHICS_DATA:
      return { ...state, infographics: action.payload };
    case RESET_SEARCHED_INFOGRAPHICS_DATA:
      return { ...state, searchedInfographics: undefined };
    case SET_SEARCHED_INFOGRAPHICS_DATA:
      return { ...state, searchedInfographics: action.payload };
    case SET_INFOGRAPHICS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
