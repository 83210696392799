export const SET_INFOGRAPHIC_DATA = 'homeImage/SET_INFOGRAPHIC_DATA';

export const SET_INFOGRAPHICS_DATA = 'homeImage/SET_INFOGRAPHICS_DATA';

export const SET_INFOGRAPHICS_METADATA = 'homeImage/SET_INFOGRAPHICS_METADATA';

export const SET_SEARCHED_INFOGRAPHICS_DATA =
  'homeImage/SET_SEARCHED_INFOGRAPHICS_DATA';
export const RESET_SEARCHED_INFOGRAPHICS_DATA =
  'homeImage/RESET_SEARCHED_INFOGRAPHICS_DATA';

export const CREATE_INFOGRAPHIC_DATA = 'homeImage/CREATE_INFOGRAPHIC_DATA';

export const UPDATE_INFOGRAPHIC_DATA = 'homeImage/UPDATE_INFOGRAPHIC_DATA';

export const REMOVE_INFOGRAPHIC_DATA = 'homeImage/REMOVE_INFOGRAPHIC_DATA';
