import {
  SET_LABS_DATA,
  SET_LAB_DATA,
  SET_LABS_METADATA,
  SET_SEARCHED_LABS_DATA,
  RESET_SEARCHED_LABS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setLabData = (payload) => ({
  type: SET_LAB_DATA,
  payload,
});

export const setLabsData = (payload) => ({
  type: SET_LABS_DATA,
  payload,
});

export const resetSearchedLabsData = (payload) => ({
  type: RESET_SEARCHED_LABS_DATA,
  payload,
});

export const setSearchedLabsData = (payload) => ({
  type: SET_SEARCHED_LABS_DATA,
  payload,
});

export const setLabsMetadata = (payload) => ({
  type: SET_LABS_METADATA,
  payload,
});

export const clearSearchedLabs = () => async (dispatch) => {
  try {
    dispatch(resetSearchedLabsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const clearSearchedPhotos = () => async (dispatch) => {
  try {
    // dispatch(resetSearchedPhotosData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchLabs =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      let link = generateQuery({ url: url.lab, query, columns, order });

      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(link);

      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedLabsData(data.results))
            : dispatch(setLabsData(data.results));
          metadata && dispatch(setLabsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchLab =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({ dispatch }).get(
        url.lab + `${id}/`
      );
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setLabData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
