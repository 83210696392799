// import { createState, removeState, updateState } from "store/utils";
import {
  SET_HOMEGALLERYS_DATA,
  SET_HOMEGALLERY_DATA,
  SET_HOMEGALLERYS_METADATA,
  SET_SEARCHED_HOMEGALLERYS_DATA,
  RESET_SEARCHED_HOMEGALLERYS_DATA,
} from "./action-types";

export const initialState = {
  homeGallery: undefined,
  homeGallerys: [],
  searchedHomeGallerys: undefined,
  searchedPhotos: undefined,
  metadata: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEGALLERY_DATA:
      return { ...state, homeGallery: action.payload };
    case SET_HOMEGALLERYS_DATA:
      return { ...state, homeGallerys: action.payload };
    case RESET_SEARCHED_HOMEGALLERYS_DATA:
      return { ...state, searchedHomeGallerys: undefined };
    case SET_SEARCHED_HOMEGALLERYS_DATA:
      return { ...state, searchedHomeGallerys: action.payload };
    case SET_HOMEGALLERYS_METADATA:
      return { ...state, metadata: action.payload };

    default:
      return state;
  }
};
