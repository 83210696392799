import {
  SET_WEBSITE_INFORMATIONS_DATA,
  SET_WEBSITE_INFORMATION_DATA,
  SET_WEBSITE_INFORMATIONS_METADATA,
  SET_SEARCHED_WEBSITE_INFORMATIONS_DATA,
  RESET_SEARCHED_WEBSITE_INFORMATIONS_DATA,
} from "./action-types";

import { setIsLoading, setErrorMessage } from "../../store/app/actions";

import network from "../../utils/network";

import url from "../../constants/url";
import { q } from "../../constants/index";
import {
  generateMeta,
  generateQuery,
  formDataGenerator,
} from "../../store/utils";

export const setWebsiteInformationData = (payload) => ({
  type: SET_WEBSITE_INFORMATION_DATA,
  payload,
});

export const setWebsiteInformationsData = (payload) => ({
  type: SET_WEBSITE_INFORMATIONS_DATA,
  payload,
});

export const resetSearchedWebsiteInformationsData = (payload) => ({
  type: RESET_SEARCHED_WEBSITE_INFORMATIONS_DATA,
  payload,
});

export const setSearchedWebsiteInformationsData = (payload) => ({
  type: SET_SEARCHED_WEBSITE_INFORMATIONS_DATA,
  payload,
});

export const setWebsiteInformationsMetadata = (payload) => ({
  type: SET_WEBSITE_INFORMATIONS_METADATA,
  payload,
});

export const clearSearchedWebsiteInformations = () => async (dispatch) => {
  try {
    dispatch(resetSearchedWebsiteInformationsData());
    return true;
  } catch (error) {
    error.response && dispatch(setErrorMessage(error));
  }
};

export const fetchWebsiteInformations =
  ({ query = q, search = false, columns, order }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(`${url.websiteInformation}?limit=1000&offset=0`);
      if (status === 200 || status > 200) {
        if (data && data.results) {
          const metadata = generateMeta({ data, query });
          search
            ? dispatch(setSearchedWebsiteInformationsData(data.results))
            : dispatch(setWebsiteInformationsData(data.results));
          metadata && dispatch(setWebsiteInformationsMetadata(metadata));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };

export const fetchWebsiteInformation =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await network({
        dispatch,
        requireToken: false,
      }).get(url.websiteInformation + `${id}/`);
      if (status === 200 || status > 200) {
        if (data) {
          dispatch(setWebsiteInformationData(data));
          dispatch(setIsLoading(false));
          return true;
        }
      }
    } catch (error) {
      error.response && dispatch(setErrorMessage(error));
    }
  };
