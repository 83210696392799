import React, { useState, useEffect } from "react";

function Tab(props) {
  return (
    <div className={`tabs tabs__child`}>
      <div className="tabs__image">
        <img src={props.tab.bannerurl} alt="" />
        {/* {props.tab.imgPath} */}
      </div>
      <div className="tabs__content">
        <h3>{props.tab.title}</h3>
        <p>{props.tab.description}</p>
      </div>
    </div>
  );
}

function Navigation(props) {
  return (
    <ul className={`tabs__nav`}>
      {props.tabs.map((item, i) => {
        if (item.is_active) {
          return (
            <li key={i} className={`tabs__item`}>
              <button
                className={`tabs__button ${
                  props.activeTabId === item.id ? "active" : ""
                }`}
                onClick={() => props.onNavClick(item.id)}
              >
                {item.title}
              </button>
            </li>
          );
        }
      })}
    </ul>
  );
}

const Index = (props) => {
  const [activeTabId, setActiveTab] = React.useState();

  useEffect(() => {
    if (props.tabs && props.tabs.length) {
      setActiveTab(props.tabs[0].id);
    }
  }, [props]);

  const activeTab = React.useMemo(
    () => props.tabs.find((tab) => tab.id === activeTabId),
    [activeTabId, props.tabs]
  );

  return (
    <div className={`tabs constant__padding`}>
      <Navigation
        tabs={props.tabs}
        onNavClick={setActiveTab}
        activeTabId={activeTabId}
      />

      {activeTab && <Tab tab={activeTab} />}
    </div>
  );
};

export default Index;
