import React, { useEffect } from "react";
import { connect } from "react-redux";
import Gallery from "./Gallery";
import HeroSection from "./HeroSection";
import Testimonial from "./Testimonial";
import Welcome from "./Welcome";

import TextScroller from "../../components/TextScroller/index";

import Heart from "../../assets/svg/Heart";
import HomeCar from "../../assets/svg/HomeCar";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { useHistory } from "react-router-dom";

// import { fetchTestimonials } from "../../store/testimonials/actions";
import { fetchInfographics } from "../../store/infographics/actions";
import { fetchAnnouncements } from "../../store/announcement/actions";
import { fetchHomeGallerys } from "../../store/homeGallery/actions";
import MetaTag from "../../meta-seo/MetaTag";
import Slider from "../../components/Slider/slider";

const dummyService = [
  { id: 1, name: "Home Collection", icon: <HomeCar></HomeCar> },
  { id: 2, name: "Health Packages", icon: <Heart></Heart> },
];

const Index = ({
  // fetchTestimonials,
  fetchAnnouncements,
  fetchHomeGallerys,
  fetchInfographics,
  infographics = [],
  // testimonials = [],
  homeGallerys = [],
  announcements = [],
}) => {
  const history = useHistory();

  useEffect(() => {
    // fetchTestimonials({});
    fetchAnnouncements({});
    fetchHomeGallerys({});
    fetchInfographics({});
  }, []);

  const activeAnnouncement =
    announcements && announcements.filter((item) => item.is_active);

  return (
    <>
      <MetaTag
        title="Home"
        content="Need health service?. Riddhi Siddhi Diagnostic is here to fulfil your need"
      ></MetaTag>
      <div>
        <div className="announcement__notice">
          <svg
            style={{ visibility: "hidden", position: "absolute" }}
            width="0"
            height="0"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <defs>
              <filter id="round">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="8"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                  result="goo"
                />
                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
              </filter>
            </defs>
          </svg>
          <div className="first__box">
            <div className="text">Announcement</div>
            <svg
              viewBox="0 0 43 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.04998 14.1C5.04998 11.515 7.16498 9.4 9.74998 9.4H28.55L37.95 0H42.65V37.6H37.95L28.55 28.2H9.74998C8.50346 28.2 7.30799 27.7048 6.42657 26.8234C5.54515 25.942 5.04998 24.7465 5.04998 23.5H0.349976V14.1H5.04998ZM23.85 35.25V47H16.8L12.8755 35.25H9.74998V30.55H28.55V35.25H23.85Z"
                fill="white"
              />
            </svg>
          </div>

          {activeAnnouncement.length && (
            <TextScroller text={activeAnnouncement} />
          )}

          <div className="second__box">
            <div className="circle"></div>
          </div>
        </div>
        <p className="mobile-notice__text notice__text constant__padding">
          {activeAnnouncement.length &&
            activeAnnouncement[activeAnnouncement.length - 1].description}
        </p>
        <HeroSection></HeroSection>
        <div className="constant__padding service__container">
          <h1 className="heading__primary line primary__color">Our Services</h1>
          <ServiceCard
            dummyService={dummyService}
            onClick={(i) => {
              history.push(`/tabbed-page?active=${i}`);
            }}
          ></ServiceCard>
        </div>
        <Welcome></Welcome>
        <Gallery homeGallerys={homeGallerys}></Gallery>
        <section className="constant__padding constant__margin-top constant__margin-bottom slider__section">
          <div className={`infographics`}>
            <h2 className="heading__primary line primary__color">
              Do You Know ?
            </h2>
            <Slider
              testimonials={infographics.filter((item) => item.is_active)}
              differentSection={true}
            />
          </div>
        </section>
      </div>
    </>
  );
};

const mapStateToProps = ({
  appState: { me },
  // testimonialState: { testimonials },
  announcementState: { announcements },
  homeGalleryState: { homeGallerys },
  infographicState: { infographics },
}) => ({
  me,
  // testimonials,
  announcements,
  homeGallerys,
  infographics,
});

export default connect(mapStateToProps, {
  // fetchTestimonials,
  fetchAnnouncements,
  fetchHomeGallerys,
  fetchInfographics,
})(Index);
