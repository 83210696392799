export const q = {
  patient: "id",
  order: "DESC",
  order_by: "id",
  search: "",
  page: 1,
  perPage: 50,
};

export const host = "http://";
