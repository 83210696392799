import React from "react";

const HomeCar = () => {
  return (
    <svg
      width="120"
      height="64"
      viewBox="0 0 120 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.395 54.276H68.446C68.3999 55.1951 68.3216 56.1831 68.3032 57.1574C68.1926 63.2233 67.5474 63.7196 61.6074 63.6874C58.0176 63.6874 56.6581 62.341 56.3862 58.6325C55.9254 52.323 54.2572 46.0824 54.0821 39.7913C53.9946 36.7859 56.1973 33.7299 57.2849 30.674C58.4968 27.2274 59.8286 23.836 60.7549 20.3343C63.3769 10.4266 64.5106 8.84573 74.6165 8.24833C83.5196 7.70607 92.501 8.10587 101.427 8.49648C106.529 8.72165 109.436 11.8787 110.975 16.8372C112.727 22.5033 115.823 27.8064 117.146 33.5415C118.653 40.0716 121.049 47.0337 115.681 53.3386C114.879 54.2576 115.109 56.2475 115.169 57.7272C115.353 62.0561 113.358 63.7426 109.063 63.7012C104.943 63.6737 103.095 62.0101 103.381 57.9248C103.464 56.7851 103.395 55.6317 103.395 54.276ZM107.413 26.028C106.764 23.4316 105.86 21.0236 105.607 18.5513C105.077 13.3998 101.708 11.9339 97.3121 11.8006C91.7453 11.6351 86.1693 11.5478 80.6026 11.6627C70.3769 11.8695 67.2387 15.7986 68.6903 26.028H107.413ZM114.353 41.1423C114.353 40.4208 114.33 39.704 114.321 38.9825C112.367 37.6039 110.556 35.4854 108.423 35.0948C104.865 34.4422 102.805 37.4614 103.353 40.3519C103.727 42.2866 106.579 44.883 108.515 45.0484C110.344 45.2001 112.399 42.5577 114.353 41.1423ZM63.3631 34.626C62.4415 36.1287 60.5106 38.0082 60.1374 40.1405C59.589 43.2792 61.9346 45.5953 64.9576 45.0484C66.8423 44.7038 69.3769 42.4474 69.7778 40.6414C70.4552 37.5901 68.3769 35.3337 63.3631 34.626Z"
        fill="black"
      />
      <path
        d="M33.8335 15.1874L51.3679 29.2034C47.9302 40.1083 49.6536 50.7375 52.5015 61.8309H39.1745V44.3683H28.9995V62.2445C23.1424 62.2445 17.7922 62.3823 12.4605 62.1066C11.5849 62.0652 10.1103 60.2363 10.0826 59.1977C9.87988 51.459 9.91675 43.7111 10.0412 35.9679C10.1389 34.8285 10.6777 33.772 11.5435 33.0222C18.8475 27.0527 26.2484 21.2212 33.8335 15.1874Z"
        fill="black"
      />
      <path
        d="M4.47439 32.8201C-1.25366 28.6843 -1.51633 27.6135 3.88453 24.0107C8.63563 20.8399 10.76 17.1176 10.0365 11.4606C9.72318 9.00669 9.0089 5.90019 13.4282 5.63825C17.4005 5.39929 19.0733 6.92037 18.3037 11.8099C22.1194 8.63447 25.3175 6.20349 28.2391 3.483C31.8381 0.137538 35.1146 -0.0600653 38.8381 3.33135C42.9855 7.06283 47.1329 10.8449 51.7411 13.933C56.5107 17.1268 56.3217 20.555 53.6536 25.4215L33.4003 8.87802L4.47439 32.8201Z"
        fill="black"
      />
    </svg>
  );
};

export default HomeCar;
