export const generateMeta = ({ data, query }) => ({
  totalCount: data.recordsTotal,
  page: query.page,
  perPage: query.perPage,
  totalPage: query.perPage ? Math.ceil(data.recordsTotal / query.perPage) : 1,
});

export const generateQuery = ({
  url,
  query,
  datatables = false,
  columns = [],
  order,
}) => {
  const getColumItem = ({
    i = 1,
    item,
    searchable = true,
    orderable = true,
    regex = false,
  }) =>
    `&columns%5B${i}%5D%5Bdata%5D=${item}&columns%5B${i}%5D%5Bname%5D=&columns%5B${i}%5D%5Bsearchable%5D=${searchable}&columns%5B${i}%5D%5Borderable%5D=${orderable}&columns%5B${i}%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B${i}%5D%5Bsearch%5D%5Bregex%5D=${regex}`;

  let columnGeneration = "";
  let orderGeneration = "";

  if (columns.length) {
    columnGeneration = `&columns%5B0%5D%5Bdata%5D=id&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=false&columns%5B0%5D%5Borderable%5D=true&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false`;

    columns.map((column, i) => {
      columnGeneration = columnGeneration + getColumItem({ i, item: column });
    });
  }

  if (order) {
    orderGeneration = `&order%5B0%5D%5Bcolumn%5D=${order.index}&order%5B0%5D%5Bdir%5D=${order.order}`;
  }

  let link = `${url}?${
    datatables ? "format=datatables" : ""
  }${columnGeneration}${orderGeneration}`;

  query &&
    Object.entries(query).forEach((item) => {
      link += item[1] ? `&${item[0]}=${item[1]}` : "";
    });

  return link;
};

export const updateState = ({ state, local, action, entity, key = "id" }) => {
  const oldData = local.find(
    (item) => item[`${key}`] === action.payload[`${key}`]
  );
  const indexOfOldData = local.indexOf(oldData);
  const newData = {
    ...oldData,
    ...action.payload,
  };
  const excludedData = [...local.filter((item) => item !== oldData)];
  return {
    ...state,
    [`${entity}`]: [
      ...excludedData.slice(0, indexOfOldData),
      newData,
      ...excludedData.slice(indexOfOldData),
    ],
  };
};

export const removeState = ({ state, local, action, entity, key = "id" }) => {
  return {
    ...state,
    [`${entity}`]: local.filter(
      (item) => item[`${key}`] !== action.payload[`${key}`]
    ),
  };
};

export const createState = ({ state, local, action, entity }) => {
  return { ...state, [`${entity}`]: [action.payload, ...local] };
};

export const formDataGenerator = ({ data }) => {
  const formData = new FormData();
  Object.entries(data).forEach((item) => {
    item[0] && formData.append(`${item[0]}`, item[1]);
  });
  return formData;
};
